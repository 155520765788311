import { Dropdown, InputGroup, Form, DropdownButton } from "react-bootstrap";

export default function TitleNameInput({ title, setTitle, name, setName }) {

    return (
        <InputGroup>
            <DropdownButton variant="outline-primary" title={title}>
                <Dropdown.Item onClick={() => setTitle('Herr')}>Herr</Dropdown.Item>
                <Dropdown.Item onClick={() => setTitle('Frau')}>Frau</Dropdown.Item>
                <Dropdown.Item onClick={() => setTitle('')}>*keine*</Dropdown.Item>
            </DropdownButton>
        
            <Form.Control placeholder="Nachname" value={name} onChange={e => setName(e.target.value)} required/>
        </InputGroup>
    );
}