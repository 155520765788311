import { Modal, Button } from 'react-bootstrap'

export default function ConfirmModal(props) {
    const onClose = ()  => props.setShow(false);
    return (
        <Modal show={props.show} backdrop='static' onHide={onClose} size={props.size}>
            <Modal.Header closeButton={props.closeButton}>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                {props.hideCancelBtn ? <></> : (
                    <Button variant="secondary" onClick={() => {props.cancelCallback(); onClose();}}>{props.cancelBtnText}</Button>
                )}
                <Button variant={props.confirmBtnVariant} onClick={() => {if(props.confirmCallback()) onClose()}}>{props.confirmBtnText}</Button>
            </Modal.Footer>
        </Modal>
    );
}

ConfirmModal.defaultProps = {
    confirmBtnVariant: 'success',
    confirmBtnText: 'Ok',
    confirmCallback: () => true,
    cancelCallback: () => {},
    cancelBtnText: 'Schließen',
    closeButton: true,
    hideCancelBtn: false,
    size: ""
}