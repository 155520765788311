import { Button, Modal, Table } from "react-bootstrap";
import MarkdownRenderer from "./MarkdownRenderer";

export default function MarkdownInfoModal({ show, setShow }) {
    return (
        <Modal show={show} size='lg' onHide={() => setShow(false)} >
            <Modal.Header>
                <Modal.Title>Markdown-Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Markdown ist eine einfache Möglichkeit Text-Styling in Ihre Kursbeschreibung hinzuzufügen. Hier werden einige vorgestellt:
                <br /><br />
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Markdown</th>
                            <th>Ausgabe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <MarkdownInfoTableRow>So geht **fettgedruckter** Text</MarkdownInfoTableRow>
                        <MarkdownInfoTableRow>Für eine _nicht so penedrante_ Hervorhebung</MarkdownInfoTableRow>
                        <MarkdownInfoTableRow>Das muss ein **_sehr wichtiger Text_** sein</MarkdownInfoTableRow>
                        <tr>
                            <td>
                                Um einen neuen Absatz zu beginnen, muss<br /><br />eine Zeile freigelassen werden.
                            </td>
                            <td>
                            Um einen neuen Absatz zu beginnen, muss<br />eine Zeile freigelassen werden.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                - Essen kochen <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;- dann essen <br />
                                - Abwaschen <br />
                            </td>
                            <td>
                                <ul>
                                    <li>Essen kochen</li>
                                    <ul><li>dann essen</li></ul>
                                    <li>Abwaschen</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1. Zuerst das<br />
                                1. Dann dies<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;1. Das auch<br />
                                1. und jenses
                            </td>
                            <td>
                                <ol>
                                    <li>Zuerst das</li>
                                    <li>Dann dies</li>
                                    <ol>
                                        <li>Das auch</li>
                                    </ol>
                                    <li>und jenses</li>
                                    
                                </ol>
                            </td>
                        </tr>
                        <MarkdownInfoTableRow># Eine große Überschrift</MarkdownInfoTableRow>
                        <MarkdownInfoTableRow>## Ein kleine Überschrift</MarkdownInfoTableRow>
                        <MarkdownInfoTableRow>Ein [Link](https://duckduckgo.com) zu der besten Suchmaschine</MarkdownInfoTableRow>
                        <MarkdownInfoTableRow>---</MarkdownInfoTableRow>
                        <MarkdownInfoTableRow>Um \* oder \_ in Texten zu verwenden, müssen Sie ein \ (Backslash) vor das betreffende Zeichen setzen</MarkdownInfoTableRow>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    );
}

function MarkdownInfoTableRow({ children }) {
    return (
        <tr>
            <td>{children}</td>
            <td><MarkdownRenderer discardParagraphs>{children}</MarkdownRenderer></td>
        </tr>
    )
}