import { Fragment } from "react";

class Utils {

    genSimplePassword(len = 6) {
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ü',
                          'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ä', 'ö', 'ü',
                          '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',', ':', ';', '-', '<', '>', '!', '&', '%', '$', '(', ')', '=', '?', '+', '*', '/', '@'];
        
        let pwd = "";
        for(let i = 0; i < len; i++)
            pwd += alphabet[Math.floor(Math.random()*alphabet.length)];
        
        return pwd;
    }

    toggleArrayItem(arr, val) {
        var index = arr.indexOf(val);

        if (index === -1)
            arr.push(val);
        else
            arr.splice(index, 1);
        
        return arr;
    }

    replaceElementInArray(arr, index, newElement) {
        let arrCpy = [...arr];
        arrCpy[index] = newElement;
        return arrCpy;
    }

    removeElementFromArray(arr, index) {
        let arrCpy = [...arr];
        arrCpy.splice(index, 1);
        return arrCpy;
    }

    areObjectsEqual(obj1, obj2) {
        if(obj1 === obj2)
            return true;
        
        if (!(obj1 instanceof Object && obj1 instanceof Object)) {
            return false;
        }

        if(Object.keys(obj1).length !== Object.keys(obj2).length)
            return false;
        
        for(const key in obj1){
            if(!this.areObjectsEqual(obj1[key], obj2[key]))
                return false;
        }

        return true;
    }

    germanImplode(list, comma = ", ", and = " und ") {
        if(!list || list.length === 0) return "";
        if(list.length === 1) return list[0];

        const last = list.pop();
        return list.join(comma) + and + last;
    }

    jsxGermanImplode(list, comma = <>, </>, and = <> und </>) {
        if(list.length === 0) return <></>;
        if(list.length === 1) return list[0];
        if(list.length === 2) return <>{list[0]}{and}{list[1]}</>;

        const last         = list.pop();
        const secondToLast = list.pop();
        return <>{list.map((e, i) => <Fragment key={i}>{e}{comma}</Fragment>)}{secondToLast}{and}{last}</>;
    }

    classLevelsDisplayString(classLevels) {
        return this.germanImplode(classLevels.split('|').map(l => Number(l)))
    }

    isStudentInClassLevels(studentClass, classLevels) {
        return classLevels.split('|').includes(studentClass.split('/')[0]);
    }

    dowNumToAbbr(dow) {
        switch(dow) {
            case 0: return 'Mo';
            case 1: return 'Di';
            case 2: return 'Mi';
            case 3: return 'Do';
            case 4: return 'Fr';
            case 5: return 'Sa';
            case 6: return 'So';
            default: return 'ER';
        }
    }

    sqlDateToGermanDate(date) {
        if(date instanceof Date) {
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        } else {
            date = date.split('-');
            return `${date[2]}.${date[1]}.${date[0]}`;
        }
    }

    sqlDateToShortGermanDate(date) {
        const germanDate = this.sqlDateToGermanDate(date);
        return germanDate.substring(0, 6) + germanDate.substring(8);
    }

    sqlDateTimeToGermanDate(date) {
        let [ datePart, timePart ] = date.split(" ")
        return this.sqlDateToGermanDate(datePart) + " " + timePart
    }

    monthNumToFullName(month) {
        switch(month) {
            case 0:  return "Januar";
            case 1:  return "Februar";
            case 2:  return "März";
            case 3:  return "April";
            case 4:  return "Mai";
            case 5:  return "Juni";
            case 6:  return "Juli";
            case 7:  return "August";
            case 8:  return "September";
            case 9:  return "Oktober";
            case 10: return "November";
            case 11: return "Dezember";
            default: return "Error-Monat";
        }
    }

    uid(offset = 0) {
        return (new Date().getTime() + offset) % 4294967296;
    }

    isEmail(email) {
        if (!email)
            return false;
        
        if(email.length>254)
            return false;

        const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
        const valid = tester.test(email);
        if(!valid)
            return false;

        // Further checking of some things regex can't handle
        var parts = email.split("@");
        if(parts[0].length>64)
            return false;

        const domainParts = parts[1].split(".");
        if(domainParts.some(function(part) { return part.length>63; }))
            return false;
            
        return true;
    }
}

export default new Utils();

// source: https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
// eslint-disable-next-line
Date.prototype.getWeek = function() {
    // Copy date so don't modify original
    let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [ weekNo, d.getUTCFullYear()];
}