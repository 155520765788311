import { useEffect } from "react";
import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import FormRow from "../../../components/bt/FormRow";
import EvaluationSelect from "../../../components/forms/selects/EvaluationSelect";
import MarkdownEditor from "../../../components/markdown/MarkdownEditor";
import useFetchData from "../../../hooks/useFetchData";

const evalIndependentInitialProperties = {
    title: '',
    description: ''
}

export default function EvaluationAnalyzer({ properties, setProperties, pageState, setPageState }) {
    const evaluations = useFetchData('evaluations', {}, []);
    const currentEval = evaluations.filter(e => e.id === pageState.evalId)[0]
    
    useEffect(() => {
        setProperties(evalIndependentInitialProperties)
    }, [ setProperties ])

    useEffect(() => {
        // set evaluation dependent initial properties
        if(currentEval !== undefined) {
            const year = currentEval.period_start ? (' ' + currentEval.period_start.split('-')[0]) : ''
            setProperties({
                ...evalIndependentInitialProperties, 
                title: `Auswertung der Umfrage ${currentEval.title}${year}`,
                evalId: pageState.evalId,
                showParticipantCount: currentEval.target_group === 'student'
            })
        }
            
    }, [ currentEval, setProperties, pageState.evalId ])
    
    if(evaluations.length === 0) {
        return (
            <Card body className="mb-3">
                <Card.Title>Lade...</Card.Title>
            </Card>
        )
    }

    return (<>
        <Card body className="mb-3">
            <Card.Title>Evaluation auswählen</Card.Title>
            <EvaluationSelect 
                value={pageState.evalId ? pageState.evalId : ''} setValue={(v) => setPageState({...pageState, evalId: v})} defaultFirst
            />
        </Card>
        {currentEval === undefined ? <></> : (
            <Card body className="mb-3">
                <Row className="mb-2">
                    <Col>
                        <Card.Title>Optionen zum Auswerten</Card.Title>   
                    </Col>
                    <Col className="text-end">
                        <Badge className="me-1">{currentEval.count_participants} Teilnahmen</Badge>
                        <Badge bg="info">{currentEval.count_answers} Antworten</Badge>
                    </Col>
                </Row>
                <FormRow label="Überschrift">
                    <Form.Control value={properties.title} onChange={e => setProperties({...properties, title: e.target.value})} />    
                </FormRow>
                <FormRow label="Einleitung">
                    <MarkdownEditor text={properties.description} setText={t => setProperties({...properties, description: t})} textAreaRows={3} hideLinkOption placeholder="Hier kann ein einleitender Text für die Evaluationsauswertung stehen"/>
                </FormRow>
                {currentEval?.target_group === 'student' ? (
                    <FormRow label="">
                        <Form.Check id="showParticipantCount" label="Zeige detaillierte Auswertung der Teilnehmenden" checked={properties.showParticipantCount} onChange={e => setProperties({...properties, showParticipantCount: !properties.showParticipantCount})} />
                    </FormRow>
                ) : <></>}
            </Card>
        )}
    </>)
}