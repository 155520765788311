import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormRow from "../../components/bt/FormRow";
import useFetchData from "../../hooks/useFetchData";
import usePageState from "../../hooks/usePageState";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";

export default function EditTermParticipants(props) {
    const [ pageState, , PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const course = useFetchData('termParticpants/' + pageState.id, {}, [saveListener]);
    const [ pushNotification, Notificator ] = usePushNotificator();

    const [ studentsInTerms, setStudentsInTerms ] = useState() 

    useEffect(() => {
        if(course.length !== 0) {
            let tempStudentsInTerms = {}
            
            for(const student of course.students) {
                tempStudentsInTerms[student.student_id] = JSON.parse(student.visit_voluntary);
            }

            setStudentsInTerms(tempStudentsInTerms);
        }
    }, [course]);

    const addStudentToTerm = (studentId, termId) => {
        setStudentsInTerms({
            ...studentsInTerms,
            [studentId]: [...studentsInTerms[studentId], Number(termId)]
        });
    };

    const removeStudentFromTerm = (studentId, termId) => {
        setStudentsInTerms({
            ...studentsInTerms,
            [studentId]: studentsInTerms[studentId].filter(t => Number(t) !== Number(termId))
        });
    }

    const save = async () => {
        const res = await api.put('termParticpants/' + pageState.id, studentsInTerms);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Die Teilnehmer des Termins wurden erfolgreich gesetzt', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }

    }

    let voluntaryTerms = []
    if(course.length !== 0) {
        voluntaryTerms = JSON.parse(course.schedule).filter(t => t.flags.includes('VOLUNTARY'));
    }


    return (
        <>
        <PageStateReloader />
        <h1>Teilnahme an freiwilligen Terminen</h1>
        {course.length === 0 ? <></> : (
            <>
            <h3>Kurs: {course.title}</h3>
            <Notificator />
            {voluntaryTerms.length === 0 ? <p>Dieser Kurs enthält keine freiwilligen Termine</p> : 
                <>
                {voluntaryTerms.map(e => <TermCard 
                    key={e.uid}
                    term={e} 
                    addStudent={(sId) => addStudentToTerm(sId, e.uid)}
                    removeStudent={(sId) => removeStudentFromTerm(sId, e.uid)}
                    students={course.students}
                    studentsInTerms={studentsInTerms}
                    />
                )}
                <Card body className="text-end">
                    <Link to={{pathname: '/viewCourse', state: {id: pageState.id}}}><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button variant="success" onClick={save}>Speichern</Button>
                </Card>
                </>
            }
            </>

        )}

        </>
    );
}

function TermCard({term, addStudent, removeStudent, students, studentsInTerms}) {
    if(studentsInTerms === undefined)
        return <></>;

    const participatingStudents = students.filter(s => studentsInTerms[s.student_id].includes(Number(term.uid)));
    const notParticipatingStudents = students.filter(s => !studentsInTerms[s.student_id].includes(Number(term.uid)));

    return (
        <Card className="mb-3">
            <Card.Header>
                {term.title}
            </Card.Header>
            <Card.Body>
                <FormRow label="Teilnehmer" plaintext={participatingStudents.length === 0}>
                    {participatingStudents.length === 0 ? <div className="text-muted">--- keine ---</div> :
                        participatingStudents.map(s => <StudentBatch 
                            key={s.student_id}
                            student={s} 
                            clickAction={() => removeStudent(s.student_id)}
                            participating={true}
                        />)
                    }
                </FormRow>
                <FormRow label="Nicht-Teilnehmer"  plaintext={notParticipatingStudents.length === 0}>
                    {notParticipatingStudents.length === 0 ? <div className="text-muted">--- keine ---</div> :
                        notParticipatingStudents.map(s => <StudentBatch 
                            key={s.student_id}
                            student={s} 
                            clickAction={() => addStudent(s.student_id)}
                            participating={false}
                        />)
                    }
                </FormRow>
            </Card.Body>
        </Card>
    );
}

function StudentBatch({ student, clickAction, participating }) {
    const variant = participating ? 'outline-success' : 'outline-danger';

    return <Button className="me-2" onClick={clickAction} variant={variant} size="sm">{student.name}, {student.prename}</Button>
}