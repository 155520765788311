import { Form } from "react-bootstrap";
import useFetchData from "../../../hooks/useFetchData"
import FloatingSelect from '../../bt/FloatingSelect'

export default function TrainerSelect({ defaultFirst, floating, value, setValue, dependencies, ...rest }) {
    
    const trainers = useFetchData('trainers', {}, dependencies)
    const SelectInUse = floating ? FloatingSelect : Form.Select;

    return (
        <SelectInUse value={value} onChange={e => setValue(e.target.value)} {...rest}>
            {defaultFirst ? <></> : <option disabled="disabled" value=''>Leiter auswählen</option>}
            {trainers.map(t => <option value={t.id} key={t.id}>{t.title} {t.name}</option>)}
        </SelectInUse>
    )
}

TrainerSelect.defaultProps = {
    defaultFirst: false,
    floating: false,
    dependencies: []
}