import { useEffect, useState } from "react";
import { Card, Col, Form, Nav, Row, Tab, Tabs } from "react-bootstrap";
import CourseDisplay from "../../components/forms/dataDisplays/CourseDisplay";
import useFetchData from "../../hooks/useFetchData";

export default function ReaderComponent({ initialCourseId, restrictToClass, showTrainerLinks, setSelectedCourse }) {
    const courses = useFetchData('courses');
    const [ coursesBySector, setCoursesBySector ] = useState({});
    const [ activeSector, setActiveSector ] = useState()
    const [ activeCourseId, setActiveCourseId ] = useState()

    useEffect(() => {
        // Sort courses by sector
        let temp = {};
        for(let c of courses) {
            if(restrictToClass !== 'no' && c.class_levels.split('|').indexOf(restrictToClass) === -1)
                continue;

            if(c.sector in temp) {
                temp[c.sector].push(c);
            } else {
                temp[c.sector] = [ c ];
            }
            if(Number(c.id) === Number(initialCourseId))
                setActiveSector(c.sector);
        }
        setCoursesBySector(temp);

        if(Object.keys(temp).length > 0) {
            setActiveCourseId(initialCourseId ? initialCourseId : temp[Object.keys(temp)[0]][0].id);
        }
    }, [ courses, initialCourseId, restrictToClass ]);

    useEffect(() => {
        setSelectedCourse(courses.filter(c => c.id === activeCourseId)[0])
    }, [activeCourseId, setSelectedCourse, courses])

    if(Object.keys(coursesBySector).length === 0) {
        return (
            <>
            <p>Lade Kurse</p>
            </>
        );
    }

    const changeTab = (sector) => {
        if(sector !== activeSector) {
            setActiveCourseId(coursesBySector[sector][0].id)
        }
        setActiveSector(sector)
    }

    return (
        <>
        <Tabs className="mb-2" activeKey={activeSector} onSelect={changeTab}>
            {Object.keys(coursesBySector).map(sector => (
                <Tab title={sector} key={sector} eventKey={sector}>
                    <ReaderSectorTab 
                        courses={coursesBySector[sector]} 
                        trainerLinks={showTrainerLinks} 
                        activeCourseId={activeCourseId} setActiveCourseId={setActiveCourseId}
                    />
                </Tab>
            ))}
        </Tabs>
        </>
    );  
}

ReaderComponent.defaultProps = {
    restrictToClass: 'no',
    showTrainerLinks: false,
    setSelectedCourse: _ => {}
}

function ReaderSectorTab({ courses, trainerLinks, activeCourseId, setActiveCourseId}) {

    return (
        <Tab.Container activeKey={activeCourseId} onSelect={setActiveCourseId}>
            <Row>
                <Col>
                    <div className="d-lg-none mb-2">
                        <Form.Select value={activeCourseId} onChange={e => setActiveCourseId(e.target.value)}>
                            {courses.map(c => (
                                <option key={c.id} value={c.id}>{c.title}</option>
                            ))}
                        </Form.Select>
                    </div>
                    {courses.map(c => (
                        <Tab.Content key={c.id}>
                            <Tab.Pane eventKey={c.id}>
                                <Card body>
                                    <CourseDisplay 
                                        title={c.title}
                                        trainerName={c.trainer_title + ' ' + c.trainer_name}
                                        trainerId={c.trainer_id}
                                        sector={c.sector}
                                        place={c.place}
                                        classLevels={c.class_levels}
                                        size={c.size}
                                        description={c.description}
                                        schedule={c.schedule}
                                        trainerLink={trainerLinks}

                                        hideSector
                                    />
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    ))}
                </Col>
                <Col lg={3}>
                    <div className="d-none d-lg-block">
                    <Nav variant="pills" className="flex-column">
                        {courses.map(c => (
                            <Nav.Item key={c.id} className="hoverable">
                                <Nav.Link eventKey={c.id}>{c.title}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
    );
}