import { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import api from "../../service/api";
import TeacherEdit from "../../components/forms/dataEdits/TeacherEdit";
import { Link } from "react-router-dom";
import usePushNotificator from "../../hooks/usePushNotificator";

export default function NewTeacher() {
    const [ name, setName ]    = useState('');
    const [ _class, setClass ] = useState('');
    const [ title, setTitle ]  = useState('Frau');
    const [ pwd, setPwd ]      = useState('');
    const [ pwd2, setPwd2 ]    = useState('');

    const [ pushNotification, Notificator ] = usePushNotificator();

    const handleSubmit = async e => {
        e.preventDefault();
        if(pwd !== pwd2) {
            pushNotification('Ungültige Eingabe', 'Die Passwörter müssen übereinstimmen!', 'warning');
        } else {
            const res = await api.post('teachers', {title: title, name: name, class: _class, pwd: pwd});

            if(res.status === 'ok') {
                setName('');
                setPwd('');
                setPwd2('');
                setTitle('Frau');

                pushNotification('Bearbeitung erfolgreich', 'Klassenleiter erfolgreich erstellt', 'success');
            } else {
                pushNotification('Interner Fehler', res.error, 'danger');
            }
            
        }
        
    }

    return (
        <>
        <Notificator />
        <Card body>
            <Card.Title>Neuer Klassenleiter erstellen</Card.Title>
            <hr />
            <Form onSubmit={handleSubmit}>
                <TeacherEdit _class={_class} setClass={setClass} title={title} setTitle={setTitle} name={name} setName={setName} pwd={pwd} setPwd={setPwd} pwd2={pwd2} setPwd2={setPwd2} mode='new' />
                <div className="text-end">
                    <Link to="/"><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button type="submit" variant="success">Erstellen</Button>
                </div>
            </Form>
        </Card>
        </>
    );
}