import { useState } from "react";
import { Button, ButtonGroup, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { ArrowDown, ArrowUp, TrashFill } from "react-bootstrap-icons";
import utils from "../../service/utils";
import ConfirmModal from "../bt/ConfirmModal";
import FormRow from "../bt/FormRow";
import EvalQuestionEdit from "./EvalQuestionEdit";

export default function EvalSectionEdit({ title, setTitle, questions, setQuestions, remove, moveUp, moveDown }) {
    const [ showWarningModal, setShowWarningModal ] = useState(false)
    
    const setQuestionProp = (i, prop, value) => setQuestions(utils.replaceElementInArray(questions, i, { ...questions[i], [prop]: value }))
    const swapQuestions = (i, j) => {
        let questionsCpy = [...questions];
        questionsCpy[i] = questions[j]
        questionsCpy[j] = questions[i]
        setQuestions(questionsCpy)
    }

    const newQuestion = () => {
        setQuestions([...questions, {
            type: "mark",
            text: "",
            required: false,
            showMode: 'everyone',
            properties: { max: '6' }
        }])
    }

    return (
        <>
        <ConfirmModal title="Abschnitt löschen" confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={() => { remove(); return true }} cancelBtnText="Abbrechen" show={showWarningModal} setShow={setShowWarningModal}>
            Möchten Sie wirklich den Abschnitt {title} und alle beinhaltete Fragen löschen?
        </ConfirmModal>
        <Card bg="dark" className="mb-2">
            <Card.Header style={{color: 'white'}}>
                <Row>
                    <Col sm={10}>{title}</Col>
                    <Col className="text-end">
                    <Button size="sm" variant="danger" className="me-2" onClick={() => setShowWarningModal(true)}><TrashFill className="mb-1" /></Button>
                    <ButtonGroup>
                        <Button size="sm" variant="secondary" onClick={moveUp} disabled={moveUp === null}><ArrowUp className="mb-1" /></Button>
                        <Button size="sm" variant="secondary" onClick={moveDown} disabled={moveDown === null}><ArrowDown className="mb-1" /></Button>
                    </ButtonGroup>
                    </Col>
                </Row>
                
            </Card.Header>
            <Card.Body style={{background: 'white'}}>
                <FormRow label="Abschnittstitel">
                    <Form.Control type="text" placeholder="Abschnittstitel" value={title} onChange={e => setTitle(e.target.value)} required/>
                </FormRow>
                <ListGroup>
                    {questions.map((question, i) => (
                        <ListGroup.Item key={i}>
                            <EvalQuestionEdit 
                                type={question.type} setType={t => setQuestionProp(i, 'type', t)}
                                text={question.text} setText={t => setQuestionProp(i, 'text', t)}
                                required={question.required} setRequired={r => setQuestionProp(i, 'required', r)}
                                showMode={question.showMode} setShowMode={m => setQuestionProp(i, 'showMode', m)}
                                properties={question.properties} setProperties={p => setQuestionProp(i, 'properties', p)}
                                remove={() => setQuestions(utils.removeElementFromArray(questions, i))}
                                moveUp={ i === 0 ? null : () => swapQuestions(i, i-1) }
                                moveDown={ i === questions.length - 1 ? null : () => swapQuestions(i, i+1) }
                            />
                        </ListGroup.Item>
                    ))}
                    {questions.length === 0 ? (
                        <ListGroup.Item>
                            <div className="text-muted text-center">--- Keine Fragen in diesem Abschnitt ---</div>
                        </ListGroup.Item>
                    ) : <></>}
                    <ListGroup.Item className="text-end">
                        <Button variant="success" size="sm" onClick={newQuestion}>Neue Frage</Button>    
                    </ListGroup.Item>
            </ListGroup>
            </Card.Body>
            <Card.Footer />
        </Card>
        </>
    )
}