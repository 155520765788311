import { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import api from "../../service/api";
import utils from "../../service/utils";
import StudentEdit from "../../components/forms/dataEdits/StudentEdit";
import { Link } from "react-router-dom";
import usePushNotificator from "../../hooks/usePushNotificator";
import useLoginToken from "../../hooks/useLoginToken";

export default function NewStudent() {
    const [{ userType }, ] = useLoginToken();
    const [ prename, setPrename ] = useState('');
    const [ name, setName ]       = useState('');
    const [ _class, setClass ]    = useState('');
    const [ pwd, setPwd ]         = useState(utils.genSimplePassword());
    const [ courses, setCourses ] = useState([]);

    const [ pushNotification, Notificator ] = usePushNotificator();

    const handleSubmit = async e => {
        e.preventDefault();
        const res1 = await api.post('students', {prename: prename, name: name, class: _class, pwd: pwd});

        if(res1.status === 'ok') {
            let res2;
            if(userType === 'other') {
                res2 = await api.put('students/' + res1.insertId + '/courses', { entries: courses.map(c => { return {courseId: c.id, visit_voluntary: c.visit_voluntary} })});;
            } else {
                // as a teacher you cannot change the courses of a student
                res2 = { status: 'ok' };
            }
            
            if(res2.status === 'ok') {
                setPrename('');
                setName('');
                setClass('');
                setPwd(utils.genSimplePassword());
                setCourses([]);

                pushNotification('Bearbeitung erfolgreich', 'Schüler erfolgreich erstellt', 'success');
                return;
            }
        }

        pushNotification('Interner Fehler', res1.error, 'danger');
    }

    return (
        <>
        <Notificator />
        <Card body>
            <Card.Title>Neuen Schüler erstellen</Card.Title>
            <hr />
            <Form onSubmit={handleSubmit}>
                <StudentEdit prename={prename} setPrename={setPrename} name={name} setName={setName} _class={_class} setClass={setClass} pwd={pwd} setPwd={setPwd} courses={courses} setCourses={setCourses}/>
                <div className="text-end">
                    <Link to="/"><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button type="submit" variant="success">Erstellen</Button>
                </div>
            </Form>
        </Card>
        </>
    );
}