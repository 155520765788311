import { Link } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import useLoginToken from "../../hooks/useLoginToken";
import utils from "../../service/utils";
import Alert from 'react-bootstrap/Alert';

export default function StudentLandingPage() {
    const evaluations = useFetchData("evaluations/questionnaire/available");
    const studentInfo = useFetchData("students");
    const [ { userData }, ] = useLoginToken();
    const courseSelectionInfo = useFetchData(`courseSelection/studentInfo/${userData.id}`);
    const allCourses = useFetchData("courses");

    const courseSelectionAvailable = !Array.isArray(courseSelectionInfo) && !('error' in courseSelectionInfo);
    const evaluationsAvailable = evaluations?.length > 0;

    console.log(userData)

    return (
        <>
        <h1>Hallo {userData.prename} {userData.name}</h1>
        {studentInfo?.length && studentInfo[0].courses?.length ? (
            <p>Du bist in  {
                utils.jsxGermanImplode(studentInfo[0].courses.split(",").map(cid => {
                    const course = allCourses.filter(c => Number(c.id) === Number(cid))[0];
                    if(!course) {
                        return <></>
                    } else {
                        return <Link to={{pathname: '/reader', state: {id: cid}}}>{course.title} ({course.trainer_title} {course.trainer_name})</Link>
                    }
                }))
            } eingeschrieben!</p>
        ) : courseSelectionAvailable ? <></> : (
            <p>Du bist gerade in keine Kurse eingschrieben.</p>
        )}
        {!courseSelectionAvailable && !evaluationsAvailable ? (
            <p>Leider sind gerade keine Aktionen hier für dich verfügbar.</p>
        ) : <></>}
        {courseSelectionAvailable ? (
            <p>Gerade findet die Kurswahl statt! {courseSelectionInfo.selectedCourses?.length === 0 ? 'Bisher hast du noch keine Kurse gewählt.' : ''} <Link to={{pathname: '/courseSelection'}}>Hier</Link> kommst du zur Kurswahl</p>
        ) : <></>}
        {!evaluationsAvailable ? <></> : (<>
            <p>Du kannst hier Evaluationen und Umfragen ausfüllen</p>
            <ul>
                {evaluations.map(e => (
                    <li key={e.id}><Link to={{pathname: '/evaluationQuestionnaire', state:{id: e.id}}}>{e.title}</Link></li>
                ))}
            </ul>
        </>)}
        {userData.id === "2418" ? <Alert>Hallo Frau Lang, bitte senden Sie noch einmal ein Feedback und geben Sie eine Antwort-Mail Adresse ein, sodass wir mit Ihnen in Kontakt treten können.</Alert> : <></>}
        </>
    )
}