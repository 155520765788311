import { Badge, Col, Form, Row } from "react-bootstrap";
import MarkdownRenderer from "../markdown/MarkdownRenderer";


export default function EvalQuestionRenderer({ id, type, text, required, showMode, properties, value, setValue, dummy, course }) {
    let textPrefix = <></>
    if(required) {
        textPrefix = <>{textPrefix}<span style={{color: 'red'}}>*</span></>
    }

    if(dummy) {
        setValue = (_) => {}
        course = {
            id: -1,
            title: 'Dummy Kurs',
            trainer_title: 'Herr',
            trainer_name: 'Mustermann'
        }

        switch(showMode) {
            case "courseSpecific":
                textPrefix = <>{textPrefix} <span className="text-muted"> Wird für jeden Kurs einzeln angezeigt!</span></>
                break;
            
            case "visitsNoCourse":
                textPrefix = <>{textPrefix} <span className="text-muted"> Wird nur angezeigt, falls der Schüler keinen Kurs besucht!</span></>
                break;

            case "visitsCourse":
                textPrefix = <>{textPrefix} <span className="text-muted"> Wird nur angezeigt, falls der Schüler mindestens einen Kurs besucht!</span></>
                break;

            default:
        }
    }

    

    if(showMode === "courseSpecific") {
        text = text.replaceAll("$$KURS$$", course.title).replaceAll("$$LEITER$$", `${course.trainer_title} ${course.trainer_name}`)
    }

    let textTag = <><MarkdownRenderer discardParagraphs>{text}</MarkdownRenderer>{textPrefix}<br /></>

    switch (type) {
        case "text":
            return textTag
        
        case "freeAnswer":
            return (<>
                {textTag}
                <Form.Control key={id + "inner"} as="textarea" value={value} onChange={e => setValue(e.target.value)} disabled={dummy} size="sm" style={{resize: dummy ? 'none' : 'vertical'}} required={required}/>
            </>) 
        
        case "mark":
            const maxMark = Number(properties.max)
            return (<>
                {textTag}
                <Row>
                    {[...Array(maxMark).keys()].map(i => (
                        <Col key={`${id}${i}`}>
                            <Form.Check type="radio" key={`${id}${i}inner`} label={i+1} id={id + i} value={i+1} name={id} checked={!dummy && i+1 === Number(value)} onChange={e => setValue(e.target.value)} disabled={dummy} required={required}/>
                        </Col>
                    ))}
                </Row>
                {dummy ? <></> : (
                    <DeleteAnswerBadge value={value} setValue={setValue} />
                )}
            </>)
        
        case "multipleChoice":
            const isOther = value && value.startsWith('__other__');
            return (<>
                {textTag}
                {properties.options.map((option, i) => (
                    <Form.Check type="radio" key={`${id}${i}inner`} label={option} value={option} name={id} id={id + i} checked={!dummy && option === value} onChange={e => setValue(e.target.value)} disabled={dummy} required={required} />
                ))}
                {properties.allowOther ? (<>
                    <Form.Check type="radio" key={`${id}oinner`} label="Sonstiges:" name={id} id={id + "o"} checked={!dummy && isOther} onChange={() => setValue("__other__")} disabled={dummy} required={required}>
                    </Form.Check>
                    <Form.Control size="sm" style={{marginLeft: '20px', width: '90%'}} key={`${id}oiinner`} value={isOther ? value.substr(9) : ''} onChange={e => setValue("__other__" + e.target.value)} required={required && isOther} disabled={dummy} onFocus={() => setValue("__other__")} />
                </>) : <></>}
                {dummy ? <></> : (
                    <DeleteAnswerBadge value={value} setValue={setValue} />
                )}
            </>)
        
        default:
            return <p>Render fehler!</p>

    }
}

EvalQuestionRenderer.defaultProps = {
    dummy: false
}

function DeleteAnswerBadge({ value, setValue }) {
    return (
        <div className="text-end">
            {value === '' ? (
                <Badge pill bg="secondary" className="disable-text-select">Antwort löschen</Badge>
            ) : (
                <Badge pill bg="danger" className="disable-text-select hoverable" onClick={() => setValue("")}>Antwort löschen</Badge>
            )}
            
        </div>
    )
}