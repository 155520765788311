import { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useFetchData from '../hooks/useFetchData';
import api from '../service/api';
import ConfirmModal from './bt/ConfirmModal';


export default function DeleteButton({ userType, userId, btnText, onDeleted, renderButton: RenderButton }) {
    const checkResult = useFetchData(`${userType}s/${userId}/check-delete`, {}, [ userType, userId ]);

    const [ showWarningModal, setShowWarningModal ] = useState(false);

    const performDelete = async () => {
        const res = await api.delete(`${userType}s/${userId}`);

        if(res.status === 'ok') {
            onDeleted();
        }
    }
    
    // Result has not arrived yet
    if(checkResult instanceof Array)
        return <RenderButton disabled onClick={() => {}} btnText={btnText}/>
    
    if(checkResult.result === 'passed') {
        return (
            <>
            <RenderButton onClick={() => setShowWarningModal(true)} btnText={btnText}/>
            <ConfirmModal title={btnText} confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={performDelete} cancelBtnText="Abbrechen" show={showWarningModal} setShow={setShowWarningModal}>
                {checkResult.warning === '' ? '' : <p><strong>Warnung: </strong>{checkResult.warning}</p>}
                Möchten Sie wirklich die Löschung ausführen?
            </ConfirmModal>
            </>
        );
    } else {
        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 50, hide: 100 }}
                overlay={(props) => (
                    <Tooltip {...props}>{checkResult.warning}</Tooltip>
                )}
            >
                <span style={{padding: '5px'}}>
                    <RenderButton disabled onClick={() => {}} btnText={btnText} />
                </span>
            </OverlayTrigger>
        );
    }
}

DeleteButton.defaultProps = {
    renderButton: (props) => <Button variant="danger" onClick={props.onClick} disabled={props.disabled}>{props.btnText}</Button>,
    btnText: 'Löschen',
    onDeleted: () => {}
}