import { Button, ButtonGroup, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import FormRow from "../bt/FormRow";
import { ArrowUp, ArrowDown, TrashFill } from "react-bootstrap-icons";
import { useEffect } from "react";
import utils from "../../service/utils";
import MarkdownEditor from "../markdown/MarkdownEditor";

export default function EvalQuestionEdit({ type, setType, text, setText, required, setRequired, showMode, setShowMode, properties, setProperties, remove, moveUp, moveDown }) {
    useEffect(() => {
        if(type === "mark") {
            if(!("max" in properties))
                setProperties({ max: 6 }) 

        } else if(type === "multipleChoice") {
            if(!("options" in properties))
                setProperties({ options: ["Antwortmöglichkeit 1"], allowOther: false })

        } else {
            if(Object.keys(properties).length > 0)
                setProperties({})
        }
    }, [ type, properties, setProperties ])
    
    return (
        <>
        <FormRow small label="Typ">
            <Row>
                <Col>
                    <Form.Select  value={type} onChange={e => setType(e.target.value)} size="sm">
                        <option value="text">Kommentar / Beschreibung</option>
                        <option value="mark">Lineare Skala</option>
                        <option value="freeAnswer">Freitext-Frage</option>
                        <option value="multipleChoice">Multiple-Choice Frage</option>
                    </Form.Select>
                </Col>
                {type === "mark" ? (
                    <Col lg="4">
                        <FormRow small label="Skalenende" labelSize="4">
                            <Form.Control type="number" placeholder="Text" value={properties.max} onChange={e => setProperties({...properties, max: e.target.value})} size="sm"/>
                        </FormRow>
                    </Col>
                ) : <></>}
            </Row>
        </FormRow>
        <FormRow small label={type === "text" ? "Text" : "Frage"}>
            <MarkdownEditor
                text={text} setText={setText}
                placeholder={type === "text" ? "Geben Sie hier Ihr Kommentar oder Ihre Zwischenbemerkung ein!" : "Geben Sie hier Ihren Fragetext ein!"}
                textAreaRows={3}
                hideIndentOption hideTitleOptions hideLinkOption
            />
        </FormRow>
        {type === 'multipleChoice' ? (
            <FormRow small label="Optionen">
                <MultipleChoiceOptionsEditor
                    options={properties.options}
                    setOptions={os => setProperties({ allowOther: properties.allowOther, options: os })}
                    allowOther={properties.allowOther}
                    setAllowOther={ao => setProperties({options: properties.options, allowOther: ao })}
                />
            </FormRow>
        ) : <></>}
        {type === 'text' ? <></> : (<>
            <FormRow small label="Verpflichtend">
                <Form.Check checked={required} onChange={() => setRequired(!required)}/>
            </FormRow>
        </>)}
        <FormRow small label="Zeige">
            <Form.Select size="sm" value={showMode} onChange={e => setShowMode(e.target.value)}>
                <option value="everyone">Jedem einmal</option>
                <option value="courseSpecific">Aufgeschlüsselt nach besuchten Kursen</option>
                <option value="visitsNoCourse">Nur Schüler, die keinen Kurs besuchen</option>
                <option value="visitsCourse">Nur Schüler, die mindestens einen Kurs besuchen</option>
            </Form.Select>
            {showMode === "courseSpecific" ? <Form.Text muted>Verwende in der Frage $$KURS$$ oder $$LEITER$$ um den Namen des jeweiligen Kurses bzw. der/des jeweiligen Leiters/in einzufügen!</Form.Text> : <></>}
        </FormRow>
        <div className="text-end">
            <Button size="sm" variant="outline-danger" className="me-2" onClick={remove}><TrashFill className="mb-1" /></Button>
            <ButtonGroup>
                <Button size="sm" variant="outline-secondary" onClick={moveUp} disabled={moveUp === null}><ArrowUp className="mb-1" /></Button>
                <Button size="sm" variant="outline-secondary" onClick={moveDown} disabled={moveDown === null}><ArrowDown className="mb-1" /></Button>
            </ButtonGroup>
        </div>

        </>
    )
}


function MultipleChoiceOptionsEditor({options, setOptions, allowOther, setAllowOther}) {
    return (
        <Card body>
            {options?.map((option, i) => (
                <InputGroup className="mb-1" size="sm" key={i}>
                    <InputGroup.Radio disabled />
                    <Form.Control required value={option} onChange={e => setOptions(utils.replaceElementInArray(options, i, e.target.value))}/>
                    <Button variant="outline-danger" onClick={() => setOptions(utils.removeElementFromArray(options, i))}><TrashFill className="mb-1" /></Button>
                </InputGroup>
            ))}
            {options?.length === 0 ? (
                <div className="text-center text-muted">--- Keine Antwortmöglichkeiten vorhanden ---</div>
            ) : <></>}
            <Row>
                <Col sm="10">
                    <Form.Check label="Erlaube andere Antworten" size="sm" id={utils.uid()} checked={allowOther} onChange={() => setAllowOther(!allowOther)} />
                </Col>
                <Col className="text-end">
                    <Button variant="success" size="sm" onClick={() => setOptions([...options, "Antwortmöglichkeit " + (options.length + 1)])}><strong>+</strong></Button>
                </Col>
            </Row>
        </Card>
    );
}