import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AttendanceCourseView from "../../components/attendance/AttendanceCourseView";
import useFetchData from "../../hooks/useFetchData";
import useLoginToken from "../../hooks/useLoginToken";

export default function CourseOverview() {
    const [ { userData }, ] = useLoginToken();
    const courses = useFetchData(`trainers/${userData.id}/courses`, {}, [ userData ]);
    const evaluations = useFetchData(`evaluations/questionnaire/available`);


    return (
        <>
        <h1>Hallo {userData.title} {userData.name}</h1>
        {evaluations?.length === 0 ? <></> : (
            <Card body className="mb-3">
                <Card.Title>Sie können an folgenden Evaluationen teilnhemen: </Card.Title>
                <ul>
                    {evaluations.map(e => (
                        <li key={e.id}><Link to={{pathname: '/evaluationQuestionnaire', state: {id: e.id}}}>{e.title}</Link></li>
                    ))}
                </ul>
            </Card>
        )}

        {courses?.map(c => (
            <Card body key={c.id} className="mb-3">
                <Row>
                    <Col> 
                        <Card.Title>{c.title}</Card.Title>
                    </Col>
                    <Col className="text-end">
                        <Link to={{pathname: '/viewCourse', state: {id: c.id}}}><Button size="sm">Kurs bearbeiten</Button></Link>
                    </Col>
                </Row>
                <hr />
                <AttendanceCourseView 
                    courseId={c.id}
                    schedule={JSON.parse(c.schedule)}
                    studentsEditable
                />
            </Card>
        ))}
        <Card body className="text-end">
            <Link to={{pathname: '/newCourse'}}><Button variant="success">Neuen Kurs erstellen</Button></Link>
        </Card>
        </>
    );
}