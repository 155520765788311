import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Pin, PinAngleFill, PinFill, TrashFill } from "react-bootstrap-icons";
import useFetchData from "../../hooks/useFetchData"
import utils from "../../service/utils";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";
import usePushNotificator from "../../hooks/usePushNotificator";
import { useState } from "react";
import ConfirmModal from "../../components/bt/ConfirmModal";

export default function FeedbackViewer() {
    const [ saveTrigger, saveListener ] = useTrigger();
    const [ pushNotification, Notificator ] = usePushNotificator();
    const feedbacks = useFetchData('feedback', {}, [ saveListener ]);

    const [ feedbackToBeDeleted, setFeedBackToBeDeleted ] = useState();
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);

    const setPinned = async (feedbackId, pinnedValue) => {
        const res = await api.put('feedback/' + feedbackId + '/pinned', { pinned: pinnedValue });
        if(!res.status === 'ok') {
            pushNotification('Interner Fehler', res.error, 'danger');
            return;
        }
        saveTrigger();
    }

    const showDeleteWarning = async (feedback) => {
        setFeedBackToBeDeleted(feedback)
        setShowDeleteModal(true)
    }

    const deleteFeedback = async () => {
        const res = await api.delete('feedback/' + feedbackToBeDeleted.id);

        if(res.status === 'ok') {
            pushNotification('Feedback gelöscht', 'Das Feedback wurde erfolgreich gelöscht', 'success');
            saveTrigger();
        } else {
            pushNotification('Interner Fehler', res.error, 'danger');
        }
    }

    return (<>
        <Notificator />
        <ConfirmModal title="Feedback löschen" confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={() => { deleteFeedback(); return true }} cancelBtnText="Abbrechen" show={showDeleteModal} setShow={setShowDeleteModal}>
            Möchten Sie wirklich das Feedback (Betreff: {feedbackToBeDeleted?.subject}) löschen?
        </ConfirmModal>
        <h1>Gesendetes Feedback einsehen</h1>
        <Card body>
            {feedbacks.length === 0 ? (
                <div className="text-center text-muted">-- kein Feedback bisher eingereicht ---</div>
            ) : feedbacks.map(feedback => (
                <FeedbackCard 
                    key={feedback.id}
                    senderId={feedback.senderId}
                    senderType={feedback.senderType}
                    senderInfo={JSON.parse(feedback.senderInfo)}
                    createdAt={feedback.createdAt}
                    subject={feedback.subject}
                    message={feedback.message}
                    answerMail={feedback.answerMail}
                    pinned={feedback.pinned}
                    togglePinned={() => setPinned(feedback.id, !Number(feedback.pinned))}
                    onDelete={() => showDeleteWarning(feedback)}
                />
            ))}
        </Card>
    </>)
}

function FeedbackCard({ senderId, senderType, senderInfo, createdAt, subject, message, answerMail, pinned, togglePinned, onDelete }) {
    return (
        <Card body className="mb-3">
            <span style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                <Card.Title>{subject}</Card.Title>
                {Number(pinned) ? <Pin /> : <></>}
            </span>
            <Card.Subtitle className="text-muted mb-1">vom {utils.sqlDateTimeToGermanDate(createdAt)}</Card.Subtitle>
            <Card.Text>{message}</Card.Text>
            <span style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <SenderText senderId={senderId} senderType={senderType} senderInfo={senderInfo} answerMail={answerMail} />
                <span>
                    <Button size="sm" variant="outline-secondary" className="me-1" onClick={togglePinned}>{Number(pinned) ? <PinAngleFill /> : <PinFill />}</Button>
                    <Button size="sm" variant="outline-danger" onClick={onDelete}><TrashFill /></Button>
                </span>
             </span>
        </Card>
    )
}

function SenderText({ senderId, senderType, senderInfo, answerMail }) {
    const germanType = senderType === 'student' ? 'Schüler' : senderType === 'teacher' ? 'Lehrer' : senderType === 'trainer' ? 'Kursleiter' : senderType === 'other' ? 'Anderer Benutzer' : 'Unbekannte Benutzergruppe';

    if(Number(senderId) === -1) {
        return <footer className="blockquote-footer"><i>Anonym</i> als {germanType}</footer>
    } else {
        const displayName = senderType === 'student' ? senderInfo.prename + ' ' + senderInfo.name : senderType === 'teacher' || senderType === 'trainer' ? senderInfo.title + ' ' + senderInfo.name : senderInfo.name;
        const viewPath = "/view" + senderType.charAt(0).toUpperCase() + senderType.substring(1)
        
        return (
            <footer className="blockquote-footer">
                <Link to={{pathname: viewPath, state: {id: senderId }}}>{displayName}</Link> ({germanType})
                {answerMail?.length ? <>, Antwort E-Mail: <a href={`mailto:${answerMail}?subject=Ihr Feedback bei Gerda-Taro GTAs`}>{answerMail}</a></> : <></>}
            </footer>
        )
    }
}