import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import EvalQuestionRenderer from "../../components/evaluation/EvalQuestionRenderer";
import useFetchData from "../../hooks/useFetchData";
import useLoginToken from "../../hooks/useLoginToken";
import usePageState from "../../hooks/usePageState";
import usePushNotificator from "../../hooks/usePushNotificator";
import api from "../../service/api";


const QuestionnaireWrapper = ({ children }) => (
    <Row className="justify-content-center">
        <Card body style={{ width: '40rem' }}>
            {children}  
        </Card>
    </Row>
);

export default function EvaluationQuestionnaire(props) {
    const [{ userType }, ] = useLoginToken();
    const [ pageState, , ] = usePageState(props, {id: '', courses: []});
    const [ errorOccured, setErrorOccured ] = useState(false);
    const [ showThankYou, setShowThankYou ] = useState(false)
    
    const history = useHistory();
    const evaluation = useFetchData('evaluations/questionnaire/' + pageState.id, {}, [], false, () => setErrorOccured(true));

    const [ preparedEvaluationData, setPreperedEvaluationData ] = useState([]);
    const [ answers, setAnswers ] = useState({})

    const [ currentSection, setCurrentSection ] = useState(-1)

    const [ pushNotification, Notificator ] = usePushNotificator({ defaultVariant: 'warning' })

    useEffect(() => {
        let workingData = evaluation.data
        if(workingData !== undefined) {
            workingData = JSON.parse(workingData)
            let courses = userType === 'other' ? pageState.courses : evaluation.courses

            for(let i in workingData) {
                let sectionQuestions = []

                for(let j in workingData[i].questions) {
                    let question = { ...workingData[i].questions[j], questionIndex: j }
                    if(question['showMode'] === 'everyone' || (question['showMode'] === 'visitsNoCourse' && courses.length === 0) || (question['showMode'] === 'visitsCourse' && courses.length > 0)) {
                        sectionQuestions.push(question)
                    } else {
                        if(question['showMode'] === 'courseSpecific') {
                            for(let course of courses) {
                                sectionQuestions.push({...question, course: course})
                            }
                        }
                    }
                }
                workingData[i].questions = sectionQuestions
            }

            // Remove all empty sections
            workingData = workingData.filter(section => section.questions.length > 0)

            // prepare answers
            let workingAnswers = {}
            for(let i in workingData) {
                for(let question of workingData[i].questions) {
                    const key = `${i},${question.questionIndex},` + ('course' in question ? question.course.id : -1)
                    workingAnswers[key] = ''
                }
            }
            setPreperedEvaluationData(workingData)
            setAnswers(workingAnswers)
            setCurrentSection(0)
        }
    }, [evaluation, pageState, userType])

    

    if (errorOccured) {
        return (
            <QuestionnaireWrapper>
                <h2 className="text-center">Evaluation nicht gefunden</h2>
                <div className="text-end">
                    <Button onClick={history.goBack} variant="secondary">Zurück</Button>
                </div>
            </QuestionnaireWrapper>
        )
    }

    if (currentSection === -1) {
        return (
            <QuestionnaireWrapper>
                <h2 className="text-center">Lade Evaluation...</h2>
                <div className="text-end">
                    <Button onClick={history.goBack} variant="secondary">Zurück</Button>
                </div>
            </QuestionnaireWrapper>
        )
    }

    if (preparedEvaluationData.length === 0) {
        return (
            <QuestionnaireWrapper>
                <h2 className="text-center">{evaluation.title}</h2>
                <hr />
                <div className="text-center mb-3">Diese Umfrage beinhaltet leider keine Fragen wir Dich!</div>
                <div className="text-end">
                    <Button onClick={history.goBack} variant="secondary">Zurück</Button>
                </div>
            </QuestionnaireWrapper>
        )
    }

    if (showThankYou) {
        return (
            <QuestionnaireWrapper>
                <h2 className="text-center">{evaluation.title}</h2>
                <hr />
                <div className="text-center mb-3">Vielen Dank für die Teilnahme an der Umfrage! <br />Die Ergebnisse sind eingegangen!</div>
                <div className="text-end">
                    <Button onClick={history.goBack} variant="secondary">Zurück</Button>
                </div>
            </QuestionnaireWrapper>
        )
    }

    const submitForm = async (e) => {
        e.preventDefault();
        if(currentSection === preparedEvaluationData.length-1) {
            if(userType !== 'other') {
                // Do the actual submition
                // construct the body
                let body = []
                for(let key in answers) {
                    body.push([ ...key.split(","), answers[key] ])
                }

                const res = await api.post(`evaluations/questionnaire/${pageState.id}/submit`, body)

                if(res.status === 'ok') {
                    setShowThankYou(true)
                } else {
                    pushNotification("Interner Fehler", "Es gab einen Fehler beim Absenden der Daten!", 'danger')
                }

            } else {
                setShowThankYou(true)
            }

        } else {
            setCurrentSection(currentSection + 1)
        }
    }

    return (
        <QuestionnaireWrapper>
            <h2 className="text-center">{evaluation.title}</h2>
            <hr />
            <Notificator />
            <Card.Title>{preparedEvaluationData[currentSection].title}</Card.Title>
            <Row className="mb-3">
                <Col>
                    <Card.Subtitle className="text-muted">Abschnitt {currentSection+1}/{preparedEvaluationData.length}</Card.Subtitle>
                </Col>
                <Col className="text-end">
                    <Card.Subtitle style={{color: 'red'}}>* Erforderlich</Card.Subtitle>    
                </Col>
            </Row>
            
            <form onSubmit={submitForm}>
                {preparedEvaluationData[currentSection].questions.map((question, i) => {
                    const answerKey = `${currentSection},${question.questionIndex},` + (question.course ? question.course.id : -1)
                    
                    return (
                        <div className="mb-5" key={i}>
                            <EvalQuestionRenderer 
                                id={answerKey}
                                type={question.type}
                                text={question.text}
                                required={question.required}
                                showMode={question.showMode}
                                properties={question.properties}
                                course={question.course}
                                value={answers[answerKey]}
                                setValue={v => setAnswers({...answers, [answerKey]: v})}
                        />
                        </div>
                    )
                })}
                <div className="text-end">
                    <Button onClick={history.goBack} variant="secondary" className="me-2">Abbrechen</Button>
                    <ButtonGroup className="me-2">
                        {currentSection === 0 ? <></> : (
                            <Button onClick={() => setCurrentSection(currentSection - 1)} variant="outline-primary">Zurück</Button>
                        )}
                        {currentSection === preparedEvaluationData.length-1 ? <></> : (
                            <Button variant="outline-primary" type="submit">Weiter</Button>
                        )}
                    </ButtonGroup>
                    {currentSection === preparedEvaluationData.length-1 ? (
                        <Button variant="success" type="submit">Absenden</Button>
                    ) : <></>}
                </div>
            </form>
        </QuestionnaireWrapper>
    )
}