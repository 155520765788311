import { Card, ListGroup } from "react-bootstrap";
import EvalQuestionRenderer from "./EvalQuestionRenderer";

export default function EvalSectionDisplay({ title, questions }) {
    return (
        <Card bg="dark" className="mb-2">
            <Card.Header style={{color: 'white'}}>{title}</Card.Header>
            <ListGroup variant="flush">
                {questions.map((question, i) => (
                    <ListGroup.Item key={i} >
                        <EvalQuestionRenderer {...question} dummy />
                    </ListGroup.Item>
                ))}
                {questions.length === 0 ? (
                    <ListGroup.Item>
                        <p className="text-muted text-center">--- Keine Fragen in diesem Abschnitt. In der Umfrage würde dieser übersprungen. ---</p>
                    </ListGroup.Item>
                ) : <></>}
            </ListGroup>
            <Card.Footer />
        </Card>
    )
}