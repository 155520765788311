import { Col, Form, Row } from "react-bootstrap";

export default function FormRow({ label, small, plaintext, labelSize, children }) {
    const marginClass = plaintext ? (small ? 'mb-2' : 'mb-4') : (small ? 'mb-1' : 'mb-3');

    if(plaintext) {
        return (
            <Row className={marginClass}>
                <Col md={labelSize}>{small ? <small>{label}</small> :  label}</Col>
                <Col md={12 - labelSize}>
                    {children}
                </Col>
            </Row>
        )
    } else {
        return (
            <Form.Group as={Row} className={marginClass}>
                <Form.Label column md={labelSize}>{small ? <small>{label}</small> :  label}</Form.Label>
                <Col md={12 - labelSize}>
                    {children}
                </Col>
            </Form.Group>
        );
    }
    
}

FormRow.defaultProps = {
    label: 'Label',
    small: false,
    plaintext: false,
    labelSize: 2
}