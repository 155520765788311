import { Badge, Col, Row, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import Scheduler from "../../../service/Scheduler"
import utils from "../../../service/utils"
import MarkdownRenderer from "../../markdown/MarkdownRenderer"

export default function CourseDisplay({ id, title, trainerName, trainerId, sector, place, classLevels, size, description, schedule, trainerLink, addLinkToReaderPage, hideSector }) {
    return (
        <>
        <Row>
            <Col><h3>{title}</h3></Col>
            {hideSector ? <></> : (
                <Col className="text-end"><h4><small className="text-muted">{sector}</small></h4></Col>
            )}
        </Row>
        <Row>
            <Col>
                <p className="text-muted">
                    von {trainerLink ? <Link to={{pathname: '/viewTrainer', state: {id: trainerId}}}>{trainerName}</Link> : trainerName}
                </p>
            </Col>
            <Col className="text-end">
                {addLinkToReaderPage ? <Link to={{pathname: '/reader', state: {id: id}}}><Badge pill size="sm" bg="secondary">Zum Reader</Badge></Link> : <></>}
            </Col>
        </Row>
        
        <div>
            <MarkdownRenderer>{description}</MarkdownRenderer>
        </div>
        <div className="d-none d-md-block">
            <Table bordered>
                <tbody>
                    <tr>
                        <th>Ort</th>
                        <th>Kursgröße</th>
                        <th>Wann</th>
                        <th>Klassenstufen</th>
                    </tr>
                    <tr>
                        <td>{place}</td>
                        <td>{size}</td>
                        <td>{new Scheduler(schedule).toDisplayText()}</td>
                        <td>{utils.classLevelsDisplayString(classLevels)}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
        <div className="d-md-none">
            <Table bordered>
                <tbody>
                    <tr>
                        <th>Ort</th>
                        <td>{place}</td>
                    </tr>
                    <tr>
                        <th>Kursgröße</th>
                        <td>{size}</td>
                    </tr>
                    <tr>
                        <th>Wann</th>
                        <td>{new Scheduler(schedule).toDisplayText()}</td>
                    </tr>
                    <tr>
                        <th>Klassenstufen</th>
                        <td>{utils.classLevelsDisplayString(classLevels)}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
        
        </>
    )
}

CourseDisplay.defaultProps = {
    trainerLink: false,
    addLinkToReaderPage: false
}