import { Form } from "react-bootstrap";
import useFetchData from "../../../hooks/useFetchData"
import FloatingSelect from '../../bt/FloatingSelect'

export default function EvaluationSelect({ defaultFirst, floating, value, setValue, dependencies, ...rest }) {
    
    const others = useFetchData('evaluations', {}, dependencies, first => {if(defaultFirst && value === '') setValue(first.id)})
    const SelectInUse = floating ? FloatingSelect : Form.Select;

    return (
        <SelectInUse value={value} onChange={e => setValue(e.target.value)} {...rest}>
            {defaultFirst ? <></> : <option disabled="disabled" value=''>Evaluation auswählen</option>}
            {others.map(o => <option value={o.id} key={o.id}>{o.title}</option>)}
        </SelectInUse>
    )
}

EvaluationSelect.defaultProps = {
    defaultFirst: false,
    floating: false,
    dependencies: []
}