import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormRow from "../../bt/FormRow";
import MarkdownEditor from "../../markdown/MarkdownEditor";
import ClassLevelRadioButtons from "../ClassLevelRadioButtons";
import ScheduleEditor from "../ScheduleEditor";
import TrainerSelect from "../selects/TrainerSelect";
import ConfirmModal from "../../bt/ConfirmModal";
import SectorSelect from "../selects/SectorSelect";
import useLoginToken from "../../../hooks/useLoginToken";

export default function CourseEdit({ title, setTitle, trainerId, setTrainerId, sector, setSector, place, setPlace, classLevels, setClassLevels, size, setSize, description, setDescription, schedule, setSchedule }) {
    const [{ userType }, ] = useLoginToken();
    const [ showNewTrainerModal, setShowNewTrainerModal ] = useState(false);
    const history = useHistory();

    return (
        <>
        <ConfirmModal title="Neuen Kursleiter erstellen" show={showNewTrainerModal} setShow={setShowNewTrainerModal} confirmBtnText="Weiterleiten" confirmCallback={() => history.push('/newTrainer')}>
            Wollen Sie einen neuen Kursleiter erstellen. Dabei werden Sie umgeleitet und <strong>alle nicht-gespeicherten Daten gelöscht!</strong>
        </ConfirmModal>
        <FormRow label="Kursname">
            <Form.Control type="text" placeholder="Kursname" value={title} onChange={e => setTitle(e.target.value)} required/>
        </FormRow>
        {userType === 'other' ? (
            <FormRow label="Kursleiter">
                <Row>
                    <Col sm="9">
                        <TrainerSelect value={trainerId} setValue={setTrainerId} />
                    </Col>
                    <Col>
                        <Button variant='outline-success' className="w-100" onClick={() => setShowNewTrainerModal(true)}>Neuen Kursleiter</Button>
                    </Col>
                </Row>
            </FormRow>
        ) : <></> }
        <FormRow label="Bereich">
            <SectorSelect value={sector} setValue={setSector} />
        </FormRow>
        <FormRow label="Ort">
            <Form.Control type="text" placeholder="zb. Raum 301" value={place} onChange={e => setPlace(e.target.value)} required/>
        </FormRow>
        <FormRow label="Klassenstufen">
            <ClassLevelRadioButtons value={classLevels} setValue={setClassLevels} />
        </FormRow>
        <FormRow label="Kursgröße">
            <Form.Control type="number" value={size} onChange={e => setSize(e.target.value)} required />
        </FormRow>
        <FormRow label="Beschreibung">
            <MarkdownEditor text={description} setText={setDescription} required placeholder="Hier können Sie den Kurs für die Schüler beschreiben" />
        </FormRow>
        <FormRow label="Kurszeiten">
            <ScheduleEditor schedule={schedule} setSchedule={setSchedule} />
        </FormRow>
        </>
    );
}