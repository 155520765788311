import { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import useFetchData from "../../hooks/useFetchData";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";
import ConfirmModal from "../bt/ConfirmModal";
import AttendanceTable from "./AttendanceTable";
import AttendanceTermSelect from "./AttendanceTermSelect";
import { ArrowCounterclockwise } from "react-bootstrap-icons";

export default function AttendanceCourseView({ courseId, schedule, studentsEditable, trainerEditable, refreshListener, studentLinks, updateTrainerFromStudents, updateStudentsFromTrainer }) {
    const [ term, setTerm ] = useState('all');
    const [ nextTerm, setNextTerm ] = useState('');
    const [ attendances, setAttendances ] = useState([]);
    const [ isSaved, setIsSaved ] = useState(true);
    const [ showNotSavedModal, setShowNotSavedModal ] = useState(false);
    const [ rollbackTrigger, rollbackListener ] = useTrigger();
    const [ pushNotification, Notificator ] = usePushNotificator();

    let attendanceData = useFetchData(`attendances/courses/${courseId}/${term}`, {}, [ courseId, term, refreshListener ]);

    const showTitles = term === 'once' || (term === 'all' && schedule.length > 1);
    const showTermSelect = schedule.length > 1;

    const termToTitle = () => {
        if(term === 'all') {
            return 'aller Termine';
        } else if(term === 'once') {
            return 'aller einmaligen Termine';
        } else {
            const t = schedule.filter(t => Number(t.uid) === Number(term))[0];
            return 'von ' + t.title;
        }
    }

    const innerSetAttendance = (att) => {
        setIsSaved(false);
        let crntAtts = attendances ? [...attendances] : [];

        const insertAtt = (att) => {
            crntAtts = crntAtts.filter(a => Number(a.student_id) !== Number(att.student_id) || a.date !== att.date || Number(a.term_id) !== Number(att.term_id) || Number(a.course_id) !== Number(att.course_id));
            crntAtts.push(att);
        }
        insertAtt(att);

        if(updateStudentsFromTrainer) {
            if(Number(att.student_id) === -1) {
                let studentsToUpdate = attendanceData.students;
                if(attendanceData.dates.filter(d => Number(d.term_id) === att.term_id)[0].voluntary)
                    studentsToUpdate = studentsToUpdate.filter(s => s.visit_voluntary.includes(att.term_id));
                
                if(Number(att.status) === 2)
                    studentsToUpdate.forEach(s => insertAtt({student_id: s.id, course_id: att.course_id, term_id: att.term_id, date: att.date, status: 2, comment: "Kursleiter entschuldigt"}));

                if(Number(att.status) === 3)
                    studentsToUpdate.forEach(s => insertAtt({student_id: s.id, course_id: att.course_id, term_id: att.term_id, date: att.date, status: 0, comment: ""}));
            }
            
        } else if(updateTrainerFromStudents) {
            if(Number(att.student_id) > -1) {
                let relevantStudents = attendanceData.students;
                if(attendanceData.dates.filter(d => Number(d.term_id) === att.term_id)[0].voluntary)
                    relevantStudents = relevantStudents.filter(s => s.visit_voluntary.includes(att.term_id));

                let allExcused = true;
                let noInfoMissing = true;
                relevantStudents.forEach(s => {
                    let foundAtt = crntAtts.filter(a => Number(a.student_id) === Number(s.id) && a.date === att.date && Number(a.term_id) === Number(att.term_id) && Number(a.course_id) === Number(att.course_id))[0];
                    if(foundAtt === undefined || Number(foundAtt.status) === 0) {
                        noInfoMissing = false;
                    } else if(Number(foundAtt.status) !== 2) {
                        allExcused = false;
                    }
                });

                if(noInfoMissing) {
                    insertAtt({student_id: -1, course_id: att.course_id, term_id: att.term_id, date: att.date, status: allExcused ? 2 : 1, comment: ""});
                }
            }
        }

        setAttendances(crntAtts);
    }

    const innerSetTerm = (term) => {
        if(!isSaved) {
            setNextTerm(term);
            setShowNotSavedModal(true);
        } else {
            setTerm(term);
        }
    }

    const saveAttendance = async () => {
        const res = await api.put('attendances', attendances);
        if(res.status === 'ok') {
            setIsSaved(true);
            pushNotification('Bearbeitung erfolgreich', 'Die Änderungen der Anwesenheit wurden erfolgreich gespeichert', 'success');
            attendanceData.attendances = attendances;
        }
    }

    useEffect(() => {
        setAttendances(attendanceData.attendances);
    }, [attendanceData, rollbackListener]);

    return (
        <>
        <ConfirmModal
            show={showNotSavedModal}
            setShow={setShowNotSavedModal}
            title="Nicht gespeichert"
            cancelCallback={() => {setIsSaved(true); setTerm(nextTerm);}}
            cancelBtnText="Nicht speichern"
            confirmBtnText="Speichern"
            confirmCallback={async () => {await saveAttendance(); setTerm(nextTerm);}}
        >
            Sie haben Ihre letzten Änderungen der Anwesenheit nicht gespeichert. Wollen Sie dies nun nachholen?
        </ConfirmModal>
        <Notificator />
        <Card className="mb-3">
            <Card.Header>
                <Row>
                    <Col sm="8">Anwesenheitsliste {termToTitle()}</Col>
                    <Col sm={showTermSelect ? 2 : 4} className="text-end">
                        {isSaved ? (<>
                            <Button size="sm" variant="outline-secondary" disabled className="me-2"><ArrowCounterclockwise size={18} /></Button>
                            <Button size="sm" variant="success" disabled>Speichern</Button>
                        </>) : (<>
                            <Button size="sm" variant="outline-secondary" className="me-2" onClick={() => { setIsSaved(true); rollbackTrigger(); }}><ArrowCounterclockwise size={18} /></Button>
                            <Button size="sm" variant="success" onClick={saveAttendance}>Speichern</Button>
                        </>)}
                    </Col>
                    {showTermSelect ? (
                        <Col><AttendanceTermSelect schedule={schedule} term={term} setTerm={innerSetTerm} /></Col> 
                    ) : <></>}
                    
                </Row>
            </Card.Header>
            {attendanceData instanceof Array ? <></> : (
                <AttendanceTable 
                    courseId={courseId} 
                    students={attendanceData.students} 
                    dates={attendanceData.dates} 
                    attendances={attendances} 
                    setAttendance={innerSetAttendance} 
                    schedule={schedule} 
                    showTitles={showTitles} 
                    studentsEditable={studentsEditable} 
                    trainerEditable={trainerEditable} 
                    studentLinks={studentLinks}
                /> )
            }
        </Card>
        </>
    );
}

AttendanceCourseView.defaultProps = {
    refreshListener: '',
    studentLinks: false,
    updateTrainerFromStudents: false,
    updateStudentsFromTrainer: false
}