import { useEffect } from "react";
import { useHistory } from "react-router-dom"

export default function RouteChangeListener({ callback }) {

    const history = useHistory();

    useEffect(() => {
        return history.listen(callback);
    }, [history, callback]);

    return <></>;
}