import { Form, InputGroup, Button } from "react-bootstrap";
import useLoginToken from "../../../hooks/useLoginToken";
import utils from "../../../service/utils";
import FormRow from "../../bt/FormRow";
import ClassSelect from "../selects/ClassSelect";
import StudentCoursesEditor from "../StudentCoursesEditor";

export default function StudentEdit({ prename, setPrename, name, setName, _class, setClass, pwd, setPwd, courses, setCourses }) {
    const [{ userType }, ] = useLoginToken();

    return (
        <>
        <FormRow label="Vorname">
            <Form.Control type="text" placeholder="Vorname" value={prename} onChange={e => setPrename(e.target.value)} required/>
        </FormRow>
        <FormRow label="Nachname">
            <Form.Control type="text" placeholder="Nachname" value={name} onChange={e => setName(e.target.value)} required/>
        </FormRow>
        {userType === 'other' ? (
            <FormRow label="Klasse">
                <ClassSelect value={_class} setValue={setClass} useClassAsValue />
            </FormRow>
        ) : <></>}
        <FormRow label="Passwort">
            <InputGroup>
                <Form.Control placeholder="Passwort" value={pwd} onChange={e => setPwd(e.target.value)} required/>
                <Button variant="outline-primary" onClick={() => setPwd(utils.genSimplePassword())}>Neu Generieren</Button>
            </InputGroup>
        </FormRow>
        {userType === 'other' ? (
            <FormRow label="Kurse">
                <StudentCoursesEditor courses={courses} setCourses={setCourses} _class={_class} />
            </FormRow>
        ) : <></>}
        </>
    );
}