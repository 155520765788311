let cashData         = {};
let pendingPromisses = {};

class ApiCash {

    genKey(apiEndpint, params) {
        return JSON.stringify([ apiEndpint, params ]);
    }

    valuePresent(key) {
        return key in cashData;
    }

    valueRequested(key) {
        return key in pendingPromisses;
    }

    valuePresentOrRequested(key) {
        return this.valuePresent(key) || this.valueRequested(key);
    }

    waitFor(key) {
        return pendingPromisses[key];
    }

    get(key) {
        return cashData[key];
    }

    putPendingPromise(key, promise) {
        pendingPromisses[key] = promise;
    }

    putValue(key, value) {
        cashData[key] = value;
    }

    free() {
        cashData         = {};
        pendingPromisses = {};
    }
}

export default new ApiCash();