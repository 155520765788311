import { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import ClassSelect from '../../components/forms/selects/ClassSelect';
import CoursesSelect from '../../components/forms/selects/CourseSelect';
import OtherSelect from '../../components/forms/selects/OtherSelect';
import StudentSelect from '../../components/forms/selects/StudentSelect';
import {  PlusLg, Search } from "react-bootstrap-icons";

export default function Dashboard(props) {

    const [ studentId, setStudentId ] = useState('');
    const [ classId, setClassId ]     = useState('');
    const [ courseId, setCourseId ]   = useState('');
    const [ otherId, setOtherId ]     = useState('');

    return (
        <>
        <h1>Willkommen</h1>
        <Row className="mb-1">
            <Col lg={3} md={6} xs={12} className="mb-1">
                <Card>
                    <Card.Header className="text-center"><b>Schüler</b></Card.Header>
                    <Card.Body>
                        <Form>
                            <StudentSelect size="sm" value={studentId} setValue={setStudentId} />
                            <div className="text-end mt-2" >
                                <Link to='/studentList'><Button variant='outline-secondary' className='me-2' size="sm"><Search size={14} className="mb-1" /></Button></Link>
                                <Link to='/newStudent'><Button variant='success' className='me-2' size="sm"><PlusLg className="mb-1" /></Button></Link>
                                <Link to={{pathname: '/viewStudent', state: {id: studentId }}}><Button variant='outline-primary' size="sm">Anzeigen</Button></Link>
                            </div>
                            <hr />
                            <Link to={{pathname: '/studentList'}}>Schülerliste</Link>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={3} md={6} xs={12} className="mb-1">
                <Card>
                    <Card.Header className="text-center"><b>Kurse</b></Card.Header>
                    <Card.Body>
                        <Form>
                            <CoursesSelect size="sm" value={courseId} setValue={setCourseId} />
                            <div className="text-end mt-2" >
                                <Link to='/newCourse'><Button variant='success' className='me-2' size="sm"><PlusLg className="mb-1" /></Button></Link>
                                <Link to={{pathname: '/viewCourse', state: {id: courseId }}}><Button variant='outline-primary' size="sm">Anzeigen</Button></Link>
                            </div>
                            <hr />
                            <Link to={{pathname: '/courseList'}}>Kursliste</Link>, <Link to={{pathname: '/trainerList'}}>Kursleiterliste</Link>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={3} md={6} xs={12} className="mb-1">
                <Card>
                    <Card.Header className="text-center"><b>Klassen</b></Card.Header>
                    <Card.Body>
                        <Form>
                            <ClassSelect size="sm" value={classId} setValue={setClassId}/>
                            <div className="text-end mt-2" >
                                <Link to='/newTeacher'><Button variant='success' className='me-2' size="sm"><PlusLg className="mb-1" /></Button></Link>
                                <Link to={{pathname: '/viewTeacher', state: {id: classId }}}><Button variant='outline-primary' size="sm">Anzeigen</Button></Link>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={3} md={6} xs={12} className="mb-1">
                <Card>
                    <Card.Header className="text-center"><b>Andere Benutzer</b></Card.Header>
                    <Card.Body>
                        <Form>
                            <OtherSelect size="sm" value={otherId} setValue={setOtherId} />
                            <div className="text-end mt-2" >
                                <Link to='/newOther'><Button variant='success' className='me-2' size="sm"><PlusLg className="mb-1" /></Button></Link>
                                <Link to={{pathname: '/viewOther', state: {id: otherId }}}><Button variant='outline-primary' size="sm">Anzeigen</Button></Link>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col md={6} xs={12} className="mb-1">
                <Card>
                    <Card.Header className="text-center"><b>Neues Schuljahr</b></Card.Header>
                    <Card.Body>
                        <Link to={{pathname: "/newYearInstructions"}}>Anleitung zum Schuljahreswechsel</Link><br />
                        <Link to={{pathname: "/generatePdf", state: {id: 'trainerAttendances'}}}>Anwesenheiten exportieren</Link><br />
                        <Link to={{pathname: "/editYear"}}>Bearbeitung des Schuljahrs</Link><br />
                        <Link to={{pathname: "/studentForwarding"}}>Schuljahreswechsel für Schüler Vorbereiten</Link> / <Link to={{pathname: "/studentForwardingPreview"}}>Prüfen und Durchführen</Link>
                        
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6} xs={12} className="mb-1">
                <Card>
                    <Card.Header className="text-center"><b>Weitere Operationen</b></Card.Header>
                    <Card.Body>
                        <Link to={{pathname: "/courseSelectionManager"}}>Kurswahl</Link>, <Link to={{pathname: "/courseSelection"}}>Kurswahl-Vorschau</Link><br />
                        <Link to={{pathname: "/viewEvaluation"}}>Evaluationen</Link><br />
                        <Link to={{pathname: "/generatePdf"}}>PDFs generieren</Link><br />
                        <Link to={{pathname: "/backups"}}>Backups</Link><br />
                        <Link to={{pathname: "/feedbackViewer"}}>Feedbacks einsehen</Link>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
        </>
    );
}