import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { ListOl, ListUl } from "react-bootstrap-icons";
import MarkdownInfoModal from "./MarkdownInfoModal";
import MarkdownRenderer from "./MarkdownRenderer";

export default function MarkdownEditor({ text, setText, textAreaRows, placeholder, required, hideTextStyleOptions, hideListOptions, hideIndentOption, hideTitleOptions, hideLinkOption }){
    const textarea = useRef();
    const [ inEditMode, setInEditMode ] = useState(true);
    const [ showInfoModal, setShowInfoModal ] = useState(false);
    const [ selectionStartUpdater, setSelectionStartUpdater ] = useState(0);
    const [ selectionEndUpdater, setSelectionEndUpdater ]     = useState(0);

    const toggleTextStyle = (symbol) => {
        // if last charakter of selection is a space, correct selection (as markdown doesnt work than)
        if(text.charAt(textarea.current.selectionEnd - 1) === ' ')
            textarea.current.setSelectionRange(textarea.current.selectionStart, textarea.current.selectionEnd - 1);


        if(text.substring(textarea.current.selectionStart - symbol.length, textarea.current.selectionStart) === symbol
            && text.substring(textarea.current.selectionEnd, textarea.current.selectionEnd + symbol.length) === symbol) {

            setText(text.substring(0, textarea.current.selectionStart - symbol.length)
                + text.substring(textarea.current.selectionStart, textarea.current.selectionEnd)
                + text.substring(textarea.current.selectionEnd + symbol.length))
            
            setSelectionStartUpdater(textarea.current.selectionStart - symbol.length);
            setSelectionEndUpdater(textarea.current.selectionEnd - symbol.length);
        } else {
            setText(text.substring(0, textarea.current.selectionStart)
                + symbol
                + text.substring(textarea.current.selectionStart, textarea.current.selectionEnd)
                + symbol
                + text.substring(textarea.current.selectionEnd));
            
            setSelectionStartUpdater(textarea.current.selectionStart + symbol.length);
            setSelectionEndUpdater(textarea.current.selectionEnd + symbol.length);
        }
        
    }

    const insertAddLineStart = (symbol) => {
        const firstLineStart = text.substring(0, textarea.current.selectionStart).lastIndexOf('\n');
        let newText = firstLineStart === -1 ? symbol + text : text.substring(0, firstLineStart + 1) + symbol + text.substring(firstLineStart + 1);
        let newSelectionStart = textarea.current.selectionStart + symbol.length;
        let newSelectionEnd   = textarea.current.selectionEnd   + symbol.length;
        
        let selectedLines = newText.substring(newSelectionStart, newSelectionEnd).split('\n');
        newText = newText.substring(0, newSelectionStart)
            + selectedLines.join('\n' + symbol)
            + newText.substring(newSelectionEnd);
        
        newSelectionEnd += (selectedLines.length - 1) * symbol.length;

        setText(newText);
        setSelectionStartUpdater(newSelectionStart);
        setSelectionEndUpdater(newSelectionEnd);
    }

    const insertLink = () => {
        if(textarea.current.selectionStart === textarea.current.selectionEnd) {
            setText(text.substring(0, textarea.current.selectionStart)
                + '[Link Text](https://taroschule.de)'
                + text.substring(textarea.current.selectionEnd));
            textarea.current.focus();
        } else {
            setText(text.substring(0, textarea.current.selectionStart)
                + '['
                + text.substring(textarea.current.selectionStart, textarea.current.selectionEnd)
                + '](https://taroschule.de)'
                + text.substring(textarea.current.selectionEnd));
            
                setSelectionStartUpdater(textarea.current.selectionStart + 1);
                setSelectionEndUpdater(textarea.current.selectionEnd + 1);
        }
    }


    useEffect(() => {
        textarea.current.setSelectionRange(selectionStartUpdater, selectionEndUpdater);
        textarea.current.focus();
    }, [selectionStartUpdater, selectionEndUpdater])

    if(inEditMode) {
        return (
            <>
            <MarkdownInfoModal show={showInfoModal} setShow={setShowInfoModal} />
            <Card>
                <Card.Header>
                    <Row>
                        <Col sm="10">
                            {hideTextStyleOptions ? <></> : (
                                <ButtonGroup size='sm' className="me-2">
                                    <Button variant='outline-secondary' onClick={() => toggleTextStyle('**')}>Fett</Button>
                                    <Button variant='outline-secondary' onClick={() => toggleTextStyle('_')}>Kursiv</Button>
                                </ButtonGroup>
                            )}
                            {hideListOptions ? <></> : (
                                <ButtonGroup size='sm' className="me-2">
                                    <Button variant='outline-secondary' onClick={() => insertAddLineStart('- ')}><ListUl size={18}/></Button>
                                    <Button variant='outline-secondary' onClick={() => insertAddLineStart('1. ')}><ListOl size={18}/></Button>
                                </ButtonGroup> 
                            )}
                            {hideIndentOption ? <></> : (
                                <Button variant='outline-secondary' size='sm' onClick={() => insertAddLineStart('    ')} className="me-2">Einschub</Button>
                            )}
                            {hideTitleOptions ? <></> : (
                                <ButtonGroup size='sm' className="me-2">
                                    <Button variant='outline-secondary' onClick={() => insertAddLineStart('# ')}>Überschrift 1</Button>
                                    <Button variant='outline-secondary' onClick={() => insertAddLineStart('## ')}>Überschrift 2</Button>
                                </ButtonGroup>
                            )}
                            {hideLinkOption ? <></> : (
                                <Button variant='outline-secondary' size='sm' onClick={insertLink} className="me-2">Link</Button>
                            )}
                        </Col>
                        <Col className="text-end">
                            <Button variant='outline-primary' size='sm' onClick={() => setShowInfoModal(true)} className="me-2">Info</Button>
                            <Button variant='outline-primary' size="sm" onClick={() => setInEditMode(false)}>Vorschau</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Form.Control as="textarea" ref={textarea} placeholder={placeholder} value={text} onChange={e => setText(e.target.value)} required={required} rows={textAreaRows} />
                </Card.Body>
            </Card>
            </>
        );
        } else {
            return (
                <>
                <MarkdownInfoModal show={showInfoModal} setShow={setShowInfoModal} />
                <Card>
                    <Card.Header>
                        <div className="text-end">
                            <Button variant='outline-primary' size='sm' onClick={() => setShowInfoModal(true)} className="me-2">Info</Button>
                            <Button variant='outline-primary' size="sm" onClick={() => setInEditMode(true)}>Bearbeiten</Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <MarkdownRenderer>{text}</MarkdownRenderer>
                    </Card.Body>
                </Card>
                </>
            );
        }
}

MarkdownEditor.defaultProps = {
    textAreaRows: 10,
    placeholder: '',
    required: false,

    hideTextStyleOptions: false,
    hideListOptions: false,
    hideIndentOption: false,
    hideTitleOptions: false,
    hideLinkOption: false
}