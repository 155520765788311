import { Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import utils from "../../service/utils";
import "./attendanceTable.css";

export default function AttendanceTable({ students, dates, courseId, attendances, setAttendance, schedule, showTitles, studentsEditable, trainerEditable, hideTrainerAttendance, studentLinks, hideStudentNames }) {
    if(students.length === 0) {
        return (
            <Card.Body className="text-center text-muted">
                <p><em>--- An diesen Termin nimmt kein Schüler teil! ---</em></p>
            </Card.Body>
        )
    }

    if(dates.length === 0) {
        return (
            <Card.Body className="text-center text-muted">
                <p><em>--- Dieser Termin findet noch nicht statt ---</em></p>
            </Card.Body>
        )
    }

    return (
        <Table className="attendance-table">
            <thead>
                <tr>
                    {hideStudentNames ? <></> : <th></th>}
                    {dates.map((d, i) => showTitles ? (
                        <OverlayTrigger placement="top" delay={{ show: 50, hide: 100 }} key={i} overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                                {schedule.filter(s => Number(s.uid) === Number(d.term_id))[0].title}
                            </Tooltip>
                        )}>
                            <td>{utils.sqlDateToShortGermanDate(d.date)}</td>
                        </OverlayTrigger>
                    ) : (
                        <td key={i}>
                            {utils.sqlDateToShortGermanDate(d.date)}
                        </td>
                    ))}
                </tr>
                {hideTrainerAttendance ? <></> : (
                    <AttendanceTableRow dates={dates} courseId={courseId} attendances={attendances} setAttendance={setAttendance} editable={trainerEditable} hideStudentNames={hideStudentNames} student={{
                        prename: 'Kursleiter',
                        name: '',
                        id: -1,
                        visit_voluntary: dates.map(d => d.term_id)
                    }} />
                )}
            </thead>
            <tbody>
            
                {students.map((s, i) => <AttendanceTableRow key={i} student={s} dates={dates} courseId={courseId} attendances={attendances} setAttendance={setAttendance} editable={studentsEditable} studentLinks={studentLinks} hideStudentNames={hideStudentNames} />)}
            </tbody>
        </Table>
    )
}

AttendanceTable.defaultProps = {
    hideTrainerAttendance: false,
    hideStudentNames: false
}

function AttendanceTableRow({ student, courseId, dates, attendances, setAttendance, editable, studentLinks, hideStudentNames }) {
    return (
        <tr>
            { hideStudentNames ? <></> : (
                <th>
                    {studentLinks ? <Link to={{pathname: '/viewStudent', state: {id : student.id}}}>{student.prename} {student.name}</Link> : <>{student.prename} {student.name}</>}
                </th>
            )}
            {dates.map((d, i) => {
                const innerSetAttendance = (status, comment = '') => {
                    setAttendance({ student_id: student.id, course_id: courseId, date: d.date, status: status, comment: comment, term_id: d.term_id });
                }

                if(d.voluntary && !student.visit_voluntary.includes(d.term_id)) {
                    return <AttendanceTableCellNoAttend key={i} />;
                } else {
                    return <AttendanceTableCell key={i} attendance={attendances?.filter(a => Number(a.student_id) === Number(student.id) && a.date === d.date && Number(a.term_id) === Number(d.term_id) && Number(a.course_id) === Number(courseId))[0]} setAttendance={innerSetAttendance} editable={editable} />
                }
            })}
        </tr>
    )
}

function AttendanceTableCell({ attendance, setAttendance, editable }) {
    const status = attendance === undefined ? 0 : Number(attendance.status);
    const comment = attendance === undefined ? '' : attendance.comment;

    let text = '?';
    switch (status) {
        case 0: text = 'X'; break;
        case 1: text = 'ANW'; break;
        case 2: text = 'BEK'; break;
        case 3: text = 'UNB'; break;
        default:
    }

    const click = () => {
        if(editable) {
            setAttendance((status + 1) % 4);
        }
        
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {comment}
        </Tooltip>
    );

    if(comment === '') {
        return (
            <td onClick={click} className={'attendance-table-cell' + (editable ? ' hoverable' : '')}>
                <div className={'attendance-table-cell-' + status}>
                {text}
                </div>
            </td>
        )
    } else {
        return (
            <OverlayTrigger placement="top" delay={{ show: 50, hide: 100 }} overlay={renderTooltip}>
                <td onClick={click} className={'attendance-table-cell' + (editable ? ' hoverable' : '')}>
                    <div className={'attendance-table-cell-' + status}>
                    {text}
                    </div>
                </td>
            </OverlayTrigger>
        )
    }

    
}

function AttendanceTableCellNoAttend() {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Nimmt nicht teil
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="top" delay={{ show: 50, hide: 100 }} overlay={renderTooltip}>
            <td className="attendance-table-cell attendance-table-cell-no-attend"></td>
        </OverlayTrigger>
    );
}