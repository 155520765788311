import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import useFetchData from "../../../hooks/useFetchData";
import utils from "../../../service/utils";

export default function CourseList() {
    const courses = useFetchData('courses', {orderBySector: ''});

    return (
        <Card body>
            <Card.Title>Kursliste</Card.Title>
            <hr />
            <Table striped>
                <thead>
                    <tr>
                        <th>Bereich</th>
                        <th>Titel</th>
                        <th>Ort</th>
                        <th>Belegung</th>
                        <th>Klassenstufen</th>
                        <th>Leiter</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map(c => (
                        <tr key={c.id}>
                            <td>{c.sector}</td>
                            <td><Link to={{pathname: '/viewCourse', state:{id: c.id}}}>{c.title}</Link></td>
                            <td>{c.place}</td>
                            <td>{c.count_students} / {c.size}</td>
                            <td>{utils.classLevelsDisplayString(c.class_levels)}</td>
                            <td><Link to={{pathname: '/viewTrainer', state:{id: c.trainer_id}}}>{c.trainer_title} {c.trainer_name}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card>
    )
}