import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap'
import useFetchData from '../../hooks/useFetchData'

export default function AttendanceRatioBar({ person, id, courseId }) {

    const counts = useFetchData(`attendances/counts/${person}s/${id}` + (courseId === 'not specified' ? '' : `/courses/${courseId}`), {}, [person, id, courseId]);

    const getTotal = () => {
        return counts instanceof Array ? 0 : Object.values(counts).reduce((prev, crnt) => prev + crnt);
    }

    if(getTotal() === 0) {
        return (
            <OverlayTrigger placement="top" delay={{show: 50, hide: 100}} overlay={(props => (
                <Tooltip {...props}>keine Daten</Tooltip>
            ))} >
                <ProgressBar />
            </OverlayTrigger>
        );
    } else {
        return (
            <OverlayTrigger placement="top" delay={{show: 50, hide: 100}} overlay={(props => (
                <Tooltip {...props}>
                    {Math.round(counts[1] / getTotal() * 100)}% anwesend<br />
                    {Math.round(counts[2] / getTotal() * 100)}% entschuldigt<br />
                    {Math.round(counts[3] / getTotal() * 100)}% unentschuldigt<br />
                </Tooltip>
            ))} >
                <ProgressBar>
                    <ProgressBar variant="success" now={counts[1] / getTotal() * 100} label={`${counts[1]}`}/>
                    <ProgressBar variant="warning" now={counts[2] / getTotal() * 100} label={`${counts[2]}`}/>
                    <ProgressBar variant="danger" now={counts[3] / getTotal() * 100} label={`${counts[3]}`}/>
                </ProgressBar>
            </OverlayTrigger>
        )
    }
}

AttendanceRatioBar.defaultProps = {
    person: 'student',
    courseId: 'not specified'
}