import { Form } from "react-bootstrap";
import FormRow from "../../bt/FormRow";
import RepeatPassword from "../RepeatPassword";
import TitleNameInput from "../TitleNameInput";

export default function TeacherEdit({ _class, setClass, title, setTitle, name, setName, pwd, setPwd, pwd2, setPwd2, mode }) {
    let pwdPlaceholder = mode === 'edit' ? 'Passwort wird gerade nicht geändert' : 'Passwort';

    return (
        <>
        {mode === 'edit' ? <div className="text-muted mb-2">Wenn das Passwort nicht geändert werden soll, einfach Passwortfeld freilassen!</div> : <></>}
        <FormRow label="Klasse">
            <Form.Control type="text" placeholder="zb. 06/3" value={_class} onChange={e => setClass(e.target.value)} pattern="[0-1]\d/\d" required/>
        </FormRow>
        <FormRow label="Name">
            <TitleNameInput name={name} setName={setName} title={title} setTitle={setTitle} />
        </FormRow>
        <FormRow label="Passwort">
            <Form.Control type="password" placeholder={pwdPlaceholder} value={pwd} onChange={e => setPwd(e.target.value)} required/>
        </FormRow>
        {mode === 'new' || pwd !== '' ? (
            <FormRow label="Passwort wiederholen">
                <RepeatPassword pwd={pwd} pwd2={pwd2} setPwd2={setPwd2} />
            </FormRow>
        ) : <></> }
        </>
    )
}

TeacherEdit.defaultProps = {
    mode: 'edit'
}