import { useEffect, useState } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import ConfirmModal from "../../components/bt/ConfirmModal";
import StudentList from "../../components/StudentList";
import useFetchData from "../../hooks/useFetchData";
import usePageState from "../../hooks/usePageState";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";
import utils from "../../service/utils";

export default function ParticipantsEditor(props) {
    const [ pushNotification, Notificator ] = usePushNotificator();
    const [ pageState, , PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const allStudents = useFetchData('students', {orderByClass: ''});
    const termParticipants = useFetchData('termParticpants/' + pageState.id, {}, [saveListener]);

    const [ selectedStudents, setSelectedStudents ] = useState([])
    const [ selectableStudents, setSelectableStudents ] = useState([])

    const [ showTooManyStudentWarningModal, setShowTooManyStudentWarningModal ] = useState(false)
    const [ studentWaitingToBeSelected, setStudentWaitingToBeSelected ] = useState()

    const [ onlyShowStudentsInRigthClassLevel, setOnlyShowStudentsInRigthClassLevel ] = useState(true);

    useEffect(() => {
        setSelectedStudents(termParticipants.students?.map(s => ({ id: s.student_id, prename: s.prename, name: s.name, class: s.class })))
    }, [ termParticipants ])

    useEffect(() => {
        const notSelectedStudents = allStudents.filter(s => selectedStudents?.filter(ss => ss.id === s.id).length === 0);
        if(onlyShowStudentsInRigthClassLevel) {
            const classLevels = termParticipants?.class_levels?.split("|");
            setSelectableStudents(notSelectedStudents.filter(s => classLevels.indexOf(s.class.split("/")[0]) !== -1))
        } else {
            setSelectableStudents(notSelectedStudents)
        }
    }, [ selectedStudents, allStudents, onlyShowStudentsInRigthClassLevel, termParticipants ])

    const onAddStudent = (s, suppressCheck = false) => {
        if(!suppressCheck && selectedStudents?.length === Number(termParticipants?.size)) {
            setStudentWaitingToBeSelected(s)
            setShowTooManyStudentWarningModal(true)
        } else {
            setSelectedStudents([...selectedStudents, s])
        }
    }

    const save = async () => {
        const body = { students: selectedStudents.map(s => s.id)}
        const res = await api.put('courses/' + pageState.id + "/participants", body);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Die Teilnehmer des Kurses wurden erfolgreich gesetzt', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }

    }

    return (
        <>
        <PageStateReloader />
        <Notificator />
        <h1>Bearbeiten der Teilnehmer von {termParticipants?.title}</h1>
        <ConfirmModal title="Überschreitung der Kurskapazität" confirmBtnText="Trotzdem Hinzufügen" confirmCallback={() => {onAddStudent(studentWaitingToBeSelected, true); return true;}} show={showTooManyStudentWarningModal} setShow={setShowTooManyStudentWarningModal}>
            Der Kurs ist bereits voll! Wollen Sie trotzdem {studentWaitingToBeSelected?.prename} {studentWaitingToBeSelected?.name} ({studentWaitingToBeSelected?.class}) zum Kurs hinzufügen?
        </ConfirmModal>
        <Card body className="mb-3">
            <Card.Title>Teilnehmer (<span style={selectedStudents?.length > termParticipants?.size ? {color: "red"} : {}}>{selectedStudents?.length}</span>/{termParticipants?.size})</Card.Title>
            <div className="mb-1">
                {selectedStudents?.length === 0 ? (
                    <i className="text-muted">--- Zurzeit sind keine Schüler in dem Kurs ---</i>
                ) : selectedStudents?.map(s => (
                    <StudentBadge 
                        student={s} 
                        key={s.id} 
                        remove={() => setSelectedStudents(selectedStudents.filter(ss => s.id !== ss.id))}
                    />
                ))}
            </div>
            <div className="text-end">
                <Button variant="success" onClick={save}>Speichern</Button>
            </div>
        </Card>
        <Card body>
            <Card.Title>Schüler hinzufügen</Card.Title>
            <Form.Check label={"Zeige nur Schüler in passenden Klassenstufen (" + utils.germanImplode(termParticipants?.class_levels?.split("|")) + ")"} id="bla" checked={onlyShowStudentsInRigthClassLevel} onChange={() => setOnlyShowStudentsInRigthClassLevel(!onlyShowStudentsInRigthClassLevel)}/>
            <StudentList students={selectableStudents} searchable paginated maxPageEntries={25} addButtons onClickStudentButton={onAddStudent}/>
        </Card>
        </>
    )
}

function StudentBadge({ student, remove }){
    return (
        <Badge className="me-2 hoverable disable-text-select" onClick={remove}>{student.prename} {student.name}</Badge>
    )
}