import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import useLoginToken from './hooks/useLoginToken';
import api from './service/api';
import ProtectedRoute from './components/ProtectedRoute';
import Unauthorized from './pages/Unauthorized';
import SpreadRootByUserType from './components/SpreadRootByUserType';
import GTANavbar from './parts/GTANavbar';
import { Container, Modal, Button, Alert } from 'react-bootstrap';
import NewStudent from './pages/newUsers/NewStudent';
import NewOther from './pages/newUsers/NewOther';
import NewTeacher from './pages/newUsers/NewTeacher';
import NewCourse from './pages/newUsers/NewCourse';
import NewTrainer from './pages/newUsers/NewTrainer';
import ViewOther from './pages/viewUsers/ViewOther';
import ViewTeacher from './pages/viewUsers/ViewTeacher';
import ViewStudent from './pages/viewUsers/ViewStudent';
import ViewCourse from './pages/viewUsers/ViewCourse';
import ViewTrainer from './pages/viewUsers/ViewTrainer';
import RouteChangeListener from './components/RouteChangeListener';
import apiCash from './service/apiCash';
import CompleteStudentList from './pages/other/lists/CompleteStudentList';
import CourseList from './pages/other/lists/CourseList';
import TrainerList from './pages/other/lists/TrainerList';
import EditYear from './pages/other/EditYear';
import { useState } from 'react';
import NotFound from './pages/NotFound';
import NewYearInstructions from './pages/other/NewYearInstructions';
import EditTermParticipants from './pages/trainer/EditTermParticipants';
import ViewEvaluation from './pages/evaluation/ViewEvaluation';
import NewEvaluation from './pages/evaluation/NewEvaluation';
import EvaluationQuestionnaire from './pages/evaluation/EvaulationQuestionnaire';
import GeneratePdf from './pages/other/pdfGenerators/GeneratePdf';
import NewCustomPdfTemplate from './pages/other/pdfGenerators/NewCustomPdfTemplate';
import StudentForwarding from './pages/teacher/StudentForwarding';
import StudentForwardingPreview from './pages/other/StudentForwardingPreview';
import CourseSelection from './pages/student/CourseSelection';
import ReaderPage from './pages/reader/ReaderPage';
import CourseSelectionManager from './pages/other/CourseSelectionManager';
import ParticipantsEditor from './pages/trainer/ParticipantsEditor';
import Backups from './pages/other/Backups';
import FeedbackPage from './pages/Feedback';
import FeedbackViewer from './pages/other/FeedbackViewer';


function App() {
  const [ loginToken, setLoginToken ]             = useLoginToken();
  const [ showExpiredModal, setShowExpiredModal ] = useState(false);
  const [ showFeedbackAlert, setShowFeedbackAlert ] = useState(true);

  const logout = () => {
    window.location.replace('/');
    setLoginToken({auth: false});
    apiCash.free();
};

  const checkLoginExpired = (withModal) => {
    if(api.isLoggedIn() && (!('expire' in loginToken) || Number(loginToken.expire) * 1000 <= Date.now())) {
      if(withModal)
        setShowExpiredModal(true);
      else
        logout();
    }
  }

  if(!showExpiredModal)
    checkLoginExpired(false);

  return (
    <BrowserRouter>
      <RouteChangeListener callback={() => { apiCash.free(); checkLoginExpired(true);}} />
      <GTANavbar loginToken={loginToken} logout={logout} />

      <Modal show={showExpiredModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Sitzung abgelaufen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Die derzeitige Sitzung ist abgelaufen. Sie müssen sich erneut anmelden!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => { logout(); setShowExpiredModal(false); }}>Abmelden</Button>
        </Modal.Footer>
      </Modal>
      
      {showExpiredModal ? <></> : (
        <Container>
          {showFeedbackAlert && api.isLoggedIn() ? (
            <Alert variant="warning" onClose={() => setShowFeedbackAlert(false)} dismissible>
              Wie Du vielleicht schon bemerkt hast, ist diese Webseite noch neu und steckt möglicherweise voller Fehler. Falls Dir einer auffällt, dich etwas nervt oder du andere Verbesserungsvorschläge hast, gib doch bitte <Link to="/feedback">hier</Link> oder über die Navigationsleiste ein Feedback. Danke!
            </Alert>
          ) : <></>}
          <Switch>
            <Route exact path='/' component={SpreadRootByUserType} />
            <Route exact path='/reader' component={ReaderPage} />

            <ProtectedRoute exact path='/newStudent' userTypes={['other', 'teacher']} component={NewStudent} />
            <ProtectedRoute exact path='/newCourse' userTypes={['other', 'trainer']} component={NewCourse} />
            <ProtectedRoute exact path='/newTrainer' userTypes={['other']} component={NewTrainer} />
            <ProtectedRoute exact path='/newTeacher' userTypes={['other']} component={NewTeacher} />
            <ProtectedRoute exact path='/newOther' userTypes={['other']} component={NewOther} />

            <ProtectedRoute exact path='/viewStudent' userTypes={['other', 'teacher']} component={ViewStudent} />
            <ProtectedRoute exact path='/viewCourse' userTypes={['other', 'trainer']} component={ViewCourse} />
            <ProtectedRoute exact path='/viewTrainer' userTypes={['other', 'trainer']} component={ViewTrainer} />
            <ProtectedRoute exact path='/editTermParticipants' userTypes={['other', 'trainer']} component={EditTermParticipants} />
            <ProtectedRoute exact path='/participantsEditor' userTypes={['other', 'trainer']} component={ParticipantsEditor} />
            <ProtectedRoute exact path='/viewTeacher' userTypes={['other', 'teacher']} component={ViewTeacher} />
            <ProtectedRoute exact path='/viewOther' userTypes={['other']} component={ViewOther} />
            
            <ProtectedRoute exact path='/studentList' userTypes={['other']} component={CompleteStudentList} />
            <ProtectedRoute exact path='/courseList' userTypes={['other']} component={CourseList} />
            <ProtectedRoute exact path='/trainerList' userTypes={['other']} component={TrainerList} />

            <ProtectedRoute exact path='/newYearInstructions' userTypes={['other']} component={NewYearInstructions} />
            <ProtectedRoute exact path='/studentForwarding' userTypes={['other', 'teacher']} component={StudentForwarding} />
            <ProtectedRoute exact path='/studentForwardingPreview' userTypes={['other']} component={StudentForwardingPreview} />
            <ProtectedRoute exact path='/editYear' userTypes={['other']} component={EditYear} />

            <ProtectedRoute exact path='/generatePdf' userTypes={['other']} component={GeneratePdf} />
            <ProtectedRoute exact path='/newCustomPdfTemplate' userTypes={['other']} component={NewCustomPdfTemplate} />

            <ProtectedRoute exact path='/backups' userTypes={['other']} component={Backups} />

            <ProtectedRoute exact path='/viewEvaluation' userTypes={['other']} component={ViewEvaluation} />
            <ProtectedRoute exact path='/newEvaluation' userTypes={['other']} component={NewEvaluation} />
            <ProtectedRoute exact path='/evaluationQuestionnaire' userTypes={['other', 'student', 'trainer']} component={EvaluationQuestionnaire} />

            <ProtectedRoute exact path='/courseSelection' userTypes={['student', 'other']} component={CourseSelection} />
            <ProtectedRoute exact path='/courseSelectionManager' userTypes={['other']} component={CourseSelectionManager} />

            <ProtectedRoute exact path='/feedback' userTypes={['other', 'student', 'trainer', 'teacher']} component={FeedbackPage} />
            <ProtectedRoute exact path='/feedbackViewer' userTypes={['other']} component={FeedbackViewer} />

            <Route exact path='/unauthorized' component={Unauthorized} />

            <Route component={NotFound} />
          </Switch>
        </Container>
      )}
    </BrowserRouter>
  );
  
}

export default App;
