import './GTANavbar.css'
import GTASmallLogo from '../assets/LogoTaroGymnasiumSmall.svg'
import { Navbar, Container, Button, Nav } from 'react-bootstrap';
import { useState } from 'react';
import ConfirmModal from '../components/bt/ConfirmModal';
import { Link } from 'react-router-dom';
import { GearFill } from "react-bootstrap-icons";
import api from '../service/api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function GTANavbar({ loginToken, logout }) {
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const location = useLocation();

    if(location.pathname === '/' && !api.isLoggedIn())
        return <></>

    return (
        <>
        <Navbar className="gtaNavbar" expand="lg" fixed="top">
            <Container>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Navbar.Brand>
                        <img src={GTASmallLogo} alt="GTA Logo" width="35" height="30" className="d-inline-block align-text-top me-2"/>
                        GTA-Webseite
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" >
                        <Nav.Link as={Link} to={{pathname: "/reader"}}  active={true}>Kursübersicht</Nav.Link>
                        {api.isLoggedIn() ? <Nav.Link as={Link} to={{pathname: "/feedback"}}  active={true}>Feedback</Nav.Link> : <></>}
                        <Nav.Link href="https://taroschule.de/" target="_blank" active={true}>Schulseite</Nav.Link>
                    </Nav>
                    <Nav>
                        <span className='d-none d-lg-block'>
                            <Navbar.Text className="me-2">
                                {getDisplayNameFromToken(loginToken)}
                            </Navbar.Text>
                            <SettingsLink loginToken={loginToken}>
                                <Button variant="outline-secondary" className="me-2">
                                    <GearFill size={18} className="mb-1" />
                                </Button>
                            </SettingsLink>
                            {api.isLoggedIn() ? (
                                <Button variant="danger" onClick={() => setLogoutModalOpen(true)}>Abmelden</Button>
                            ) : (
                                <Link to={{pathname: '/'}}><Button variant="outline-primary">Anmelden</Button></Link>
                            )}
                        </span>
                        <span className='d-lg-none'>
                            <SettingsLink asNavLink loginToken={loginToken}>Einstellungen</SettingsLink>
                            {api.isLoggedIn() ? (
                                <Nav.Link active onClick={() => setLogoutModalOpen(true)}>Abmelden</Nav.Link>
                            ) : (
                                <Nav.Link active href="/">Anmelden</Nav.Link>
                            )}
                        </span>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <ConfirmModal title="Abmelden" show={logoutModalOpen} setShow={setLogoutModalOpen} confirmCallback={() => { logout(); return true; }} confirmBtnVariant='danger' confirmBtnText='Abmelden'>
            Möchtest du dich wirklich abmelden?
        </ConfirmModal>
        <div className='navbar-spacer' />
        </>
    );
}

const SettingsLink = ({ loginToken, children, asNavLink = false }) => {
    if(!api.isLoggedIn())
        return <></>

    const userId = loginToken.userData.id;
    const Elem = asNavLink ? ({children, ...props}) => <Nav.Link as={Link} active {...props}>{children}</Nav.Link> : ({ children, ...props }) => <Link {...props}>{children}</Link>
    
    switch(loginToken.userType) {
        case 'other': return <Elem to={{pathname: '/viewOther', state: {id: userId}}}>{children}</Elem>;
        case 'student': return <></>; // No setting button
        case 'teacher': return <Elem to={{pathname: '/viewTeacher', state: {id: userId}}}>{children}</Elem>;
        case 'trainer': return <Elem to={{pathname: '/viewTrainer', state: {id: userId}}}>{children}</Elem>;
        default: return children;

    }
}

function getDisplayNameFromToken(loginToken){
    if(!api.isLoggedIn())
        return ""

    switch(loginToken.userType) {
        case 'student': return loginToken.userData.prename + " " + loginToken.userData.name;
        case 'teacher':
        case 'trainer': return loginToken.userData.title + " " + loginToken.userData.name;
        case 'other'  : return loginToken.userData.name;
        default       : return "ERROR";
    }
}