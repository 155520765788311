import { Badge, Col, Row } from "react-bootstrap";
import utils from "../../service/utils";
import FormRow from "../bt/FormRow";
import EvalSectionDisplay from "./EvalSectionDisplay";

export default function EvaluationDisplay({ title, targetGroup, periodStart, periodEnd, data, countAnswers, countParticipants }) {
    const periodDeclared = periodStart !== '' && periodEnd !== '';

    return (
    <>
        <FormRow label="Titel" plaintext>
            <Row>
                <Col sm="8">
                    {title}
                </Col>
                <Col className="text-end">
                    <Badge className="me-1">{countParticipants} Teilnahmen</Badge>
                    <Badge bg="info">{countAnswers} Antworten</Badge>
                </Col>

            </Row>
            
        </FormRow>
        <FormRow label="Zielgruppe" plaintext>
            {targetGroup === "student" ? "Schüler" : "Kursleiter"}
        </FormRow>
        <FormRow label="Zeitraum" plaintext>
            {periodDeclared ? (
                <>{utils.sqlDateTimeToGermanDate(periodStart)}&nbsp;&nbsp; bis &nbsp;&nbsp;{utils.sqlDateTimeToGermanDate(periodEnd)}</>
            ) : (
                <div className="text-muted">--- Kein Zeitraum festgelegt ---</div>
            )}    
        </FormRow>

        {data.map((section, i) => (
            <EvalSectionDisplay key={i} title={section.title} questions={section.questions} />
        ))}
        {data.length === 0 ?  <p className="text-muted text-center">--- Kein Abschnitt in der Evaluation ---</p> : <></>}
    </>
    )
}