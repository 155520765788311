import utils from "./utils";

export const FLAGS_BY_TYPE = {
    weekly: [ 'VOLUNTARY', 'A-WEEK-ONLY', 'B-WEEK-ONLY' ],
    once:   [ 'VOLUNTARY' ]
}

export const FLAG_DISPLAY_NAMES = {
    'A-WEEK-ONLY': 'nur A-Woche',
    'B-WEEK-ONLY': 'nur B-Woche',
    'VOLUNTARY'  : 'freiwillig'
}

export const DEFAULT_PROPS_BY_TYPE = {
    common: {
        startTime: '00:00',
        endTime:   '00:00',
        title:     'Kurstag'
    },
    weekly: {
        dow: 0
    },
    once: {
        date: '1970-01-01'
    }
}

export default class Scheduler {

    constructor(schedule = []) {
        if(schedule instanceof Array) {
            this.schedule = [...schedule];
        } else {
            this.schedule = JSON.parse(schedule);
        }
        this.verifySchedule();
    }

    verifySchedule() {
        for(let i in this.schedule) {
            if(!("uid" in this.schedule[i]))
                this.schedule[i].uid = utils.uid(i);
            
            if(!("type" in this.schedule[i]))
                this.setType(i, 'weekly');
            
            this.schedule[i] = { ...DEFAULT_PROPS_BY_TYPE.common, ...DEFAULT_PROPS_BY_TYPE[this.schedule[i].type], ...this.schedule[i] };
        }
    }

    numEntries() {
        return this.schedule.length;
    }

    addEmptyEntry() {
        this.schedule.push({ ...DEFAULT_PROPS_BY_TYPE.common, uid: utils.uid() });

        return this.setType(this.schedule.length - 1, 'weekly');
    }

    setType(index, type) {
        this.schedule[index] = {
            type: type,
            flags: [],

            ...this.copyCommonProps(index),

            ...DEFAULT_PROPS_BY_TYPE[type]
        }
        return this.schedule;
    }

    copyCommonProps(index) {
        let stm = {};
        Object.keys(DEFAULT_PROPS_BY_TYPE.common).forEach(prop => stm[prop] = this.schedule[index][prop]);
        return stm;
    }

    setProp(index, key, val) {
        if(key === 'type')
            return this.setType(index, val);
        
        this.schedule[index][key] = val;
        return this.schedule;
    }

    setPropFun(index) {
        return (key, val) => this.setProp(index, key, val);
    }

    getProp(index, key) {
        return this.schedule[index][key];
    }

    getPropFun(index) {
        return key => this.getProp(index, key);
    }

    toggleFlag(index, flag) {
        this.schedule[index].flags = utils.toggleArrayItem(this.schedule[index].flags, flag);
        return this.schedule;
    }

    toggleFlagFun(index) {
        return flag => this.toggleFlag(index, flag);
    }

    hasFlag(index, flag) {
        return this.schedule[index].flags.includes(flag);
    }

    hasFlagFun(index) {
        return flag => this.hasFlag(index, flag);
    }

    deleteEntry(index) {
        this.schedule.splice(index, 1);
        return this.schedule;
    }

    deleteEntryFun(index) {
        return () => this.deleteEntry(index);
    }

    toDisplayText() {
        if(this.numEntries() === 0)
            return "keine Zeiten";
        
        if(this.numEntries() === 1)
            return this.entryToDisplayText(0);
        
        return <ul>{this.schedule.map((e, i) => <li key={i}><strong>{e.title}</strong> {this.entryToDisplayText(i)}</li>)}</ul>;
    }

    entryToDisplayText(index) {
        const entry = this.schedule[index];
        let stm = "";

        switch(entry.type) {
            case 'weekly': stm = `wöchentlich: ${utils.dowNumToAbbr(entry.dow)} ${entry.startTime} - ${entry.endTime}`; break;
            case 'once'  : stm = `${utils.sqlDateToGermanDate(entry.date)}: ${entry.startTime} - ${entry.endTime}`; break;
            default:
        }

        if(entry.flags.length > 0)
            stm += ` (${utils.germanImplode(entry.flags.map(f => FLAG_DISPLAY_NAMES[f]))})`;

        return stm;
    }

    getVoluntaryEntries() {
        return this.schedule.filter(e => e.flags.includes('VOLUNTARY'));
    }
}