import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import OtherDisplay from "../../components/forms/dataDisplays/OtherDisplay";
import OtherSelect from "../../components/forms/selects/OtherSelect";
import OtherEdit from "../../components/forms/dataEdits/OtherEdit";
import useFetchData from "../../hooks/useFetchData";
import usePageState from "../../hooks/usePageState";
import api from "../../service/api";
import useTrigger from "../../hooks/useTrigger";
import DisplayEditToggleCard from "../../components/forms/DisplayEditToggleCard";
import usePushNotificator from "../../hooks/usePushNotificator";
import DeleteButton from "../../components/DeleteButton";

export default function ViewOther(props) {
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const others = useFetchData('others', {}, [saveListener]);
    
    const [ name, setName ] = useState('');
    const [ pwd, setPwd ]   = useState('');
    const [ pwd2, setPwd2 ] = useState('');

    const [ pushNotification, Notificator ] = usePushNotificator();

    const data = others.filter(e => e.id === pageState.id)[0];

    useEffect(() => {
        if(data !== undefined) {
            setName(data.name);
            
            setPwd('');
            setPwd2('');
        }

    }, [pageState, others, data])

    const saveEdits = async () => {
        let body = {name: name};

        if(pwd !== '') {
            if(pwd !== pwd2) {
                pushNotification('Ungültige Eingabe', 'Die Passwörter müssen übereinstimmen!', 'warning');
                return false;
            }
            body.pwd = pwd;
        }

        const res = await api.put('others/' + pageState.id, body);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Der Benutzer wurde erfolgreich bearbeitet.', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
        
    }

    return (
        <>
        <PageStateReloader />
        <Card body className="mb-3">
            <Card.Title>Benutzer auswählen</Card.Title>
            <OtherSelect value={pageState.id} setValue={v => setPageState({id: v})} dependencies={[saveListener]}/>
        </Card>
        <Notificator />
        {data === undefined ? <></> : (
            <>
            <DisplayEditToggleCard suppressButtonSaveBtn cardTitle={`Benutzer ${data.name}`} displayComponent={OtherDisplay} editComponent={OtherEdit} saveFun={saveEdits} name={name} setName={setName} pwd={pwd} setPwd={setPwd} pwd2={pwd2} setPwd2={setPwd2} />
            <Card body className="text-end">
                <DeleteButton userType="other" userId={pageState.id} btnText="Benutzer löschen" onDeleted={() => { setPageState({id: ''}); saveTrigger(); }}/>
            </Card>
            </>
            )}
        </>
    )
}