import { Form } from "react-bootstrap";
import useFetchData from "../../../hooks/useFetchData"
import FloatingSelect from '../../bt/FloatingSelect'

export default function CoursesSelect({ defaultFirst, floating, value, setValue, dependencies, ...rest }) {
    
    const courses = useFetchData('courses', {}, dependencies, first => {if(defaultFirst) setValue(first.id)})
    const SelectInUse = floating ? FloatingSelect : Form.Select;

    return (
        <SelectInUse value={value} onChange={e => setValue(e.target.value)} {...rest}>
            {defaultFirst ? <></> : <option disabled="disabled" value=''>Kurs auswählen</option>}
            {courses.map(c => <option value={c.id} key={c.id}>{c.title} ({c.trainer_title} {c.trainer_name})</option>)}
        </SelectInUse>
    )
}

CoursesSelect.defaultProps = {
    defaultFirst: false,
    floating: false,
    dependencies: []
}