import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomPdfTemplateEdit from "../../../components/forms/dataEdits/CustomPdfTemplateEdit";
import usePushNotificator from "../../../hooks/usePushNotificator";
import api from "../../../service/api";

export default function NewCustomPdfTemplate() {
    const [ title, setTitle ] = useState('')
    const [ targetGroup, setTargetGroup ] = useState('student')
    const [ text, setText ] = useState('')
    const [ variables, setVariables ] = useState([])

    const [ pushNotification, Notificator ] = usePushNotificator()

    const handleSubmit = async e => {
        e.preventDefault();
        const res = await api.post('pdfs/custom', { title: title, targetGroup: targetGroup, text: text, variables: JSON.stringify(variables) });

        if(res.status === 'ok') {
            setTitle('');
            setTargetGroup('student');
            setText('');
            setVariables([]);

            pushNotification('Bearbeitung erfolgreich', 'Evaluation erfolgreich erstellt', 'sucess');
        } else {
            pushNotification('Interner Fehler', res.error, 'danger');
        }
            
    }

    return (
        <>
        <Notificator />
        <Card body>
            <Card.Title>Neue Vorlage erstellen</Card.Title>
            <hr />
            <Form onSubmit={handleSubmit}>
                <CustomPdfTemplateEdit
                    title={title} setTitle={setTitle}
                    targetGroup={targetGroup} setTargetGroup={setTargetGroup}
                    text={text} setText={setText}
                    variables={variables} setVariables={setVariables}
                />
                <div className="text-end">
                    <Link to="/generatePdf"><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button type="submit" variant="success">Erstellen</Button>
                </div>
            </Form>
        </Card>
        </>
    );
}