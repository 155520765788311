import FormRow from '../../bt/FormRow'
import PasswordDisplayRow from './PasswordDisplayRow'

export default function OtherDisplay({ name }) {
    return (
        <>
            <FormRow label="Benutzername" plaintext>
                {name}
            </FormRow>
            <PasswordDisplayRow />
        </>
    )
}