import ReactMarkdown from 'react-markdown';

export default function MarkdownRenderer({ children, discardParagraphs }) {
    return <ReactMarkdown
        components={{
            h1: 'h4', h2: 'h5', h3: 'h6', h4: 'h6', h5: 'h6', h6: 'h6',
            p: discardParagraphs ? 'span' : 'p'
        }}
    >
        {children}
    </ReactMarkdown>
}

MarkdownRenderer.defaultPops = {
    discardParagraphs: false
}