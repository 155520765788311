import { Card } from "react-bootstrap";
import StudentList from "../../../components/StudentList";
import useFetchData from "../../../hooks/useFetchData";

export default function CompleteStudentList() {
    const students = useFetchData('students', {orderByClass: ''});

    return (
        <Card body>
            <Card.Title>Vollständige Schülerliste</Card.Title>
            <hr />
            <StudentList students={students} studentLinks courseLinks searchable paginated/>
        </Card>
    )
}