import { Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Unauthorized() {
    return (
        <Row className="justify-content-center">
            <div style={{ width: '40rem', marginTop: '40px' }} className="text-center">
                <hr />
                <h1>Du hast nicht die Rechte, diese Seite zu betreten!</h1> 
                <hr />
                <br /><br />
                <Link to='/'><Button variant="outline-secondary">Zurück</Button></Link>
            </div>
        </Row>
    );
}