import { useEffect, useState } from "react";
import api from "../service/api";

export default function useFetchData(apiEndpoint, params, dependencies = [], defaultSetter = false, errorHandler = null) {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = apiEndpoint.includes('//') || apiEndpoint.includes('undefined') ? [] : await api.get(apiEndpoint, params);

                if(defaultSetter !== false)
                    defaultSetter(res[0]);
                
                if('error' in res) {
                    if(errorHandler === null) {
                        console.error("API Error: " + res.error);
                    } else {
                        errorHandler(res)
                    }
                } else {
                    setData(res);
                }
            } catch(e) {
                console.error("Fetch Data error. Check api endpoint " + apiEndpoint + "! (" + e.message + ")");
            }
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return data;
}