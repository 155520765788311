import { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import api from "../../service/api";
import Scheduler from "../../service/Scheduler";
import CourseEdit from "../../components/forms/dataEdits/CourseEdit";
import { Link } from "react-router-dom";
import usePushNotificator from "../../hooks/usePushNotificator";

export default function NewCourse() {

    const [ title, setTitle ]             = useState('');
    const [ trainerId, setTrainerId ]     = useState('');
    const [ sector, setSector ]           = useState('');
    const [ place, setPlace ]             = useState('');
    const [ classLevels, setClassLevels ] = useState('');
    const [ size, setSize ]               = useState(0);
    const [ description, setDescription ] = useState('');
    const [ schedule, setSchedule ]       = useState(new Scheduler().addEmptyEntry());

    const [ pushNotification, Notificator ] = usePushNotificator();

    const handleSubmit = async e => {
        e.preventDefault();
        if(classLevels === '') {
            pushNotification('Ungültige Eingabe', 'Bitte wählen Sie Klassenstufen aus, die an dem Kurs teilnehmen können', 'warning');
        } else if(size <= 0) {
            pushNotification('Ungültige Eingabe', 'Bitte setzen Sie die Kursgröße auf eine Zahl größer 0', 'warning');
        } else {
            const res = await api.post('courses', {
                title: title,
                trainerId: trainerId,
                sector: sector,
                place: place,
                classLevels: classLevels,
                size: size,
                description: description,
                schedule: JSON.stringify(schedule)
            });

            if(res.status === 'ok') {
                setTitle('');
                setTrainerId('');
                setSector('');
                setPlace('');
                setClassLevels('');
                setSize(0);
                setDescription('');
                setSchedule(new Scheduler().addEmptyEntry());
        
                pushNotification('Bearbeitung erfolgreich', 'Kurs erfolgreich erstellt', 'success');
            } else {
                pushNotification('Interner Fehler', res.error, 'danger');
            }
            
        }

        
    }

    return (
        <>
        <Notificator />
        <Card body>
            <Card.Title>Neuen Kurs erstellen</Card.Title>
            <hr />
            <Form onSubmit={handleSubmit}>
                <CourseEdit
                    title={title} setTitle={setTitle}
                    trainerId={trainerId} setTrainerId={setTrainerId}
                    sector={sector} setSector={setSector}
                    place={place} setPlace={setPlace}
                    classLevels={classLevels} setClassLevels={setClassLevels}
                    size={size} setSize={setSize}
                    description={description} setDescription={setDescription}
                    schedule={schedule} setSchedule={setSchedule}
                />
                <div className="text-end">
                    <Link to="/"><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button type="submit" variant="success">Erstellen</Button>
                </div>
            </Form>
        </Card>
        </>
    );
}