import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Pagination, Row, Table } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import useFetchData from "../hooks/useFetchData";
import AttendanceRatioBar from "./attendance/AttendanceRatioBar";
import DisplayCoursesInSentence from "./forms/dataDisplays/DisplayCoursesInSentence";

export default function StudentList({ students, studentLinks, courseLinks, courseLinkPath, hideClass, attendanceRatioBars, searchable, paginated, maxPageEntries, maxVisiblePaginationButtonDistance, addButtons, onClickStudentButton }) {
    const allCourses = useFetchData('courses');

    const [ nameFilter, setNameFilter ] = useState("");
    const [ classFilter, setClassFilter ] = useState("");

    const [ currentPage, setCurrentPage ] = useState(0);

    const filteredStudents = useMemo(() => {
        if(searchable) {
            const splitNameFilter = nameFilter.split(" ").filter(s => s !== "").map(s => s.toUpperCase())
            return students.filter(s => (classFilter === "" || s.class.includes(classFilter)) && splitNameFilter.every(filter => (s.prename + " " + s.name).toUpperCase().includes(filter)));
        } else {
            return students;
        }
    }, [ searchable, students, nameFilter, classFilter ])

    useEffect(() => setCurrentPage(0), [ nameFilter, classFilter, students ])

    const maxPages = Math.ceil(filteredStudents.length / maxPageEntries);
    const visibleStudents = useMemo(() => paginated ? filteredStudents.slice(currentPage * maxPageEntries, (currentPage + 1) * maxPageEntries) : filteredStudents, [ filteredStudents, currentPage, maxPageEntries, paginated ])
    const smallestPaginationIndex = Math.min(Math.max(currentPage - maxVisiblePaginationButtonDistance, 0), Math.max(0, maxPages - 2*maxVisiblePaginationButtonDistance - 1))
    const largestPaginationIndex = Math.min(smallestPaginationIndex + 2*maxVisiblePaginationButtonDistance + 1, maxPages)

    
    return (
        <Table striped>
            <thead>
                <tr>
                    {addButtons && <th style={{width: "55px"}} />}
                    <th style={searchable ? {width: hideClass ? "50%" : "40%"} : {}}>Name</th>
                    {hideClass ? <></> : <th style={searchable ? {width: "20%"} : {}}>Klasse</th>}
                    <th>Kurs(e)</th>
                </tr>
                { searchable && (
                    <tr>
                        {addButtons && <th />}
                        <th><Form.Control size="sm" placeholder="Nach Name filtern" value={nameFilter} onChange={e => setNameFilter(e.target.value)}/></th>
                        {!hideClass && <th><Form.Control size="sm" placeholder="Nach Klasse filtern" value={classFilter} onChange={e => setClassFilter(e.target.value)}/></th>}
                        <th></th>
                    </tr>
                )}
            </thead>
            <tbody>
                {filteredStudents.length === 0 ? (
                    <tr>
                        <td colSpan={hideClass ? addButtons ? 3 : 2 : addButtons ? 4 : 3} className="text-center text-muted">
                            <i>{searchable ? "--- Kein Schüler gefunden. Ändere die Filter ---" : "--- Kein Schüler in der Datenbank ---"}</i>
                        </td>
                    </tr>
                ) : (<>
                    {visibleStudents.map(s => (
                        <tr key={s.id}>
                            {addButtons && <td><Button variant='success' className='me-2' size="sm" onClick={() => onClickStudentButton(s)}><PlusLg size={13} className="mb-1" /></Button></td>}
                            <td>{studentLinks ? <Link to={{pathname: '/viewStudent', state: {id: s.id}}}>{s.name}, {s.prename}</Link> : <>{s.name}, {s.prename}</>}</td>
                            {hideClass ? <></> : <td>{s.class}</td>}
                            <StudentListCourseCell courses={s.courses?.split(',').map(cid => allCourses.filter(c => c.id === cid)[0])} courseLinks={courseLinks} courseLinkPath={courseLinkPath} attendanceRatioBars={attendanceRatioBars} studentId={s.id}/>    
                        </tr>
                    ))}
                    {paginated && maxPages > 1 && (
                        <tr>
                            <td colSpan={hideClass ? addButtons ? 3 : 2 : addButtons ? 4 : 3} >
                                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                    <Pagination>
                                        <Pagination.First onClick={() => setCurrentPage(0)}/>
                                        {smallestPaginationIndex > 0 && <Pagination.Ellipsis disabled />}
                                        {[...Array(largestPaginationIndex - smallestPaginationIndex).keys()].map(i => (
                                            <Pagination.Item key={i+smallestPaginationIndex} onClick={() => setCurrentPage(i+smallestPaginationIndex)} active={currentPage === i+smallestPaginationIndex}>{i+1+smallestPaginationIndex}</Pagination.Item>
                                        ))}
                                        {largestPaginationIndex < maxPages - 1 && <Pagination.Ellipsis disabled />}
                                        <Pagination.Last onClick={() => setCurrentPage(maxPages - 1)}/>
                                    </Pagination>
                                </div>
                            </td>
                        </tr>
                    )}
                </>)
                }
            </tbody>
        </Table>
    )
}

StudentList.defaultProps = {
    studentLinks: false,
    courseLinks: false,
    hideClass: false,
    attendanceRatioBars: false,
    courseLinkPath: '/viewCourse',
    searchable: false,
    paginated: false,
    maxPageEntries: 50,
    maxVisiblePaginationButtonDistance: 2,
    addButtons: false,
}

function StudentListCourseCell({ courses, courseLinks, courseLinkPath, attendanceRatioBars, studentId }) {
    if(!attendanceRatioBars || courses === undefined || courses.length === 0) {
        return (
            <td>
                <DisplayCoursesInSentence courses={courses} links={courseLinks} linkPath={courseLinkPath} />
            </td>
        );
    } else {
        return (
            <td>
                {courses.map((c, i) => c === undefined ? <Fragment key={i}></Fragment> : (
                    <Row key={c.id} className="w-100">
                        <Col sm="8">
                            {courseLinks ? <Link to={{pathname: courseLinkPath, state: {id: c.id}}}>{c.title}</Link> : c.title}
                        </Col>
                        <Col>
                            <AttendanceRatioBar id={studentId} courseId={c.id} />
                        </Col>
                    </Row>
                ))}
            </td>
        );
    }
}