import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function SectorSelect({ value, setValue }) {
    const [ showTextInput, setShowTextInput ] = useState(false);
    const sectors = [ 'Entdecken', 'Gestalten', 'Bewegen', 'Lernen' ];

    const changeSelect = (e) => {
        if(e.target.value === 'new') {
            setShowTextInput(true);
            setValue('');
        } else {
            setShowTextInput(false);
            setValue(e.target.value);
        }
    }

    if(value !== '' && !showTextInput && !sectors.includes(value))
        setShowTextInput(true);

    const select = (
        <Form.Select value={showTextInput ? 'new' : value} onChange={changeSelect} required>
            <option disabled="disabled" value=''>Bereich auswählen</option>
            {sectors.map(s => <option key={s} value={s}>{s}</option>)}
            <option value='new' style={{fontStyle: 'italic'}}>anderer Bereich</option>
        </Form.Select>
    );
    
    if(showTextInput) {
        return (
            <Row>
                <Col sm={4}>
                    {select}
                </Col>
                <Col>
                    <Form.Control type="text" placeholder="zb. Spielen" value={value} onChange={e => setValue(e.target.value)} required/>
                </Col>
            </Row>
        )
    } else {
        return select;
    }
}