import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import Scheduler, { FLAGS_BY_TYPE, FLAG_DISPLAY_NAMES } from "../../service/Scheduler";
import FormRow from "../bt/FormRow";

export default function ScheduleEditor({schedule, setSchedule}) {

    const scheduler = new Scheduler(schedule);

    return (
        <Card>
            <ListGroup variant="flush">
                {scheduler.schedule.map((e, i) => <ListGroup.Item key={i}><ScheduleEditorEntry setSchedule={setSchedule} getProp={scheduler.getPropFun(i)} setProp={scheduler.setPropFun(i)} hasFlag={scheduler.hasFlagFun(i)} toggleFlag={scheduler.toggleFlagFun(i)} deleteEntry={scheduler.deleteEntryFun(i)} singleEntry={scheduler.numEntries() > 1}/></ListGroup.Item>)}
                <ListGroup.Item className="text-end">
                    <Button variant="success" size="sm" onClick={() => setSchedule(scheduler.addEmptyEntry())}>Neuer Termin</Button>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
}

function ScheduleEditorEntry({ setSchedule, getProp, setProp, hasFlag, toggleFlag, deleteEntry, singleEntry }) {
    let typeSpezificInput = <div>Interner fehler!</div>;
    switch(getProp('type')) {
        case 'weekly': typeSpezificInput = (
                <FormRow label="Wochentag" small>
                    <Form.Select onChange={e => setSchedule(setProp('dow', Number(e.target.value)))} value={getProp('dow')} size="sm">
                        <option value='0'>Montag</option>
                        <option value='1'>Dienstag</option>
                        <option value='2'>Mittwoch</option>
                        <option value='3'>Donnerstag</option>
                        <option value='4'>Freitag</option>
                        <option value='5'>Samstag</option>
                        <option value='6'>Sonntag</option>
                    </Form.Select>
                </FormRow>
            );
            break;

        case 'once': typeSpezificInput = (
                <FormRow label="Datum" small>
                    <Form.Control type="date" size="sm" placeholder="zb. 27.02.2021" onChange={e => setSchedule(setProp('date', e.target.value))} value={getProp('date')}/>
                </FormRow>
            ); 
            break;

        default:
    }

    const flagInput = FLAGS_BY_TYPE[getProp('type')].length === 0 ? <></> : (
        <FormRow label="" small>
            <Row >
                {FLAGS_BY_TYPE[getProp('type')].map(flag => (
                    <Col key={flag} >
                        <Form.Check type="checkbox" size="sm" id={flag}>
                            <Form.Check.Input type="checkbox" className="me-1" defaultChecked={hasFlag(flag)} onChange={() => setSchedule(toggleFlag(flag))}/>
                            <Form.Check.Label><small>{FLAG_DISPLAY_NAMES[flag]}</small></Form.Check.Label>
                        </Form.Check>
                    </Col>
                ))}
            </Row>
        </FormRow>
    );

    return (
        <>
            <FormRow label="Termintyp" small>
                <Form.Select onChange={e => setSchedule(setProp('type', e.target.value))} value={getProp('type')} size="sm">
                    <option value="weekly">wöchentlich</option>
                    <option value="once">einmalig</option>
                </Form.Select>
            </FormRow>
            {singleEntry ? (
                <FormRow label="Terminname" small>
                    <Form.Control size="sm" onChange={e => setSchedule(setProp('title', e.target.value))} value={getProp('title')}/>
                </FormRow>
            ) : <></>}
            <FormRow label="Anfangszeit" small>
                <Form.Control size="sm" type="time" onChange={e => setSchedule(setProp('startTime', e.target.value))} value={getProp('startTime')}/>
            </FormRow>
            <FormRow label="Endzeit" small>
                <Form.Control size="sm" type="time" onChange={e => setSchedule(setProp('endTime', e.target.value))} value={getProp('endTime')}/>
            </FormRow>
            {typeSpezificInput}
            {flagInput}
            {singleEntry ? (
                <div className="text-end">
                    <Button variant="danger" size="sm" onClick={() => setSchedule(deleteEntry())}>Termin Löschen</Button>
                </div>
            ) : <></> }
        </>
    );
}