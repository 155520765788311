import { Redirect, Route } from "react-router-dom";
import api from "../service/api";

export default function ProtectedRoute({component: Component, userTypes, ...rest}) {
    return (
        <Route 
            {...rest}
            render = {
                props => {
                    if(api.isLoggedIn() && userTypes.includes(api.getUserType())) {
                        return <Component {...rest} {...props} />;
                    } else {
                        return <Redirect to={{
                            pathname: '/unauthorized',
                            state: {
                                from: props.location
                            }
                        }} />
                    } 
                }
            }
        />
    );
}