import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from "react-bootstrap-icons";

export default function NewYearInstructions() {
    return (
        <>
        <h1>Anleitung zum Schuljahreswechsel</h1>
        <p>Das neue Schuljahr steht an. Was ist nun zu tun, um alles für den GTA-Betrieb vorzubereiten? Hier finden Sie eine einfache Checkliste aller notwendigen Schritte. <strong>Halten Sie unbedingt die vorgeschlagene Reihenfolge ein!</strong></p>
        <Row>
            <NewYearInstructionsCard num="1" title="Anwesenheiten exportieren" linkPath="/generatePdf" linkState={{id: 'trainerAttendances'}}>
                Der erste Schritt bei einer größeren Datenoperation ist es immer, die bestehenden Daten zu sichern, um sie im Zweifelsfall rekonstruieren zu können. Außerdem müssen schließlich alle Kursleiter-Anwesenheitsdaten über 5 Jahre hinweg archiviert werden. <br />
                <strong>Wichtig:</strong> Löschen und Bearbeiten Sie vor dem Schritt <strong>keine</strong> Kurse. Danach kann angefangen werden, das Kursangebot zu aktualisieren.
            </NewYearInstructionsCard>
            <NewYearInstructionsCard num="2" title="Schuljahr bearbeiten" linkPath="/editYear">
                Nun müssen die Daten des neuen Schuljahres ins System eingespeist werden (Anfang und Ende des Schuljahres, Ferien, Zeitraum der Kurswahl etc.). <br />
                <strong>Wichtig:</strong> Achten Sie darauf, dass alle Daten, besonders A-& B-Wochen, zum Beginn des Kursbetriebs korrekt sind und möglichst innerhalb des Schuljahres nicht geändert werden müssen. 
            </NewYearInstructionsCard>
            <NewYearInstructionsCard num="3" title="Schuljahreswechsel für Schüler*innen beaufsichtigen" linkPath="/studentForwarding">
                Die Schüler müssen jetzt in die richtigen neuen Klassen eingepflegt werden. Dies kann manuell oder per Excel-Tabelle geschehen. Man kann anschließened eine Vorschau einsehen, wo sich welcher Schüler im nächsten Schuljahr befinden wird.
            </NewYearInstructionsCard>
            <NewYearInstructionsCard num="4" title="Kursbriefe generieren" linkPath="/generatePdf">
                Damit die Schüler auf das Kursangebot zugreifen können, brauchen Sie ihr Passwort. Daher ist es gut so früh wie möglich die Kursbriefe, auf denen die Passwörter sowie der Kurswahlzeitraum steht, auszuteilen. 
            </NewYearInstructionsCard>
            <NewYearInstructionsCard num="5" title="Kurswahl vorbereiten" linkPath="/courseSelectionManager">
                <strong>Vor der ersten Kurswahl</strong> im Schuljahr müssen die Kurse, die ein Schüler im letzten Jahr belegte, als sein / ihr Vorrecht markiert werden, sodass Schüler ohne Probleme Ihre Lieblingskurse weiterführen können. 
            </NewYearInstructionsCard>
            <NewYearInstructionsCard num="6" title="Kurswahl abschließen" linkPath="/courseSelectionManager">
                Nachdem alle Schüler die Möglichkeit hatten seine / ihre Kurswahl zu wählen also nach dem Ende der Kurswahl, muss die Kurswahl ausgeführt werden. Danach kann das Schuljahr voller GTA's los gehen. 
            </NewYearInstructionsCard>
        </Row>
        </>
    )
}

function NewYearInstructionsCard({ num, title, linkPath, linkState, children }) {
    return (
        <Col xs="12" lg="6">
            <Card body className="mb-2">
                <Row>
                    <Col sm="10">
                        <Card.Title>{num}. {title}</Card.Title>
                    </Col>
                    <Col className="text-end">
                        <Card.Title><Link to={{pathname: linkPath, state: linkState}}><BoxArrowUpRight className="text-muted"/></Link></Card.Title>
                    </Col>
                </Row>
                {children}
            </Card>
        </Col>
    )
}

NewYearInstructionsCard.defaultProps = {
    linkState: {}
}