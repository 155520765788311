import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import utils from "../../service/utils";
import FormRow from "../bt/FormRow";
import EvalSectionEdit from "./EvalSectionEdit";

export default function EvaluationEdit({ title, setTitle, targetGroup, setTargetGroup, periodStart, setPeriodStart, periodEnd, setPeriodEnd, data, setData, shouldShowPeriodInput }) {
    const [ showPeriodInput, setShowPeriodInput ] = useState(false)

    useEffect(() => setShowPeriodInput(shouldShowPeriodInput), [ shouldShowPeriodInput ])
    
    const swapSections = (i, j) => {
        let dataCpy = [...data];
        dataCpy[i] = data[j]
        dataCpy[j] = data[i]
        setData(dataCpy)
    }

    const newSection = () => {
        setData([...data, {
            title: "Neuer Abschnitt",
            questions: []
        }])
    }

    const removePeriod = () => {
        setPeriodStart("")
        setPeriodEnd("")
        setShowPeriodInput(false)
    }

    return (
        <>
        <FormRow label="Titel">
            <Form.Control type="text" placeholder="Titel" value={title} onChange={e => setTitle(e.target.value)} required/>
        </FormRow>
        <FormRow label="Zielgruppe">
            <Form.Select value={targetGroup} onChange={e => setTargetGroup(e.target.value)}>
                <option value="student">Schüler</option>    
                <option value="trainer">Kursleiter</option>    
            </Form.Select>
        </FormRow>
        {showPeriodInput ? (
            <FormRow label="Zeitraum">
                <Row>
                    <Col sm="4">
                        <Form.Control type="datetime-local" value={periodStart} onChange={e => setPeriodStart(e.target.value)} step="1"/>
                    </Col>
                    <Col className="text-center" sm="1">
                        bis
                    </Col>
                    <Col sm="4">
                        <Form.Control type="datetime-local" value={periodEnd} onChange={e => setPeriodEnd(e.target.value)} step="1"/>
                    </Col>
                    <Col sm="3" className="text-end">
                        <Button variant="outline-danger" onClick={removePeriod}>Zeitraum entfernen</Button>
                    </Col>
                </Row>
                <b style={{color: 'red'}}>ACHTUNG:</b> Bearbeiten einer Evaluation während sie läuft oder bevor die Ergebnisse ausgewertet sind, kann große Probleme verursachen!
            </FormRow>
            ) : (
            <div className="text-end mb-3">
                <Button variant="outline-info" onClick={() => setShowPeriodInput(true)}>Zeitraum festlegen</Button>
            </div>
        )}
        
        {data.map((section, i) => (
            <EvalSectionEdit 
                key={i} 
                title={section.title} 
                questions={section.questions}
                setTitle={ t => setData(utils.replaceElementInArray(data, i, {...data[i], title: t})) }
                setQuestions={ qs => setData(utils.replaceElementInArray(data, i, {...data[i], questions: qs})) }
                moveUp={ i === 0 ? null : () => swapSections(i, i-1) }
                moveDown={ i === data.length - 1 ? null : () => swapSections(i, i+1) }
                remove={() => setData(utils.removeElementFromArray(data, i))}
            /> 
        ))}
        {data.length === 0 ?  <p className="text-muted text-center">--- Kein Abschnitt in der Evaluation ---</p> : <></>}
        <div className="text-end mb-3">
            <Button variant="success" onClick={newSection}>Neuer Abschnitt</Button>    
        </div>
        </>
    )
}