import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import TrainerEdit from "../../components/forms/dataEdits/TrainerEdit";
import usePushNotificator from "../../hooks/usePushNotificator";
import api from "../../service/api";

export default function NewTrainer() {
    const [ title, setTitle ] = useState('Frau');
    const [ name, setName ]   = useState('');
    const [ mail, setMail ]   = useState('');
    const [ pwd, setPwd ]     = useState('');
    const [ pwd2, setPwd2 ]   = useState('');

    const [ pushNotification, Notificator ] = usePushNotificator();

    const handleSubmit = async e => {
        e.preventDefault();
        if(pwd !== pwd2) {
            pushNotification('Ungültige Eingabe', 'Die Passwörter müssen übereinstimmen!', 'warning');
        } else {
            const res = await api.post('trainers', {title: title, name: name, mail: mail, pwd: pwd});

            if(res.status === 'ok') {
                setTitle('');
                setName('');
                setPwd('');
                setPwd2('');

                pushNotification('Bearbeitung erfolgreich', 'Benutzer erfolgreich erstellt', 'success');
            } else {
                pushNotification('Interner Fehler', res.error, 'danger');
            }
            
        }
    }

    return (
        <>
        <Notificator />
        <Card body>
            <Card.Title>Neuen Kursleiter erstellen</Card.Title>
            <hr />
            <Form onSubmit={handleSubmit}>
                <TrainerEdit title={title} setTitle={setTitle} name={name} setName={setName} mail={mail} setMail={setMail} pwd={pwd} setPwd={setPwd} pwd2={pwd2} setPwd2={setPwd2} mode='new' />
                <div className="text-end">
                    <Link to="/"><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button type="submit" variant="success">Erstellen</Button>
                </div>
            </Form>
        </Card>
        </>
    )
}