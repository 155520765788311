import { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import utils from "../service/utils";
import { createPortal } from 'react-dom';
import { CheckCircleFill, ExclamationCircleFill, ExclamationDiamondFill } from "react-bootstrap-icons";

const STANDARD_ICONS = {
    "success": <span className="text-success me-2"><CheckCircleFill size="16" className="mb-1"/></span>,
    "danger": <span className="text-danger me-2"><ExclamationCircleFill size="16" className="mb-1"/></span>,
    "warning": <span className="text-warning me-2"><ExclamationDiamondFill size="16" className="mb-1"/></span>,
}


export default function usePushNotificator() {
    const [ notifications, setNotifications ] = useState([]);

    const pushNotification = (title, message, icon = <></>) => {
        if(icon in STANDARD_ICONS)
            icon = STANDARD_ICONS[icon]
        setNotifications([{ title, message, icon }, ...notifications]);
    }

    const closeNotification = (i) => {
        setNotifications(utils.removeElementFromArray(notifications, i));
    }

    const Notificator = () => {
        return createPortal(
            <ToastContainer position="top-center" className="position-fixed" style={{paddingTop: "20px", zIndex: 1025}}>
                {notifications.map(({ title, message, icon }, i) => (
                    <Toast key={i} show onClose={() => closeNotification(i)}>
                        <Toast.Header>
                            {icon}
                            <strong className="me-auto">{title}</strong>
                        </Toast.Header>
                        <Toast.Body>
                            {message}
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        , document.body);
    }

    return [ pushNotification, Notificator ];
}