import { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import DeleteButton from "../../components/DeleteButton";
import TrainerDisplay from "../../components/forms/dataDisplays/TrainerDisplay";
import TrainerEdit from "../../components/forms/dataEdits/TrainerEdit";
import DisplayEditToggleCard from "../../components/forms/DisplayEditToggleCard";
import TrainerSelect from "../../components/forms/selects/TrainerSelect";
import useFetchData from "../../hooks/useFetchData";
import useLoginToken from "../../hooks/useLoginToken";
import usePageState from "../../hooks/usePageState";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";

export default function ViewTrainer(props) {
    const [{ userType }, ] = useLoginToken();
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const others = useFetchData('trainers', {}, [saveListener]);

    const [ title, setTitle ] = useState('');
    const [ name, setName ]   = useState('');
    const [ mail, setMail ]   = useState('');
    const [ pwd, setPwd ]     = useState('');
    const [ pwd2, setPwd2 ]   = useState('');

    const [ pushNotification, Notificator ] = usePushNotificator();

    const data = others.filter(e => e.id === pageState.id)[0];

    useEffect(() => {
        if(data !== undefined) {
            setTitle(data.title);
            setName(data.name);
            setMail(data.mail);
            
            setPwd('');
            setPwd2('');
        }

    }, [pageState, others, data]);

    const saveEdits = async () => {
        let body = {title: title, name: name, mail: mail};

        if(pwd !== '') {
            if(pwd !== pwd2) {
                pushNotification('Ungültige Eingabe', 'Die Passwörter müssen übereinstimmen!', 'warning');
                return false;
            }
            body.pwd = pwd;
        }

        const res = await api.put('trainers/' + pageState.id, body);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Der Kursleiter wurde erfolgreich bearbeitet', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
    }

    return (
        <>
        <PageStateReloader />
        { userType === 'other' ? (
            <Card body className="mb-3">
                <Card.Title>Kursleiter auswählen</Card.Title>
                <TrainerSelect value={pageState.id} setValue={v => setPageState({id: v})} dependencies={[saveListener]}/>
            </Card>
        ) : <></> }
        <Notificator />
        {data === undefined ? <></> : (
            <>
            <DisplayEditToggleCard suppressButtonSaveBtn cardTitle={`Kursleiter ${data.title} ${data.name}`} displayComponent={TrainerDisplay} editComponent={TrainerEdit} saveFun={saveEdits} id={pageState.id} title={title} setTitle={setTitle} name={name} setName={setName} mail={mail} setMail={setMail} pwd={pwd} setPwd={setPwd} pwd2={pwd2} setPwd2={setPwd2} />
            { userType === 'other' ? (
                <Card body className="text-end">
                    <DeleteButton userType="trainer" userId={pageState.id} btnText="Kursleiter löschen" onDeleted={() => { setPageState({id: ''}); saveTrigger(); }}/>
                </Card>
            ) : <></> }
            </>
        )}
        
        </>
    )

}