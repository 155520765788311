import { useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ConfirmModal from "../../components/bt/ConfirmModal";
import useFetchData from "../../hooks/useFetchData";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";
import utils from "../../service/utils";

export default function CourseSelectionManager() {
    const [ showConvertModal, setShowConvertModal ] = useState(false);
    const [ showExecuteModal, setShowExecuteModal ] = useState(false);
    const [ pushNotification, Notificator ] = usePushNotificator();

    const [ triggerReloadTrigger, reloadTrigger] = useTrigger();

    const courseSelectionStatus = useFetchData("courseSelection/status", {}, [ reloadTrigger ]);

    let now = new Date();
    now.setHours(0, 0, 0, 0);

    const convert = async () => {
        const res = await api.post('courseSelection/convertVisitingToPriorityCourses', {});
        triggerReloadTrigger()

        if(res.status === 'ok') {
            pushNotification('Kovertierung erfolgreich', 'Nun können die Schüler ihr vorrecht genießen!', 'success');
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
        
    }

    const execute = async () => {
        const res = await api.post('courseSelection/execute', {});
        triggerReloadTrigger()

        if(res.status === 'ok') {
            pushNotification('Abschließen erfolgreich', 'Alle Schüler wurden ihren Kursen zugeordnet!', 'success');
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
    }   

    if (Array.isArray(courseSelectionStatus)) return <></>;

    return (
        <>
        <h1>Kurswahlmanager</h1>
        <Notificator />
        <p>Der Zeitrahmen der Kurswahl kann bei <Link to={{pathname: "/editYear"}}>Bearbeitung des Schuljahrs</Link> angepasst werden.</p>
        <ConfirmModal title="Besuchte Kurse in Vorrechtskurse umtragen" show={showConvertModal} setShow={setShowConvertModal} confirmBtnText="Durchführen" confirmCallback = {() => {convert(); return true; }}>
            Bei dieser Operation werden alle Schüler aus ihren Kursen entfernt. Dafür erhalten sie bei der nächsten Kurswahl ein vorrecht auf diese. Wollen Sie diese Aktion wirklich durchführen?
        </ConfirmModal>
        <ConfirmModal title="Kurswahl abschließen" show={showExecuteModal} setShow={setShowExecuteModal} confirmBtnText="Ja, ich bin bereit" confirmCallback = {() => {execute(); return true; }}>
            Mit dieser Akion werden alle Schüler zufällig und möglichst fair den Kursen zugeordnet. Außerdem werden die Daten, welche Kurse angewählt wurden gelöscht. Sind Sie bereit für dies?
        </ConfirmModal>
        <Card className="mb-3">
            <Card.Header>Statusinformationen</Card.Header>
            <Card.Body>
            <div className="float-left">
                Zeitraum: {utils.sqlDateToGermanDate(courseSelectionStatus.start)} bis {utils.sqlDateToGermanDate(courseSelectionStatus.end)}
            </div>
            <div className="text-end">
                {now < new Date(courseSelectionStatus.start) ? (
                <Badge bg="secondary">liegt in Zukunft</Badge>
                ) : now > new Date(courseSelectionStatus.end) ? (
                <Badge bg="success">vorbei</Badge>
                ) : (
                <Badge bg="warning">läft gerade</Badge>
                )}
            </div>
            <div className="float-left">
                Teilnahmen: {courseSelectionStatus.numParticipants} von {courseSelectionStatus.totalStudents}
            </div>
            <div className="text-end">
                <Badge>{(courseSelectionStatus.numParticipants / courseSelectionStatus.totalStudents * 100).toFixed(1)}%</Badge>
            </div>
            </Card.Body>
        </Card>
        <Card body className="text-end">
            <Button variant="warning" disabled={now > new Date(courseSelectionStatus.end)} className="me-2" onClick={() => setShowConvertModal(true)}>Vorrecht auf besuchte Kurse gewären</Button>
            <Button variant="warning" disabled={now <= new Date(courseSelectionStatus.end)} onClick={() => setShowExecuteModal(true)}>Abschließen</Button>
        </Card>
        </>
    );
}
