import { useEffect, useState } from "react";
import { Card, Form, Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ConfirmModal from "../../../components/bt/ConfirmModal";
import CustomPdfTemplateEdit from "../../../components/forms/dataEdits/CustomPdfTemplateEdit";
import DisplayEditToggleCard from "../../../components/forms/DisplayEditToggleCard";
import useFetchData from "../../../hooks/useFetchData";
import usePageState from "../../../hooks/usePageState";
import usePushNotificator from "../../../hooks/usePushNotificator";
import useTrigger from "../../../hooks/useTrigger";
import api from "../../../service/api";
import CustomPdfTemplate from "./CustomPdfTemplate";
import EvaluationAnalyzer from "./EvaluationAnalyzer";

const download = require("downloadjs");

export default function GeneratePdf(props) {
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ pdfProperties, setPdfProperties ] = useState({})
    const [ pushNotification, Notificator ] = usePushNotificator();
    const [ saveTrigger, saveListener ] = useTrigger();

    const customTemplates = useFetchData('pdfs/custom', {}, [saveListener]);
    const internalTemplates = [
        { id: 'trainerAttendances', title: 'Kursleiter-Anwesenheiten exportieren'},
        { id: 'analyzeEvaluation', title: 'Evaluationen auswerten'}
    ];

    const generate = () => {
        setShowWaitForDownloadModal(true);
        const path = isNaN(pageState.id) ? pageState.id : 'custom/generate/' + pageState.id;
        api.send("pdfs/" + path, 'GET', pdfProperties, "application/pdf", false)
            .then(res => res.blob())
            .then(blob => {
                setShowWaitForDownloadModal(false);
                download(blob)
            });
    }

    // custom template stuff
    const crntCustomTemplate = customTemplates.filter(t => t.id === pageState.id)[0];

    const [ customTemplateTitle, setCustomTemplateTitle ] = useState('')
    const [ customTemplateTargetGroup, setCustomTemplateTargetGroup ] = useState('')
    const [ customTemplateText, setCustomTemplateText ] = useState('')
    const [ customTemplateVariables, setCustomTemplateVariables ] = useState([])

    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ showWaitForDownloadModal, setShowWaitForDownloadModal ] = useState(false);

    useEffect(() => {
        if(crntCustomTemplate !== undefined) {
            setCustomTemplateTitle(crntCustomTemplate.title)
            setCustomTemplateTargetGroup(crntCustomTemplate.target_group)
            setCustomTemplateText(crntCustomTemplate.text)
            setCustomTemplateVariables(JSON.parse(crntCustomTemplate.variables))
        }

    }, [pageState, crntCustomTemplate ])

    const saveCustomTemplateEdits = async () => {
        const res = await api.put('pdfs/custom/' + pageState.id, { title: customTemplateTitle, targetGroup: customTemplateTargetGroup, text: customTemplateText, variables: JSON.stringify(customTemplateVariables) });

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'DIe PDF-Vorlage wurde erfolgreich bearbeitet', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
    }

    const deleteCustomTemplate = async () => {
        const res = await api.delete(`pdfs/custom/${pageState.id}`);

        if(res.status === 'ok') {
            setPageState({id: ''});
            saveTrigger();
        }
    }

    return (
        <>
        <PageStateReloader />
        <ConfirmModal title="Vorlage löschen" confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={deleteCustomTemplate} cancelBtnText="Abbrechen" show={showDeleteModal} setShow={setShowDeleteModal}>
            Möchten Sie die PDF-Vorlage wirklich löschen?
        </ConfirmModal>
        <Modal show={showWaitForDownloadModal} onHide={() => setShowWaitForDownloadModal(false)} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Warte auf Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Gerade beim Erzeugen großer PDFs, kann es zu einer leichten Verzögerung im Betriebsablauf kommen. Ich bitte um Geduld.
                <div style={{margin: 10, alignItems: "center", display: "flex"}}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <span style={{marginLeft: 10}}>Generiere PDF...</span>
                </div>
            </Modal.Body>
        </Modal>
        <Card body className="mb-3">
            <Card.Title>PDF-Vorlage auswählen</Card.Title>
            <Form.Select value={pageState.id} onChange={e => setPageState({...pageState, id: e.target.value})} className="mb-3">
                <option disabled="disabled" value=''>Vorlage auswählen</option>
                <optgroup label="Interne Vorlagen">
                    {internalTemplates.map(t => (
                        <option key={t.id} value={t.id}>{t.title}</option>
                    ))}
                </optgroup>
                <optgroup label="Eigene Vorlagen">
                    {customTemplates.map(t => (
                        <option key={t.id} value={t.id}>{t.title}</option>
                    ))}
                </optgroup>
            </Form.Select>
            <div className="text-end">
                <Link to='/newCustomPdfTemplate'><Button variant="success">Neue Vorlage erstellen</Button></Link>
            </div>
        </Card>
        <Notificator />
        {pageState.id !== '' ? (<>
            {pageState.id === 'analyzeEvaluation' ? (
                <EvaluationAnalyzer 
                    properties={pdfProperties} setProperties={setPdfProperties}
                    pageState={pageState} setPageState={setPageState}
                />
            ) : <></>}
            {crntCustomTemplate === undefined ? <></> : (
                <DisplayEditToggleCard
                    cardTitle={crntCustomTemplate.title}
                    displayComponent={CustomPdfTemplate}
                    editComponent={CustomPdfTemplateEdit}
                    saveFun={saveCustomTemplateEdits}

                    title={customTemplateTitle} setTitle={setCustomTemplateTitle}
                    targetGroup={customTemplateTargetGroup} setTargetGroup={setCustomTemplateTargetGroup}
                    text={customTemplateText} setText={setCustomTemplateText}
                    variables={customTemplateVariables} setVariables={setCustomTemplateVariables}

                    variableValues={pdfProperties} setVariableValues={setPdfProperties}
                />
                
            )}
            <Card body className="text-end">
                {crntCustomTemplate === undefined ? <></> : (
                    <Button variant="danger" className="me-3" onClick={() => setShowDeleteModal(true)}>Vorlage Löschen</Button>
                ) }
                <Button variant="success" onClick={generate}>PDF generieren</Button>
            </Card>
        </>) : <></>}
        </>
    )
}