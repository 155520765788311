export default function FloatingInput(props) {
    const label = props.label || 'Label';
    const children = props.children;
    const classNames = props.className || '';
    
    let propsCpy = {...props};
    delete propsCpy['label'];
    delete propsCpy['children'];
    delete propsCpy['className'];

    return (
        <div className="form-floating">
            <select className={"form-select " + classNames} id={label} {...propsCpy}>
                {children}
            </select>
            <label htmlFor={label}>{label}</label>
        </div>
    );
}