import { useEffect, useState } from "react";
import usePageState from "../../hooks/usePageState";
import StudentEdit from "../../components/forms/dataEdits/StudentEdit";
import useTrigger from "../../hooks/useTrigger";
import useFetchData from "../../hooks/useFetchData";
import api from "../../service/api";
import { Card } from "react-bootstrap";
import StudentSelect from "../../components/forms/selects/StudentSelect";
import DisplayEditToggleCard from "../../components/forms/DisplayEditToggleCard";
import StudentDisplay from "../../components/forms/dataDisplays/StudentDisplay";
import usePushNotificator from "../../hooks/usePushNotificator";
import DeleteButton from "../../components/DeleteButton";
import useLoginToken from "../../hooks/useLoginToken";
import AttendanceStudentView from "../../components/attendance/AttendanceStudentView";

export default function ViewStudent(props) {
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const students = useFetchData('students', {}, [saveListener]);
    const dbCourses = useFetchData(`student/${pageState.id}/courses`, { }, [pageState, saveListener]);
    const [{ userType }, ] = useLoginToken();

    const [ prename, setPrename ] = useState('');
    const [ name, setName ]       = useState('');
    const [ _class, setClass ]    = useState('');
    const [ pwd, setPwd ]         = useState('');
    const [ courses, setCourses ] = useState([]);

    const [ pushNotification, Notificator ] = usePushNotificator();

    const data = students.filter(e => e.id === pageState.id)[0];
    useEffect(() => {
        if(data !== undefined) {
            setPrename(data.prename)
            setName(data.name);
            setClass(data.class);
            setPwd(data.pwd);
        }

    }, [pageState, students, data])

    useEffect(() => {
        setCourses(dbCourses)
    }, [dbCourses])

    const saveEdits = async () => {
        let body = {prename: prename, name: name, class: _class, pwd: pwd};
        
        const res1 = await api.put('students/' + pageState.id, body);

        if(res1.status === 'ok') {
            body = {entries: courses.map(c => { return {courseId: c.id, visit_voluntary: c.visit_voluntary} })};
            
            let res2;
            if(userType === 'other') {
                res2 = await api.put('students/' + pageState.id + "/courses", body);
            } else {
                // as a teacher you cannot change the courses of a student
                res2 = { status: 'ok' };
            }

            if(res2.status === 'ok') {
                pushNotification('Bearbeitung erfolgreich', 'Der Schüler wurde erfolgreich bearbeitet', 'success');
                saveTrigger();
                return true;
            }
        }

        pushNotification('Interner Fehler', res1.message, 'danger');
        return false;
    }


    return (
        <>
        <PageStateReloader />
        <Card body className="mb-3">
            <Card.Title>Schüler auswählen</Card.Title>
            <StudentSelect value={pageState.id} setValue={v => setPageState({id: v})} dependencies={[saveListener]}/>
        </Card>
        <Notificator />
        {data === undefined ? <></> : (
            <>
            <DisplayEditToggleCard suppressButtonSaveBtn cardTitle={`Schüler ${data.name}, ${data.prename}`} displayComponent={StudentDisplay} editComponent={StudentEdit} saveFun={saveEdits} prename={prename} setPrename={setPrename} name={name} setName={setName} pwd={pwd} setPwd={setPwd} _class={_class} setClass={setClass} courses={courses} setCourses={setCourses} />
            {courses.length === 0 ? <></> : (
                <AttendanceStudentView student={data} editable/>
            )}
            <Card body className="text-end">
                <DeleteButton userType="student" userId={pageState.id} btnText="Schüler löschen" onDeleted={() => { setPageState({id: ''}); saveTrigger(); }}/>
            </Card>
            </>
        )}
        
        </>
    )

}