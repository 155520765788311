import { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import FormRow from '../../bt/FormRow'
import DisplayCoursesInSentence from './DisplayCoursesInSentence'

export default function StudentDisplay({ prename, name, _class, pwd, courses }) {
    const [ showPwd, setShowPwd ] = useState(false);

    return (
        <>
            <FormRow label="Name" plaintext>
                {`${name}, ${prename}`}
            </FormRow>
            <FormRow label="Klasse" plaintext>
                {_class}
            </FormRow>
            <FormRow label="Passwort" plaintext>
                <Row>
                    <Col sm="10">
                        {showPwd ? pwd : '●●●●●●●●'}
                    </Col>
                    <Col className='text-end'>
                        <Button variant="outline-secondary" size="sm" onClick={() => setShowPwd(!showPwd)}>
                            { showPwd ? <EyeFill size="18" /> : <EyeSlashFill size="18"/>}
                        </Button>
                    </Col>
                </Row>
            </FormRow>
            <FormRow label="Kurse" plaintext>
                <DisplayCoursesInSentence courses={courses} links />
            </FormRow>
        </>
    )
}