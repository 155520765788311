import { useEffect, useState } from "react";
import usePageState from "../../hooks/usePageState";
import TeacherEdit from "../../components/forms/dataEdits/TeacherEdit";
import useTrigger from "../../hooks/useTrigger";
import useFetchData from "../../hooks/useFetchData";
import api from "../../service/api";
import { Card } from "react-bootstrap";
import ClassSelect from "../../components/forms/selects/ClassSelect";
import TeacherDisplay from "../../components/forms/dataDisplays/TeacherDisplay";
import DisplayEditToggleCard from "../../components/forms/DisplayEditToggleCard";
import StudentList from "../../components/StudentList";
import usePushNotificator from "../../hooks/usePushNotificator";
import DeleteButton from "../../components/DeleteButton";
import useLoginToken from "../../hooks/useLoginToken";

export default function ViewTeacher(props) {
    const [{ userType }, ] = useLoginToken();
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const teachers = useFetchData('teachers', {}, [saveListener]);

    const data = teachers.filter(e => e.id === pageState.id)[0];
    const studentsInClass = useFetchData(`class/${data?.class}/students`, {}, [data]);

    const [ name, setName ]    = useState('');
    const [ title, setTitle ]  = useState('');
    const [ _class, setClass ] = useState('');
    const [ pwd, setPwd ]      = useState('');
    const [ pwd2, setPwd2 ]    = useState('');

    const [ pushNotification, Notificator ] = usePushNotificator();

    useEffect(() => {
        if(data !== undefined) {
            setName(data.name);
            setTitle(data.title);
            setClass(data.class);

            setPwd('');
            setPwd2('');
        }

    }, [pageState, teachers, data])

    const saveEdits = async () => {
        let body = {name: name, title: title, class: _class};

        if(pwd !== '') {
            if(pwd !== pwd2) {
                pushNotification('Ungültige Eingabe', 'Die Passwörter müssen übereinstimmen!', 'warning');
                return false;
            }
            body.pwd = pwd;
        }
        
        const res = await api.put('teachers/' + pageState.id, body);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Der Lehrer wurde erfolgreich bearbeitet', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.error, 'danger');
            return false;
        }
        
    }


    return (
        <>
        <PageStateReloader />
        { userType === 'other' ? (
            <Card body className="mb-3">
                <Card.Title>Klasse auswählen</Card.Title>
                <ClassSelect value={pageState.id} setValue={v => setPageState({id: v})} dependencies={[saveListener]}/>
            </Card>
        ) : <></> }
        <Notificator />
        {data === undefined ? <></> : <>
            <DisplayEditToggleCard suppressButtonSaveBtn cardTitle={`Klassenleiter ${data.title} ${data.name}`} displayComponent={TeacherDisplay} editComponent={TeacherEdit} saveFun={saveEdits} name={name} setName={setName} pwd={pwd} setPwd={setPwd} pwd2={pwd2} setPwd2={setPwd2} _class={_class} setClass={setClass} title={title} setTitle={setTitle} />
            { userType === 'other' ? (
                <>
                <Card body className="mb-3">
                    <Card.Title>Schüler der Klasse {_class}</Card.Title>
                    <hr />
                    <StudentList students={studentsInClass} courseLinks studentLinks hideClass />
                </Card>
                <Card body className="text-end">
                    <DeleteButton userType="teacher" userId={pageState.id} btnText="Klasse löschen" onDeleted={() => { setPageState({id: ''}); saveTrigger(); }}/>
                </Card>
                </>
            ) : <></> }
        </>}
        
        </>
    )

}