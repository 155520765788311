import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DisplayCoursesInSentence from "../../../components/forms/dataDisplays/DisplayCoursesInSentence";
import useFetchData from "../../../hooks/useFetchData";

export default function TrainerList() {
    const trainers = useFetchData('trainers');
    const courses = useFetchData('courses');

    return (
        <Card body>
            <Card.Title>Kursleiterliste</Card.Title>
            <hr />
            <Table striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Kurse</th>
                        <th>E-Mail-Adresse</th>
                    </tr>
                </thead>
                <tbody>
                    {trainers.map(t => (
                        <tr key={t.id}>
                            <td><Link to={{pathname: '/viewTrainer', state:{id: t.id}}}>{t.title} {t.name}</Link></td>
                            <td><DisplayCoursesInSentence courses={t.courses?.split(',').map(cid => courses?.filter(c => c.id === cid)[0])} links noCourseMsg="betreibt zurzeit keine Kurse"/></td>
                            <td>{t.mail}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card>
    )
}