import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import logoGTS from '../assets/taro-schule-fassade.jpg'
import { useState } from 'react';
import FloatingInput from '../components/bt/FloatingInput';
import api from '../service/api';
import { Row, Col, Card, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';
import ClassSelect from '../components/forms/selects/ClassSelect';
import usePushNotificator from '../hooks/usePushNotificator';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useLoginToken from '../hooks/useLoginToken';

async function loginUser(credentials) {
    return api.post('login', credentials);
}

export default function Login() {
    if(document.location.pathname !== '/')
        document.location.pathname = '/';

    const [userData, setUserData] = useState({});
    const [password, setPasswort] = useState('');
    const [userType, setUserType] = useState('student'); // student, teacher, trainer, other

    const [ , setLoginToken ] = useLoginToken();

    const [ pushNotification, Notificator ] = usePushNotificator();
    
    const handleSubmit = async e => {
        e.preventDefault();
        const res = await loginUser({
            userType,
            userData,
            password
        });
        if(res.auth) {
            setLoginToken(res);
            window.location.reload();
        } else {
            pushNotification('Anmeldefehler', res.message, 'danger');
        }
    }

    let userIdentification = <div>Interner fehler. Bitte Drücken Sie auf einen Anmeldetypen</div>;
    
    if(userType === 'student') {
        userIdentification = (
            <Row>
                <Col>
                    <ClassSelect defaultFirst={true} floating={true} showTeacherNames={false} useClassAsValue={true} value={userData.class} setValue={c => setUserData({...userData, class: c})} />
                </Col>
                <Col>
                    <div style={{paddingRight: 0, width: '80%'}} className="float-left">
                        <FloatingInput label='Initialen' onChange={(e) => setUserData({...userData, initials: e.target.value})}/>
                    </div>
                    <div sm="3" style={{paddingLeft: 10, paddingRight: 0, paddingTop: '14px', width: '20%'}} className="text-muted float-right">
                        <OverlayTrigger
                            key='initals-help'
                            placement='top'
                            overlay={
                                <Tooltip id='initals-help'>
                                    Beispiel:<br />
                                    <strong>P</strong>ia Zoe <strong>W</strong>olf<br />
                                    Initialien: <strong>PW</strong>
                                </Tooltip>
                            }
                        >
                            <QuestionCircle size="20" className="hoverable"/>
                        </OverlayTrigger>
                    </div>
                </Col>
            </Row>
        );
    } else if(userType === 'teacher') {
        userIdentification = <ClassSelect defaultFirst={true} floating={true} value={userData.class} setValue={c => setUserData({...userData, class: c})} useClassAsValue/>;
    } else if(userType === 'trainer') {
        userIdentification = <FloatingInput label='Nachname' id='1' onChange={(e) => setUserData({...userData, username: e.target.value})}/>;
    } else if(userType === 'other') {
        userIdentification = <FloatingInput label='Benutzername' id='2' onChange={(e) => setUserData({...userData, username: e.target.value})}/>;
    }



    return (
        <div className="d-flex justify-content-center">
            <Card className="loginCard">
            <Card.Img src={logoGTS} alt="GTA-Logo" />
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <h3 style={{textAlign: 'center'}}>Anmeldung GTA</h3>
                        <ButtonGroup className="w-100">
                            <Button onClick={() => setUserType('student')} variant={userType === 'student' ? 'primary' : 'outline-primary'}>Schüler</Button>
                            <Button onClick={() => setUserType('teacher')} variant={userType === 'teacher' ? 'primary' : 'outline-primary'}>Lehrer</Button>
                            <Button onClick={() => setUserType('trainer')} variant={userType === 'trainer' ? 'primary' : 'outline-primary'}>Leiter</Button>
                            <Button onClick={() => setUserType('other')}   variant={userType === 'other' ? 'primary' : 'outline-primary'}>Andere</Button>
                        </ButtonGroup>
                        <hr />
                        <Notificator />
                        {userIdentification}
                        <FloatingInput type='password' label='Passwort' value={password} onChange={e => setPasswort(e.target.value)} className='mt-3'/>
                        <div className='mt-3 text-end'>
                            <Button variant="success" type="submit">Anmelden</Button>
                        </div>
                    </form>
                </Card.Body>
                <Card.Footer className="text-center">
                    <Link to={{pathname: "/reader"}}  active="true">Kursübersicht</Link> · <a href="https://taroschule.de/" active="true">Schulseite</a>
                </Card.Footer>
            </Card>
        </div>
    );
}