import FormRow from "../../bt/FormRow";
import PasswordDisplayRow from "./PasswordDisplayRow";

export default function TrainerDisplay({ id, title, name, mail, courseLinks}) {

    return (
        <>
        <FormRow label="Name" plaintext>
            {title + ' ' + name}
        </FormRow>
        <FormRow label="E-Mail-Adresse" plaintext>
            {mail === '' ? <div className="text-muted">--- keine Mail-Adresse gespeichert ---</div> : mail}
        </FormRow>
        <PasswordDisplayRow />
        </>
    );
}

TrainerDisplay.defaultProps = {
    courseLinks: false,
}