import { useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import useFetchData from "../../hooks/useFetchData";
import Scheduler from "../../service/Scheduler";
import utils from "../../service/utils";
import ConfirmModal from "../bt/ConfirmModal";

export default function StudentCoursesEditor({ courses, setCourses, _class }) {
    const [ newCourseId, setNewCourseId ] = useState('');
    const [ crntlyClassConflict, setCrntlyClassConfilct ] = useState(true);
    const [ showWarningModal, setShowWarningModal ] = useState(false);
    const allCourses = useFetchData('courses');
    const availableCourses = allCourses.filter(c1 => courses.filter(c2 => c1.id === c2.id).length === 0);

    const addCourse = (classConflict) => {
        classConflict = classConflict === 'true' || classConflict === true;

        if(newCourseId !== '') {
            if(classConflict) {
                setShowWarningModal(true);
            } else {
                setCourses([...courses, {...allCourses.filter(c => Number(c.id) === Number(newCourseId))[0], visit_voluntary: '[]'}]);
                setShowWarningModal(false);
                setNewCourseId('');
            }
        }
    };

    const deleteCourse = (id) => {
        setCourses(courses.filter(c => c.id !== id));
    }

    return (
        <>
        <Card>
            <ListGroup variant="flush">
                {courses.map((c, i) => <ListGroup.Item key={i}><StudentCoursesEditorEntry course={c} setCourse={v => setCourses(utils.replaceElementInArray(courses, i, v))} delCourse={() => deleteCourse(c.id)} /></ListGroup.Item>)}
                <ListGroup.Item>
                    <Row>
                        <Col sm="10">
                            <Form.Select size="sm" value={newCourseId} onChange={e => {setNewCourseId(e.target.value); setCrntlyClassConfilct(e.target.children[e.target.selectedIndex].dataset.classconflict)}}>
                                <option disabled="disabled" value=''>Kurs auswählen</option>
                                {availableCourses.map(c => {
                                    const classConflict = !utils.isStudentInClassLevels(_class, c.class_levels);
                                    return <option key={c.title} value={c.id} className={!classConflict ? "" : "text-muted"} data-classconflict={classConflict}>{c.title} von {c.trainer_title} {c.trainer_name} (Klassen {utils.classLevelsDisplayString(c.class_levels)})</option>;
                                })}
                            </Form.Select>
                        </Col>
                        <Col className="text-end" sm="2">
                            <Button size="sm" variant="success" onClick={() => addCourse(crntlyClassConflict)} disabled={newCourseId === ''}>Einschreiben</Button>
                        </Col>
                    </Row>
                    
                </ListGroup.Item>
            </ListGroup>
        </Card>
        <ConfirmModal title="Unpassende Kurseinschreibung" show={showWarningModal} setShow={setShowWarningModal} confirmCallback={() => addCourse(false)} confirmBtnText='Trotzdem einschreiben'>
            Der Kurs wird nicht für die Klassenstufe des Schülers angeboten!
        </ConfirmModal>
        </>
    )
}

function StudentCoursesEditorEntry({course, setCourse, delCourse }) {
    const voluntaryEntries = new Scheduler(JSON.parse(course.schedule)).getVoluntaryEntries();

    const isInVoluntaryEntry = (uid) => {
        return JSON.parse(course.visit_voluntary).includes(uid);
    }

    const toggleInVoluntaryEntry = (uid) => {
        const newVisitVoluntary = JSON.stringify(utils.toggleArrayItem(JSON.parse(course.visit_voluntary), uid));
        setCourse({...course, visit_voluntary: newVisitVoluntary});
    }

    return (
        <Row>
            <Col>
                {course.title} ({course.trainer_title} {course.trainer_name})
                {voluntaryEntries.length === 0 ? <></> : (
                    <Row>
                        <Col><small>Nimmt Teil an: </small></Col>
                        {voluntaryEntries.map((v, i) => (
                            <Col key={i}>
                                <Form.Check type="checkbox" size="sm" id={i}>
                                    <Form.Check.Input type="checkbox" className="me-1" defaultChecked={isInVoluntaryEntry(v.uid)} onChange={() => toggleInVoluntaryEntry(v.uid)}/>
                                    <Form.Check.Label><small>{v.title}</small></Form.Check.Label>
                                </Form.Check>
                            </Col>
                        ))}
                    </Row>
                )}
            </Col>
            <Col className="text-end">
                <Button size="sm" variant="danger" onClick={delCourse}>Austragen</Button>
            </Col>
        </Row>
    );
}