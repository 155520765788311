import apiCash from "./apiCash"

const SERVER_ADRESS = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_BUILD;

class Api {

    getCrntLoginToken() {
        return JSON.parse(localStorage.getItem('loginToken'));
    }

    setCrntLoginToken(token) {
        localStorage.setItem('loginToken', JSON.stringify(token));
    }

    isLoggedIn() { 
        const token = this.getCrntLoginToken();
        return token && token.auth;
    }

    getUserType() {
        return this.isLoggedIn() ? this.getCrntLoginToken().userType : 'none';
    }

    async send(endpoint, method, requestData, contentType = 'application/json') {
        const token = this.getCrntLoginToken()?.token;
        let promise = fetch(SERVER_ADRESS + endpoint, {
            method: method,
            headers: {
                'Content-Type': contentType,
                'x-access-token': token,
            },
            ...requestData
            })
                
        if(contentType === 'application/json')
            return promise.then(res => res.json());
        else 
            return promise
    }

    async get(endpoint, params = {}) {
        const cashKey = apiCash.genKey(endpoint, params);
        if(apiCash.valuePresent(cashKey)) {
            return apiCash.get(cashKey);
        } else if(apiCash.valueRequested(cashKey)) {
            return apiCash.waitFor(cashKey);
        } else {
            const urlParams = this.parseGetParams(params);
            const promise = this.send(endpoint + urlParams, 'GET', {});
            apiCash.putPendingPromise(cashKey, promise);
            const res = await promise;
            apiCash.putValue(cashKey, res);
            return res;
        }

        
    }

    parseGetParams(params) {
        let urlParams = "";
        if(params !== {}) {
            urlParams = "?";
            for(const key in params) {
                if(params[key] instanceof Array) {
                    for(const val of params[key])
                        urlParams += key + "[]=" + encodeURIComponent(val) + "&";
                } else {
                    urlParams += key + "=" + encodeURIComponent(params[key]) + "&"; 
                }
            }
        }

        return urlParams;
    }


    async post(endpoint, body) {
        apiCash.free();
        return this.send(endpoint, 'POST', {body: JSON.stringify(body)});
    }

    async put(endpoint, body) {
        apiCash.free();
        return this.send(endpoint, 'PUT', {body: JSON.stringify(body)});
    }

    async delete(endpoint) {
        apiCash.free();
        return this.send(endpoint, 'DELETE', {});
    }

    getServerAdress() {
        return SERVER_ADRESS;
    }
}

export default new Api();
