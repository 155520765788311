import { useEffect, useState } from "react";
import { Alert, Button, Card, FormSelect, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ConfirmModal from "../../components/bt/ConfirmModal";
import useFetchData from "../../hooks/useFetchData";
import api from "../../service/api";

export default function StudentForwardingPreview() {
    const readyReqResult = useFetchData('studentForwarding/ready');
    const previewData = useFetchData('studentForwarding/preview');
    const [ previewedClass, setPreviewedClass ] = useState('06/1');
    const [ showExecuteConfirmModal, setShowExecuteConfirmModal ] = useState(false);
    const history = useHistory();

    let pastPreveiewedClass = Number(previewedClass.split('/')[0]) - 1;
    pastPreveiewedClass = (pastPreveiewedClass < 10 ? '0' + pastPreveiewedClass.toString() : pastPreveiewedClass.toString()) + "/" + previewedClass.split('/')[1];
    
    useEffect(() => {
        if(previewData.teachers) {
            setPreviewedClass(previewData.teachers[0].class);
        }
    }, [previewData, setPreviewedClass])

    const execute = async () => {
        const res = await api.put('studentForwarding/execute', {});
        if(res.status === 'ok') {
            history.push('/');
        }
    }
    
    return (<>
        <h1>Schuljahreswechsel für Schüler prüfen und durchführen</h1>

        <ConfirmModal title="Schuljahreswechsel bestätigen" confirmBtnVariant="warning" confirmBtnText="Wirklich Durchführen" confirmCallback={execute} cancelBtnText="Abbrechen" show={showExecuteConfirmModal} setShow={setShowExecuteConfirmModal}>
            <p>Soll der Schuljahreswechsel wirklich durchgeführt werden? Diese Aktion ist ohne Weiteres nicht umkehrbar.</p>
                {readyReqResult.ready === false ? (
                <Alert variant="danger">Es {readyReqResult.unsetStudents === 1 ? 'hat' : 'haben'} immernoch {readyReqResult.unsetStudents} Schüler keine Information darüber, wo {readyReqResult.unsetStudents === 1 ? 'er im nächsten Jahr verbleibt. Dieser wird' : 'sie im nächsten Jahr verbleiben. Diese werden'} <strong>nicht versetzt!</strong></Alert>
            ) : <></> }
        </ConfirmModal>

        {readyReqResult.ready === true ? (
            <Alert variant="success">Alles fertig! Der Schuljahreswechsel kann durchgeführt werden. Bitte überprüfen Sie nochmal, ob jeder Schüler richtig zugeordnet ist.</Alert>
        ) : <></> }
        {readyReqResult.ready === false ? (
            <Alert variant="danger">Es {readyReqResult.unsetStudents === 1 ? 'hat' : 'haben'} noch {readyReqResult.unsetStudents} Schüler keine Information darüber, wo {readyReqResult.unsetStudents === 1 ? 'er im nächsten Jahr verbleibt. Dieser wird' : 'sie im nächsten Jahr verbleiben. Diese werden'} <strong>nicht versetzt!</strong> Bitte korrigieren Sie das <Link to={{pathname: "/studentForwarding", state: {class: readyReqResult.randomClassWithUnsetStudents}}}>Hier</Link>.</Alert>
        ) : <></> }
        {previewData.teachers ? (<>
            <Card className="mb-3">
                <Card.Header>Vorschau der Klassen nach dem Schuljahreswechsel</Card.Header>
                <Card.Body>
                    <h4>Klasse auswählen</h4>
                    <FormSelect value={previewedClass} onChange={e => setPreviewedClass(e.target.value)}>
                        {previewData.teachers.map(teacher => (
                            <option value={teacher.class} key={teacher.class}>{teacher.class} ({teacher.title} {teacher.name})</option>
                        ))}
                    </FormSelect>
                    <div class="text-end">
                        <Link to={{pathname: "/studentForwarding", state: {class: pastPreveiewedClass}}}>Bearbeiten</Link>
                    </div>
                    <hr />
                    {previewData.students[previewedClass]?.length ? (
                        <Table striped size="sm">
                            <thead>
                                <tr>
                                    <th>Nachname</th>
                                    <th>Vorname</th>
                                    <th>Herkunft</th>
                                </tr>
                            </thead>
                            <tbody>
                                {previewData.students[previewedClass].map((student, i) => (
                                    <tr key={i}>
                                        <td>{student.name}</td>
                                        <td>{student.prename}</td>
                                        <td>{student.origin === 'new' ? 'Neuzugang' : (student.origin === 'no info' ? <><span className="text-muted">{previewedClass}</span> - <span style={{color: 'red'}}>nicht gesetzt</span></> : student.origin)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="text-center text-muted">--- In dieser Klasse werde keine Schüler gehen ---</div>
                    )}
                </Card.Body>
            </Card>
            <Card body className="mb-3 text-end">
                <Button variant="warning" onClick={() => setShowExecuteConfirmModal(true)}>Schuljahreswechsel {readyReqResult.ready === false ? 'trotzdem' : ''} durchführen</Button>
            </Card>
        </>) : <></>}


    </>);

}