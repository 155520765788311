import { Badge, Button, Card, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import FormRow from "../../components/bt/FormRow";
import utils from "../../service/utils";
import useFetchData from "../../hooks/useFetchData";
import './calender.css'
import { Fragment, useEffect, useState } from "react";
import ConfirmModal from "../../components/bt/ConfirmModal";
import api from "../../service/api";
import usePushNotificator from "../../hooks/usePushNotificator";

export default function EditYear() {
    const yearDB = useFetchData('school-year');
    const [ yearInfo, setYearInfo ] = useState([]);
    const [ showNewModal, setShowNewModal ]   = useState(false);
    const [ showEditModal, setShowEditModal ] = useState(false);

    const [ pushNotification, Notificator ] = usePushNotificator();

    const [ inEditMode, setInEditMode ] = useState(false);

    useEffect(() => {
        setYearInfo(yearDB);
    }, [ yearDB ]);

    const resetYearInfo = () => {
        setYearInfo({
            year_start: '',
            year_end: '',
            course_selection_start: '',
            course_selection_end: '',
            weeks: [],
            vacations: []
        });
    }

    const saveAll = async () => {
        const res = await api.put('school-year', yearInfo);
        if(res.status === 'ok') {
            setInEditMode(false);
    
            pushNotification('Bearbeitung erfolgreich', 'Schuljahr erfolgreich bearbeitet!', 'success');
        } else {
            pushNotification('Interner Fehler', res.error, 'danger');
        }

    }

    if(yearInfo instanceof Array) {
        return <h1>Laden</h1>
    } else {
        return (
            <>
            <h1>Bearbeitung des Schuljahrs</h1>
            <Notificator />
            <Row>
                <Col>
                    {inEditMode ? (<>
                        <EditYearInfoEdit yearInfo={yearInfo} setYearInfo={setYearInfo} />
                        <Card body className="text-end">
                            <Button variant="success" onClick={saveAll}>Speichern</Button>
                        </Card>
                    </>) : (<>
                        <EditYearInfoDisplay yearInfo={yearInfo} />
                        <Card body className="text-end">
                            <Button variant="primary" className="me-2" onClick={() => setShowEditModal(true)}>Schuljahr bearbeiten</Button>
                            <Button variant="warning" onClick={() => setShowNewModal(true)}>Neues Schuljahr</Button>
                        </Card>
                    </>)}
                    
                </Col>
                <Col>
                    <EditYearCalender yearInfo={yearInfo} setYearInfo={setYearInfo} inEditMode={inEditMode} />
                </Col>
            </Row>
            <ConfirmModal title="Neues Schuljahr" show={showNewModal} setShow={setShowNewModal} confirmCallback={() => {resetYearInfo(); setInEditMode(true); return true;}} confirmBtnText="Los geht's!" confirmBtnVariant="warning">
                Sie wollen ein neues Schuljahr erstellen. Das bedeutet, dass <strong>alle Anwesenheitsdaten gelöscht werden</strong>. Daher sollten Sie die Daten <Link to={{pathname: '/exportAttendance'}}>hier nochmal absichern</Link>. Wenn Sie das schon gemacht haben, können Sie starten.
            </ConfirmModal>
            <ConfirmModal title="Schuljahr bearbeiten" show={showEditModal} setShow={setShowEditModal} confirmCallback={() => { setInEditMode(true); return true; }} confirmBtnText="Fortfahren">
                Sie wollen das Schuljahr bearbeiten. Beachten Sie, dass Anwesenheitsdaten entstehen könnten, die nicht mehr aktuell sind und dabei die Endstatistik beeinträchtigen können. Dies kann bei nachträglicher Bearbeitung der A-, B-Wochen und Schuljahresdauer passieren. Möchten Sie trotzdem fortfahren?
            </ConfirmModal>
            </>
        );
    }
}

function EditYearInfoDisplay({ yearInfo }) {
    return (
        <Card body className="mb-3">
            <h3>Informationen</h3>
            <FormRow plaintext label="Schuljahr" labelSize={3}>
                {utils.sqlDateToGermanDate(new Date(yearInfo.year_start))} bis {utils.sqlDateToGermanDate(new Date(yearInfo.year_end))}
            </FormRow>
            <FormRow plaintext label="Kurswahl" labelSize={3}>
                {utils.sqlDateToGermanDate(new Date(yearInfo.course_selection_start))} bis {utils.sqlDateToGermanDate(new Date(yearInfo.course_selection_end))}
            </FormRow>
            <h4>Ferien</h4>
            <ul>
                {yearInfo.vacations.filter(v => v.start !== v.end).map((v, i) => (
                    <li key={i}>{v.title} ({utils.sqlDateToGermanDate(new Date(v.start))} - {utils.sqlDateToGermanDate(new Date(v.end))})</li>
                ))}
            </ul>
            <h4>Feiertage</h4>
            <ul>
                {yearInfo.vacations.filter(v => v.start === v.end).map((v, i) => (
                    <li key={i}>{v.title} ({utils.sqlDateToGermanDate(new Date(v.start))})</li>
                ))}
            </ul>
        </Card>
    );
}

function EditYearInfoEdit({ yearInfo, setYearInfo }) {
    const [ showAutoCreateModal, setShowAutoCreateModal ] = useState(false);
    const [ showEditYearSpanModal, setShowEditYearSpanModal ] = useState(yearInfo.year_start === '' || yearInfo.year_start === '');

    const [ yearStartTemp, setYearStartTemp ] = useState('');
    const [ yearEndTemp, setYearEndTemp ]     = useState('');
    const [ pushYearSpanNotification, YearSpanNotificator ] = usePushNotificator({ defaultVariant: 'warning', maxCount: 3 });

    const setInfo = (key, val) => {
        setYearInfo({...yearInfo, [key]: val});
    }

    const validateTempYearSpan = () => {
        let start = new Date(yearStartTemp);
        let end   = new Date(yearEndTemp);

        if(!(start instanceof Date && !isNaN(start.valueOf()) && end instanceof Date && !isNaN(end.valueOf()))) {
            pushYearSpanNotification('Füllen Sie die Felder bitte mit validen Daten');
            return false;
        }

        start = start.getTime();
        end   = end.getTime();

        if(start > end) {
            pushYearSpanNotification('Der Start des Jahres muss VOR dem Ende kommen');
            return false;
        }

        const maxYearSpan = 2;
        if(end - start > maxYearSpan * 365 * 24 * 60 * 60 * 1000) {
            pushYearSpanNotification('Das Schuljahr darf maximal 2 Jahre lang sein!');
            return false;
        }

        setYearInfo({...yearInfo, year_start: yearStartTemp, year_end: yearEndTemp});

        return true;
    }

    const setVacationProp = (i, key, val) => {
        let vac = yearInfo.vacations[i];
        vac[key] = val;
        setInfo('vacations', utils.replaceElementInArray(yearInfo.vacations, i, vac));
    }

    const newVac = (title, start, end) => {
        setInfo('vacations', [...yearInfo.vacations, {title: title, start: start, end: end}]);
    }

    const fetchVacations = async () => {
        let externalVacation = await api.get('school-year/vacations-external', { yearStart: yearInfo.year_start, yearEnd: yearInfo.year_end});
        setInfo('vacations', [...yearInfo.vacations, ...externalVacation]);
    }


    return (
        <>
        {yearInfo.year_start === '' || yearInfo.year_start === '' ? <></> : (
        <Card body className="mb-3">
            <h3>Informationen</h3>
            <FormRow plaintext label="Schuljahr" labelSize={3} small>
                <Row>
                    <Col sm="6">
                        <small>{utils.sqlDateToGermanDate(new Date(yearInfo.year_start))} bis {utils.sqlDateToGermanDate(new Date(yearInfo.year_end))}</small>
                    </Col>
                    <Col sm="6" className="text-end">
                        <Button size="sm" onClick={() => setShowEditYearSpanModal(true)}>Daten setzen</Button>
                    </Col>
                </Row>
            </FormRow>
            <FormRow plaintext label="Kurswahl" labelSize={3} small>
                <Row>
                    <Col sm="5">
                        <Form.Control type="date" size="sm" value={yearInfo.course_selection_start} onChange={e => setInfo('course_selection_start', e.target.value)} />
                    </Col>
                    <Col sm="2" className="text-center"><small>bis</small></Col>
                    <Col sm="5">
                        <Form.Control type="date" size="sm" value={yearInfo.course_selection_end} onChange={e => setInfo('course_selection_end', e.target.value)} />
                    </Col>
                </Row>
            </FormRow>
            <h4>Ferien</h4>
            <ListGroup className="mb-2">
                {yearInfo.vacations.map((v, i) => v.start === v.end ? <Fragment key={i}></Fragment> : (
                    <ListGroup.Item key={i}>
                        <Row>
                            <Col sm="4">
                                <Form.Control size="sm" value={v.title} onChange={e => setVacationProp(i, 'title', e.target.value)} />
                            </Col>
                            <Col sm="3">
                                <Form.Control type="date" size="sm" value={v.start} onChange={e => setVacationProp(i, 'start', e.target.value)} />
                            </Col>
                            <Col sm="1" className="text-center"><small>bis</small></Col>
                            <Col sm="3">
                                <Form.Control type="date" size="sm" value={v.end} onChange={e => setVacationProp(i, 'end', e.target.value)} />
                            </Col>
                            <Col sm="1">
                                <Button size="sm" variant="danger" onClick={() => setInfo('vacations', utils.removeElementFromArray(yearInfo.vacations, i))}>X</Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item className="text-end">
                    <Button size="sm" variant="success" onClick={() => newVac('Titel', '2020-01-01', '2020-01-27')}>Hinzufügen</Button>
                </ListGroup.Item>
            </ListGroup>
            <h4>Feiertage</h4>
            <ListGroup className="mb-2">
                {yearInfo.vacations.map((v, i) => v.start !== v.end ? <Fragment key={i}></Fragment> : (
                    <ListGroup.Item key={i}>
                        <Row>
                            <Col sm="6">
                                <Form.Control size="sm" value={v.title} onChange={e => setVacationProp(i, 'title', e.target.value)} />
                            </Col>
                            <Col sm="5">
                                <Form.Control type="date" size="sm" value={v.start} onChange={e => { setVacationProp(i, 'start', e.target.value); setVacationProp(i, 'end', e.target.value);}} />
                            </Col>
                            <Col sm="1">
                                <Button size="sm" variant="danger" onClick={() => setInfo('vacations', utils.removeElementFromArray(yearInfo.vacations, i))}>X</Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item className="text-end">
                    <Button size="sm" variant="success" onClick={() => newVac('Titel', '2020-03-14', '2020-03-14')}>Hinzufügen</Button>
                </ListGroup.Item>
            </ListGroup>
            <div className="text-end">
                <Button variant="warning" onClick={() => setShowAutoCreateModal(true)}>Automatisch erstellen</Button>
            </div>
        </Card>
        )}
        <ConfirmModal title="Ferien automatisch erstellen" show={showAutoCreateModal} setShow={setShowAutoCreateModal} confirmCallback={() => {fetchVacations(); return true;}} >
            Die Daten zu den Ferien und Feiertagen werden über eine externe Quelle bezogen. Für die Richtigkeit wird in keinem Falle garantiert. <strong>Bitte überprüfen Sie die Richtigkeit</strong> bevor Sie speichern!
        </ConfirmModal>
        <Modal show={showEditYearSpanModal} onHide={() => setShowEditYearSpanModal(false)} backdrop="static" keyboard={false} >
            <Modal.Header>
                <Modal.Title>Bearbeitung Schuljahr</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Geben Sie bitte die neue Dauer des Schuljahres ein.</p>
                <FormRow label="Start">
                    <Form.Control type="date" value={yearStartTemp} onChange={e => setYearStartTemp(e.target.value)} />
                </FormRow>
                <FormRow label="Ende">
                    <Form.Control type="date" value={yearEndTemp} onChange={e => setYearEndTemp(e.target.value)} />
                </FormRow>
                <YearSpanNotificator />
            </Modal.Body>
            <Modal.Footer>
                {yearInfo.year_start === '' || yearInfo.year_start === '' ? <></> : (
                    <Button variant="secondary" onClick={() => setShowEditYearSpanModal(false)}>Abbrechen</Button>
                )}
                <Button variant="success" onClick={() => {if(validateTempYearSpan()) setShowEditYearSpanModal(false);}}>Speichern</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

function EditYearCalender({ yearInfo, setYearInfo, inEditMode }) {
    if(yearInfo.year_start === '' || yearInfo.year_start === '')
        return <></>;

    let date = new Date(yearInfo.year_start);
    let monthsOfYear = [ new Date(date.getTime()) ];
    const end = new Date(yearInfo.year_end)

    date.setDate(1);
    date.setHours(0);
    date.setMonth(date.getMonth() + 1);
    while(date <= end) {
        monthsOfYear.push(new Date(date.getTime()))
        date.setMonth(date.getMonth() + 1);
    }

    const setWeekInfo = (week, info) => {
        let weeks = yearInfo.weeks.filter(w => Number(week[0]) !== Number(w.week_nr) || Number(week[1]) !== Number(w.year));
        setYearInfo({...yearInfo, weeks: [...weeks, { week_nr: week[0], year: week[1], ...info}]});
    }
    
    return (
        <Card body>
            { monthsOfYear.map(m => <EditYearCalenderMonth firstDate={m} lastPossibleDate={end} key={m.getTime()} yearInfo={yearInfo} setWeekInfo={setWeekInfo} inEditMode={inEditMode} />)}
        </Card>
    )
}

function EditYearCalenderMonth({ firstDate, lastPossibleDate, yearInfo, setWeekInfo, inEditMode }) {
    let rows = [{ weekNo: firstDate.getWeek(), abWeek: 'A', cells: [] }];
    let date = new Date(firstDate.getTime());
    date.setDate(date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1));
    
    while(date < firstDate) {
        rows[rows.length - 1].cells.push(<td className="calender-cell calender-cell-hidden" key={date.getTime()}></td>);
        date.setDate(date.getDate() + 1);
    }
        
    
    
    while(date.getMonth() === firstDate.getMonth() && date <= lastPossibleDate) {
        // if monday, create new week row with week indicator
        if(date.getDay() === 1 && rows[rows.length - 1].cells.length > 0) {
            rows.push({ weekNo: date.getWeek(), abWeek: 'A', cells: [] })
        }
            
        rows[rows.length - 1].cells.push(<EditYearDayCell date={new Date(date.getTime())} key={date.getTime()} yearInfo={yearInfo} />);

        // if sunday, add info cell
        if(date.getDay() === 0) {
            rows[rows.length - 1].cells.push(<EditYearWeekInfoCell week={date.getWeek()} key={"i" + date.getWeek()} weekData={yearInfo.weeks} setWeekInfo={setWeekInfo} inEditMode={inEditMode} />)
        }

        date.setDate(date.getDate() + 1);
    }

    if(date.getDay() !== 1) {
        // fill empty cells after month
        while(date.getDay() !== 1) {
            date.setDate(date.getDate() + 1);
            rows[rows.length - 1].cells.push(<td className="calender-cell calender-cell-hidden" key={date.getTime()}></td>);
        }

        // last day on year and not sunday than add another info cell
        if(date >= lastPossibleDate) {
            date.setDate(date.getDate() - 7); // set back to Monday
            rows[rows.length - 1].cells.push(<EditYearWeekInfoCell week={date.getWeek()} key={"i" + date.getWeek()} weekData={yearInfo.weeks} setWeekInfo={setWeekInfo} inEditMode={inEditMode} />);
        }
    }

    


    return (
        <Row className="mb-3">
            <Col sm="3">
                {utils.monthNumToFullName(firstDate.getMonth())}
                <br />
                <div className="text-muted">{firstDate.getFullYear()}</div>
            </Col>
            <Col>
                <table>
                    <tbody>
                        {rows.map( (row, i) => (
                            <tr key={"r" + i}>
                                {row.cells.map(cell => cell)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

function EditYearDayCell({ date, yearInfo }) {
    const crntVacations = yearInfo.vacations.filter(v => date >= new Date(v.start).setHours(0) && date <= new Date(v.end));
    const isElection    = date >= new Date(yearInfo.course_selection_start).setHours(0) && date <= new Date(yearInfo.course_selection_end);

    return <td className={"calender-cell calender-cell-" + date.getDay() + (crntVacations.length > 0 ? ' calender-cell-vacation' : '') + (isElection ? ' calender-cell-election' : '')}>{date.getDate()}</td>;
}

function EditYearWeekInfoCell({ week, weekData, setWeekInfo, inEditMode }) {
    const data = weekData.filter(w => Number(week[0]) === Number(w.week_nr) && Number(week[1]) === Number(w.year))[0];

    return (
        <>
        <td className="calender-info-cell text-muted">{week[0]}. KW</td>
        <td className="calender-info-cell text-muted"><EditYearWeekInfoBadge data={data} inEditMode={inEditMode} setData={(data) => setWeekInfo(week, data)} /></td>
        </>
    );
}

function EditYearWeekInfoBadge({ data, inEditMode, setData }) {
    const ab = data?.ab;

    const clickOnBadge = () => {
        if(ab === 'A') {
            setData({...data, ab: 'B'})
        } else if(ab === 'B') {
            setData({...data, ab: ''})
        } else {
            setData({...data, ab: 'A'})
        }
    }

    if(inEditMode) {
        if(ab === 'A') {
            return <Badge pill bg="primary" className="hoverable disable-text-select" onClick={clickOnBadge}>A-Woche</Badge>;
        } else if(ab === 'B') {
            return <Badge pill bg="success" className="hoverable disable-text-select" onClick={clickOnBadge}>B-Woche</Badge>;
        } else {
            return <Badge pill bg="secondary" className="hoverable disable-text-select" onClick={clickOnBadge}>AB-Info fehlt</Badge>
        }
    } else {
        if(ab === 'A') {
            return <Badge pill bg="secondary" className="disable-text-select">A-Woche</Badge>;
        } else if(ab === 'B') {
            return <Badge pill bg="secondary" className="disable-text-select">B-Woche</Badge>;
        } else {
            return <Badge pill bg="dark" className=" disable-text-select">AB-Info fehlt</Badge>
        }
    }
}