import { Form } from "react-bootstrap";
import useFetchData from "../../../hooks/useFetchData"
import FloatingSelect from '../../bt/FloatingSelect'

export default function StudentSelect({ defaultFirst, floating, value, setValue, dependencies, ...rest }) {
    
    const students = useFetchData('students', {}, dependencies, first => {if(defaultFirst) setValue(first.id)})
    const SelectInUse = floating ? FloatingSelect : Form.Select;

    return (
        <SelectInUse value={value} onChange={e => setValue(e.target.value)} {...rest}>
            {defaultFirst ? <></> : <option disabled="disabled" value=''>Schüler auswählen</option>}
            {students.map(s => <option value={s.id} key={s.id}>{s.name}, {s.prename} ({s.class})</option>)}
        </SelectInUse>
    )
}

StudentSelect.defaultProps = {
    defaultFirst: false,
    floating: false,
    dependencies: []
}