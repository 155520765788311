import { useEffect, useState } from "react";
import usePageState from "../../hooks/usePageState";
import useTrigger from "../../hooks/useTrigger";
import useFetchData from "../../hooks/useFetchData";
import api from "../../service/api";
import { Button, Card } from "react-bootstrap";
import DisplayEditToggleCard from "../../components/forms/DisplayEditToggleCard";
import CoursesSelect from "../../components/forms/selects/CourseSelect";
import CourseDisplay from "../../components/forms/dataDisplays/CourseDisplay";
import CourseEdit from "../../components/forms/dataEdits/CourseEdit";
import Scheduler from "../../service/Scheduler";
import AttendanceCourseView from "../../components/attendance/AttendanceCourseView";
import usePushNotificator from "../../hooks/usePushNotificator";
import DeleteButton from "../../components/DeleteButton";
import useLoginToken from "../../hooks/useLoginToken";
import { Link } from "react-router-dom";

export default function ViewCourse(props) {
    const [{ userType }, ] = useLoginToken();
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const courses = useFetchData('courses', {}, [saveListener]);

    const [ title, setTitle ]             = useState('');
    const [ trainerId, setTrainerId ]     = useState('');
    const [ sector, setSector ]           = useState('');
    const [ place, setPlace ]             = useState('');
    const [ classLevels, setClassLevels ] = useState('');
    const [ size, setSize ]               = useState(0);
    const [ description, setDescription ] = useState('');
    const [ schedule, setSchedule ]       = useState(new Scheduler().addEmptyEntry());

    const [ pushNotification, Notificator ] = usePushNotificator();

    const data = courses.filter(e => e.id === pageState.id)[0];

    const hasVoluntaryTerms = new Scheduler(schedule).getVoluntaryEntries().length > 0;

    useEffect(() => {
        if(data !== undefined) {
            setTitle(data.title);
            setTrainerId(data.trainer_id);
            setSector(data.sector);
            setPlace(data.place);
            setClassLevels(data.class_levels);
            setSize(data.size);
            setDescription(data.description);
            setSchedule(JSON.parse(data.schedule));
        }
    }, [pageState, courses, data]);

    const saveEdits = async () => {
        let body = {title: title, trainerId: trainerId, sector: sector, place: place, classLevels: classLevels, size: size, description: description, schedule: JSON.stringify(schedule)};
        
        const res = await api.put('courses/' + pageState.id, body);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Der Kurs wurde erfolgreich bearbeitet', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
        
    }


    return (
        <>
        <PageStateReloader />
        {userType === 'other' ? (
            <Card body className="mb-3">
                <Card.Title>Kurs auswählen</Card.Title>
                <CoursesSelect value={pageState.id} setValue={v => setPageState({id: v})} dependencies={[saveListener]}/>
            </Card>
        ) : <></> }
        <Notificator />
        {data === undefined ? <></> : (<>
            <DisplayEditToggleCard cardTitle={`Kurs ${data.title}`} displayComponent={CourseDisplay} editComponent={CourseEdit} saveFun={saveEdits} id={pageState.id} trainerName={`${data.trainer_title} ${data.trainer_name}`} title={title} setTitle={setTitle} trainerId={trainerId} setTrainerId={setTrainerId} sector={sector} setSector={setSector} place={place} setPlace={setPlace} classLevels={classLevels} setClassLevels={setClassLevels} size={size} setSize={setSize} description={description} setDescription={setDescription} schedule={schedule} setSchedule={setSchedule} trainerLink addLinkToReaderPage />
            {userType === 'other' ? (
                <AttendanceCourseView courseId={pageState.id} schedule={schedule} refreshListener={saveListener} studentLinks studentsEditable trainerEditable updateStudentsFromTrainer />
            ) : <></> }
            <Card body className="text-end">
                {hasVoluntaryTerms ? <Link to={{pathname: "/editTermParticipants", state: {id: pageState.id}}} className="me-2"><Button variant="outline-primary">Teilnahme an freiwilligen Terminen berabeiten</Button></Link> : <></>}
                <Link to={{pathname: '/participantsEditor', state: {id: pageState.id}}} className="me-2"><Button>Teilnehmer bearbeiten</Button></Link>
                <DeleteButton userType="course" userId={pageState.id} btnText="Kurs löschen" onDeleted={() => { setPageState({id: ''}); saveTrigger(); }}/>
            </Card>
        </>)}
        </>
    )

}