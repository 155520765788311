import { Form } from "react-bootstrap";

export default function AttendanceTermSelect({ schedule, term, setTerm }) {
    const voluntaryRegularTerms = schedule.filter(s => s.flags.includes('VOLUNTARY') && s.type !== 'once');
    const hasOnceDates = schedule.filter(s => s.type === 'once').length > 0;

    return (
        <Form.Select size="sm" value={term} onChange={e => setTerm(e.target.value)}>
            <option value="all">Alle Termine</option>
            {hasOnceDates ? <option value="once">Alle einmaligen Termine</option> : <></> }
            {voluntaryRegularTerms.map(t => <option value={t.uid} key={t.uid}>Nur: {t.title}</option>)}
        </Form.Select>
    )
}