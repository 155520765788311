import { Fragment, useEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Card, Form, OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import ClassSelect from "../../components/forms/selects/ClassSelect";
import useFetchData from "../../hooks/useFetchData";
import { ExclamationTriangle, QuestionCircle, Search, TrashFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import usePageState from "../../hooks/usePageState";
import utils from "../../service/utils";
import usePushNotificator from "../../hooks/usePushNotificator";
import api from "../../service/api";
import useTrigger from "../../hooks/useTrigger";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import ConfirmModal from "../../components/bt/ConfirmModal";

export default function StudentForwarding(props) {
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {class: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const forwardingInfo = useFetchData('studentForwarding/' + pageState.class, {}, [pageState.class, saveListener]);
    const [ pushNotification, Notificator ] = usePushNotificator();
    const [ targets, setTargets ] = useState([])
    const [ newStudents, setNewStudents ] = useState([])

    const [ selectedCsvFile, setSelectedCsvFile ] = useState()
    const [ isCsvFilePicked, setIsCsvFilePicked ] = useState()

    const [ showResetModal, setShowResetModal ] = useState()

    const readyReqResult = useFetchData('studentForwarding/ready', {}, [ saveListener ]);

    let futureClass = Number(pageState.class.split('/')[0]) + 1;
    futureClass = (futureClass < 10 ? '0' + futureClass.toString() : futureClass.toString()) + "/" + pageState.class.split('/')[1];

    useEffect(() => {
        if(!Array.isArray(forwardingInfo)) {
            setTargets(forwardingInfo.oldStudents.map(student => {return {id: student.id, target: student.forwarding_target}}))
            setNewStudents(forwardingInfo.newStudents.map(student => {return {isLeant: false, ...student}}).concat(forwardingInfo.leanStudents.map(student => {return {isLeant: true, ...student}})))
        }
    }, [forwardingInfo])

    const setAllTargets = (target) => {
        setTargets(targets.map(t => { return {id: t.id, target: target}}));
    }

    const addNewStudent = () => {
        setNewStudents(newStudents.concat([{isLeant: false, prename: '', name: ''}]))
    }

    const saveAll = async () => {
        for(let { target } of targets) {
            if(target !== 'no info' && target !== 'leave' && target !== 'no info' && target.match("[0-9][0-9]/[1-9]")?.length !== 1) {
                pushNotification('Ungültige Eingabe', 'Geben Sie bitte bei allen zukünftigen Klassen eine valide Klasse ein!', 'warning');
                return;
            }
        }


        let body = {
            oldStudents: targets.concat(newStudents.filter(s => s.isLeant).map(s => {return { id: s.id, target: futureClass }})),
            newStudents: newStudents.filter(s => !s.isLeant).map(s => {return { prename: s.prename, name: s.name }})
        };

        const res = await api.put('studentForwarding/' + pageState.class, body);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Die neuen Daten wurden gesichert', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
    }

    const submitCsvFile = async () => {
        setIsCsvFilePicked(false);
        const formData = new FormData();
        formData.append('file', selectedCsvFile);
        const rawRes = await api.send('studentForwarding/uploadCsv', 'PUT', { body: formData }, 'text/csv');
        const res = await rawRes.json();

        if(res.status === 'ok') {
            if(Number(res.processedRows) === 0) {
                pushNotification('Keine passenden Daten', 'Die Datei wurde zwar erfolgreich hochgeladen und behandelt, aber es wurde keine lesbaren Daten gefunden. Das kann daran liegen, dass Schritt 1 und oder 2 nicht richtig durchgeführt wurde. Bitte überprüfen Sie dies!', 'warning');
            } else {
                pushNotification('Hochladen erfolgreich', (
                    <>
                    <p>Die Daten wurden erfolgreich hochgeleden und eingelesen. <Link to={{pathname: "/studentForwardingPreview"}}>Hier</Link> können die zukünftigen Klassen eingelesen werden.</p>
                    Genauere Informationen:
                    <ul>
                        <li>Insgesamt wurden {res.processedRows} Zeilen analysiert</li>
                        <li>{res.successfulMatches} Mal konnte ein Schüler gleichen Names in der Datenbank gefunden werden</li>
                        <li>{res.newStudents} neue Schüler wurden identifiziert</li>
                        <li>In {res.uncertainCases} Fällen war es unklar, welcher Schüler gemeint war. Diese Anfragen wurden ignoriert!</li>
                    </ul>
                    </>
                ), 'success')
            }

            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
    }

    const reset = async () => {
        const res = await api.put('studentForwarding/reset', {});

        if(res.status === 'ok') {
            pushNotification('Zurücksetzen erfolgreich', 'Alle Daten wurden zurückgesetzt', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
    }

    return (<>
        <PageStateReloader />
        <h1>Schuljahreswechsel für Schüler vorbereiten</h1>
        {readyReqResult.ready ? (
            <Alert variant="success">Jeder Schüler hat eine Zukunft! <Link to={{pathname: "/studentForwardingPreview"}}>Hier kann der Schuljahreswechsel durchgeführt werden</Link></Alert>
        ) : <></> }
        {readyReqResult.ready === false ? (
            <Alert variant="secondary">Noch {readyReqResult.unsetStudents} Schüler {readyReqResult.unsetStudents === 1 ? 'hat' : 'haben'} keine Information zugeordnet!</Alert>
        ) : <></> }
        <Notificator />
        <ConfirmModal show={showResetModal} setShow={setShowResetModal} title="Schljahreswechseldaten zurücksetzen" confirmCallback={ () => { reset(); return true; }} confirmBtnVariant="warning" confirmBtnText="Wirklich zurücksetzen">
            Sollen wirklich alle Daten darüber, wohin die Schüler im nächsten Schuljahr gehen gelöscht werden?
        </ConfirmModal>
        
        <Accordion className="mb-3" defaultActiveKey={pageState.class !== '' ? '0' : ''}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Händisch eingeben</Accordion.Header>
                <AccordionBody>
                    <Card body className="mb-3">
                        <Card.Title>Klasse auswählen</Card.Title>
                        <ClassSelect value={pageState.class} setValue={v => setPageState({class: v})} useClassAsValue />
                        {readyReqResult.ready === false ? (
                            <div className="text-end">
                                <Link onClick={() => setPageState({class: readyReqResult.randomClassWithUnsetStudents})}>Gehe zu einer Klasse mit Schülern ohne Infos</Link>
                            </div>
                        ) : <></> }
                    </Card>
                    {targets.length === 0 ? <></> : (
                    <>
                    <Card body className="mb-3">
                        <Card.Title>Derzeitige Schüler</Card.Title>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th rowSpan={2} style={{borderBottomColor: 'black'}}>Name</th>
                                    <th colSpan={4}>Situation nach dem Schuljahreswechsel</th>
                                </tr>
                                <tr>
                                    <th style={{fontWeight: 'unset'}}>Geht in Klasse {futureClass} &nbsp;&nbsp;<Badge pill bg="success" className="hoverable" onClick={() => setAllTargets(futureClass)}>Alle Wählen</Badge></th>
                                    <th style={{fontWeight: 'unset'}}>Geht in andere Klasse</th>
                                    <th style={{fontWeight: 'unset'}}>Verlässt die Schule &nbsp;&nbsp;<Badge pill bg="danger" className="hoverable" onClick={() => setAllTargets('leave')}>Alle Wählen</Badge></th>
                                    <th style={{fontWeight: 'unset'}} className="text-muted">
                                        Ignorieren / Keine Infos&nbsp;&nbsp;
                                        <OverlayTrigger
                                            key='ignore-help'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='ignore-help'>
                                                    Kein Schüler sollte ignoriert werden. Vor dem Schuljahreswechsel wird gewarnt, falls es noch Schüler gibt, die ignoriert werden.
                                                </Tooltip>
                                            }
                                        >
                                            <QuestionCircle size="18" className="hoverable"/>
                                        </OverlayTrigger>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {forwardingInfo.oldStudents.map((student) => {
                                    const target = targets.filter(t => t.id === student.id)[0]?.target;
                                    if (target === undefined) 
                                        return <Fragment key={student.id} />;

                                    const setTarget = (target) => {
                                        setTargets(targets.filter(t => t.id !== student.id).concat([{id: student.id, target: target}]))
                                    }
                                    
                                    return (
                                        <tr key={student.id}>
                                            <td><Link to={{pathname: '/viewStudent', state:{id: student.id}}}>{student.name}, {student.prename}</Link></td>
                                            <td className="text-center"><Form.Check checked={target === futureClass} onChange={() => setTarget(futureClass)}/></td>
                                            <td><Form.Control size="sm" placeholder="zB 07/2" value={target === futureClass || target === 'leave' || target === 'no info' ? '' : target} onChange={e => setTarget(e.target.value)}/></td>
                                            <td className="text-center"><Form.Check checked={target === 'leave'} onChange={() => setTarget('leave')}/></td>
                                            <td className="text-center"><Form.Check checked={target === 'no info'} onChange={() => setTarget('no info')}/></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Card.Title>Neue Schüler der zukünftigen {futureClass}</Card.Title>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th style={{width: 50}}></th>
                                    <th>Vorname</th>
                                    <th>Nachname</th>
                                    <th></th>
                                    <th style={{width: 50}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {newStudents.map((student, i) => student.isLeant ? (
                                    <LeantStudentRow
                                        key={i}
                                        student={student}
                                        deleteFun={() => setNewStudents(utils.removeElementFromArray(newStudents, i))}

                                    />
                                ) : (
                                    <NewStudentRow
                                        key={i}
                                        index={i}
                                        crntClass={pageState.class}
                                        name={student.name} setName={v => setNewStudents(utils.replaceElementInArray(newStudents, i, {...student, name: v}))}
                                        prename={student.prename} setPrename={v => setNewStudents(utils.replaceElementInArray(newStudents, i, {...student, prename: v}))}
                                        convertToLeant={(id, name, prename, _class) => setNewStudents(utils.replaceElementInArray(newStudents, i, {isLeant: true, id, name, prename, class: _class}))}
                                        deleteFun={() => setNewStudents(utils.removeElementFromArray(newStudents, i))}
                                    />
                                ))}
                                {newStudents.length === 0 ? (
                                    <tr>
                                        <td colSpan={5} className="text-muted text-center">--- keine Zugänge bekannt ---</td>
                                    </tr>
                                ) : <></>}
                            </tbody>
                        </Table>
                        <div className="text-end">
                            <Button size="sm" variant="success" onClick={addNewStudent}>+</Button>
                        </div>
                    </Card>
                    <Card body className="text-end">
                        <Button variant="success" onClick={saveAll}>Speichern</Button>
                    </Card>
                    </>
                    )}
                </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <AccordionHeader>Per Excel-Tabelle generieren</AccordionHeader>
                <AccordionBody>
                    <h4>Vorgehensweise</h4>
                    <ol>
                        <li>Erstellen Sie eine Excel-Datei, indem alle Schüler untereinander stehen. In der 1. Spalte stehen die <strong>Nachnamen</strong> in der 2. die <strong>Vornamen</strong> und in der 3. Spalte die <strong>Klassen</strong></li>
                        <li>Drücken Sie auf <strong>Speichern unter</strong>. Und wählen Sie als Dateityp: <strong>CSV UTF-8 (durch Trennzeichen getrennt)</strong>. (Es gibt 2 verschiedene CSV-Optionen)</li>
                        <li>
                            <p>Laden Sie die Datei hier hoch</p>
                            <Form.Control type="file" size="sm" accept=".csv" onChange={e => { setSelectedCsvFile(e.target.files[0]); setIsCsvFilePicked(true) }} />
                        </li>
                    </ol>
                    <div className="text-end"><Button variant="success" onClick={submitCsvFile} disabled={!isCsvFilePicked}>Abschicken</Button></div>
                </AccordionBody>
            </Accordion.Item>
        </Accordion>

        <Card body className="mb-3 text-end">
            <Button variant="warning" onClick={() => setShowResetModal(true)}>Zurücksetzen</Button>
        </Card>
            
        
    </>);
}

function NewStudentRow({ index, crntClass, prename, setPrename, name, setName, convertToLeant, deleteFun }) {
    const allStudents = useFetchData('students')
    const [ similarStudents, setSimilarStudents ] = useState([])
    const [ foundTooManySimilarStudents, setFoundTooManySimilarStudents] = useState(false)
    const [ foundExactStudent, setFoundExactStudent ] = useState(false)
    const MAX_RESULTS = 10;

    useEffect(() => {
        const similarStudentsTemp = allStudents.filter(function(student) {
            if(this.count <= MAX_RESULTS) {
                if(student.prename.toUpperCase().includes(prename.toUpperCase()) && student.name.toUpperCase().includes(name.toUpperCase()) && student.class !== crntClass) {
                    this.count++;
                    return true;
                }
            }
            return false;
        }, { count: 0 });
        
        setFoundExactStudent(false)
        for(let student of similarStudentsTemp) {
            if(student.prename.toUpperCase() === prename.toUpperCase() && student.name.toUpperCase() === name.toUpperCase())
                setFoundExactStudent(true)
        }

        if(similarStudentsTemp.length > MAX_RESULTS) {
            setFoundTooManySimilarStudents(true)
            similarStudentsTemp.pop()
        } else {
            setFoundTooManySimilarStudents(false)
        }
        setSimilarStudents(similarStudentsTemp)
    }, [name, prename, crntClass, allStudents, setFoundTooManySimilarStudents])

    return (
        <tr>
            <td>
                
                <OverlayTrigger
                    trigger="click"
                    key={index}
                    placement='top'
                    overlay={
                        <Popover id={`serachbox-${index}`}>
                            <Popover.Header as="h3">{foundExactStudent ? 'Gleicher Schüler auf der Schule' : 'Ähnliche Schüler auf der Schule'}</Popover.Header>
                            <Popover.Body>
                                {similarStudents.map((student, i) => (
                                    <Fragment key={i}><Badge pill className="hoverable" onClick={() => convertToLeant(student.id, student.name, student.prename, student.class)}>{student.prename} {student.name} {student.class}</Badge> &nbsp;</Fragment>
                                ))}
                                {foundTooManySimilarStudents ? <>...</> : <></>}
                                {similarStudents.length === 0 ? <>Keine gefunden</> : <></>}
                            </Popover.Body>
                        </Popover>
                    }
                    >
                        {name.length <= 2 && prename.length <= 2 ? <></> : ( 
                            foundExactStudent ? (
                                <Button variant="warning" size="sm"><ExclamationTriangle className="mb-1"/></Button>
                            ) : (
                                <Button variant="outline-info" size="sm"><Search className="mb-1"/></Button>
                            ))
                            
                        }
                </OverlayTrigger>
            </td>
            <td><Form.Control size="sm" value={prename} onChange={e => setPrename(e.target.value)} /></td>
            <td><Form.Control size="sm" value={name} onChange={e => setName(e.target.value)}/></td>
            <td><i>Neuzugang</i></td>
            <td className="text-end">
                <Button variant="outline-danger" size="sm" onClick={deleteFun}><TrashFill className="mb-1" /></Button>
            </td>
        </tr>
    )
}

function LeantStudentRow({ student, deleteFun }) {
    return (
        <tr>
            <td></td>
            <td><Form.Control size="sm" value={student.prename} disabled /></td>
            <td><Form.Control size="sm" value={student.name} disabled /></td>
            <td><i>von Klasse {student.class}</i></td>
            <td className="text-end">
                <Button variant="outline-danger" size="sm" onClick={deleteFun}><TrashFill className="mb-1" /></Button>
            </td>
        </tr>
    )
}