import { useState } from "react";
import { Badge, Button, Col, Form, FormControl, InputGroup, ListGroup, Modal, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import { TARGET_GROUP_VARIABLES } from "../../../pages/other/pdfGenerators/CustomPdfTemplate";
import utils from "../../../service/utils";
import FormRow from "../../bt/FormRow";
import MarkdownEditor from "../../markdown/MarkdownEditor";

export default function CustomPdfTemplateEdit({ title, setTitle, targetGroup, setTargetGroup, text, setText, variables, setVariables }) {
    

    return (<>
        <FormRow label="Titel">
            <Form.Control value={title} onChange={e => setTitle(e.target.value)} />
        </FormRow>
        <FormRow label="Generieren">
            <Form.Select value={targetGroup} onChange={e => setTargetGroup(e.target.value)}>
                <option value="none">Einmalig</option>
                <option value="student">Für jeden Schüler</option>
                <option value="student_in_course">Für jeden Schüler, der min. einen Kurs belegt</option>
                <option value="trainer">Für jeden Leiter</option>
            </Form.Select>
        </FormRow>
        <FormRow label="Variablen">
            <VariablesEditor
                defaultVariables={TARGET_GROUP_VARIABLES[targetGroup]}
                variables={variables} setVariables={setVariables}
            />
        </FormRow>
        <FormRow label="Text">
            <MarkdownEditor 
                text={text} setText={setText}
            />
        </FormRow>
    </>);
}

function VariablesEditor({ defaultVariables, variables, setVariables }) {
    const [ showHelp, setShowHelp ] = useState(false)

    const setVariable = (v, i) => setVariables(utils.replaceElementInArray(variables, i, v))

    return (<>
        <VariableHelpModal show={showHelp} setShow={setShowHelp} />
        <ListGroup>
            { defaultVariables === undefined ? <></> : Object.keys(defaultVariables).map((v, i) => (
                <ListGroup.Item key={'d' + i}>
                    <Row>
                        <Col sm="5">
                            <InputGroup size="sm">
                                <InputGroup.Text>$$</InputGroup.Text>
                                <FormControl value={v} disabled/>
                                <InputGroup.Text>$$</InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col sm="5">
                            <FormControl size="sm" value={defaultVariables[v]} disabled />
                        </Col>
                    </Row>
                </ListGroup.Item>
            )) }
            { variables.map((v, i) => (
                <ListGroup.Item key={i}>
                    <Row>
                        <Col sm="5">
                            <InputGroup size="sm">
                                <InputGroup.Text>$$</InputGroup.Text>
                                <FormControl value={v.name} onChange={e => setVariable({...v, name: e.target.value}, i)} />
                                <InputGroup.Text>$$</InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col sm="5">
                            <FormControl size="sm" value={v.displayName} onChange={e => setVariable({...v, displayName: e.target.value}, i)} />
                        </Col>
                        <Col className="text-end">
                            <Button variant="outline-danger" size="sm" onClick={() => setVariables(utils.removeElementFromArray(variables, i))}><TrashFill className="mb-1" /></Button>
                        </Col>
                    </Row>
                </ListGroup.Item>
            ))}
            <ListGroup.Item className="text-end">
                <Button variant="success" size="sm" onClick={() => setVariables([...variables, {name: '', displayName: ''}])}><strong>+</strong></Button>
            </ListGroup.Item>
        </ListGroup>
        <div className="text-end">
            <Badge pill bg="secondary" className="disable-text-select hoverable" onClick={() => setShowHelp(true)}>Hilfe</Badge>
        </div>
    </>)
}

function VariableHelpModal({ show, setShow }) {
    return (
        <Modal show={show} size='lg' onHide={() => setShow(false)} >
            <Modal.Header>
                <Modal.Title>Hilfe zu PDF-Vorlagen-Variablen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Variablen sind bestimmte Zeichenketten, die beim Generieren des PDFs stets mit ihrem Wert überschrieben bzw. ersetzt werden. Dies kann durch zwei Mechanismen entstehen:</p>
                <ul>
                    <li>
                        <p>Die Werte werden direkt vor dem Generieren durch den Administrator festgelegt. Sollte das PDF für mehrere Benutzer generiert werden, wird dieser Wert global für alle Benutzer ersetzt.</p>
                        <p>Ein Beispiel hierfür wäre der Zeitpunkt der Kurswahl in einem Brief an die Schüler.</p>
                        <p>Diese Variablen können selbst angelegt werden. Der linke Wert zwischen den doppelten Dollar-Zeichen, gibt die Zeichenkette an, die bei der Generierung ersetzt wird. Genau diese sollte im Text verwendet werden. Der rechte Wert gibt den Namen an, der im Formular vor der generierung mit der Variable assoziiert wird.</p>
                        <p>In unserem Beispiel könnte man <i>$$ZEIT_KURSWAHL$$</i> und <i>Zeitpunkt der Kurswahl</i> als Werte wählen.</p>
                    </li>
                    <li>
                        <p>Sollte das Dokument für mehrere Benutzer erstellt werden, können benutzer-abhängige Daten, wie Name oder belegte Kurse, als Variablen verwendet werden.</p>
                        <p>Um diese Werte zu verwenden, schreibt man wie üblich in den Text zum Beispiel <i>Hallo $$VORNME$$ $$NACHNAME$$,</i> um eine schüler-spezifische Anrede zu erreichen.</p>
                        <p>Diese Variablen könnnen nicht selbst angelegt oder bearbeitet werden.</p>
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    )
}