import { Fragment, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import FormRow from "../../../components/bt/FormRow";
import MarkdownRenderer from "../../../components/markdown/MarkdownRenderer";

export const TARGET_GROUP_VARIABLES = {
    none: {},
    student: {
        VORNAME: 'Vorname',
        NAME: 'Nachname',
        KLASSE: 'Klasse',
        PASSWORT: 'Passwort',
        INITIALIEN: 'Initialien',
    },
    student_in_course: {
        VORNAME: 'Vorname',
        NAME: 'Nachname',
        KLASSE: 'Klasse',
        KURSE: 'belegte Kurse',
        PASSWORT: 'Passwort',
        INITIALIEN: 'Initialien'
    },
    trainer: {
        NAME: 'Name',
        KURSE: 'leitende Kurse'
    }
}

function replaceVariables(text, variables, targetGroup) {
    if(targetGroup in TARGET_GROUP_VARIABLES) {
        for(let [ name, value ] of Object.entries(TARGET_GROUP_VARIABLES[targetGroup])) {
            text = text.replaceAll(`$$${name}$$`, `*${value}*`)
        }
    }
    for(let name in variables) {
        if(variables[name] !== '')
            text = text.replaceAll(`$$${name}$$`, variables[name])
    }
    return text
}


export default function CustomPdfTemplate({ targetGroup, text, variables, variableValues, setVariableValues}) {
    useEffect(() => {
        if(targetGroup in TARGET_GROUP_VARIABLES) {
            let variableValues = {}
            for(let v of variables) {
                variableValues[v.name] = ''
            }
            setVariableValues(variableValues)
        }
    }, [ targetGroup, variables, setVariableValues ])

    let introText = "";
    switch(targetGroup) {
        case 'none': introText = "Das Dokument wird nicht Benutzer-spezifisch generiert."; break;
        case 'student': introText = "Für jeden Schüler wird ein Dokument erstellt."; break;
        case 'trainer': introText = "Für jeden Kursleiter wird ein Dokument erstellt."; break;
        default:
    }
    
    return (<>
        <p>{introText}</p>
        {variables?.map((v, i) => {
            if(!(v.name in variableValues))
                return <Fragment key={i}></Fragment>;

            return (
                <FormRow key={i} label={v.displayName}>
                    <Form.Control value={variableValues[v.name].value} onChange={e => setVariableValues({...variableValues, [v.name]: e.target.value}) }/>
                </FormRow>
            )
        })}
        <Card>
            <Card.Header>
                Vorschau
            </Card.Header>
            <Card.Body>
                <MarkdownRenderer>
                    {replaceVariables(text, variableValues, targetGroup)}
                </MarkdownRenderer>
            </Card.Body>
        </Card>
    </>);
}