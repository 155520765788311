import useFetchData from "../../hooks/useFetchData"
import { Accordion, Button, Card, Form } from 'react-bootstrap'
import FormRow from "../../components/bt/FormRow";
import ConfirmModal from "../../components/bt/ConfirmModal";
import { useState } from "react";
import api from "../../service/api";
import useTrigger from "../../hooks/useTrigger";
import usePushNotificator from "../../hooks/usePushNotificator";

const download = require("downloadjs");

const backupFilenameToGermanDate = (filename) => {
    return filename.substr(6, 2) + "." + filename.substr(4, 2) + "." + filename.substr(0, 4);
}

const backupFilenameToGermanTime = (filename) => {
    return filename.substr(8, 2) + ":" + filename.substr(10, 2) + ":" + filename.substr(12, 2);
}

export default function Backups() {
    const [ saveTrigger, saveListener ] = useTrigger();
    const [ pushNotification, Notificator ] = usePushNotificator();

    const backups = useFetchData("backups", {}, [ saveListener ]);

    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ backupToBeDeleted, setBackupToBeDeleted ] = useState("");

    const [ description, setDescription ] = useState("");

    const createBackup = async () => {
        if(description === "") {
            pushNotification("Beschreibung fehlt", "Bitte Beschreiben warum und warum jetzt Sie das Backup machen!", "warning");
            return;
        }
        const res = await api.post('backups', { description: description });
        if(res.status === 'ok') {
            pushNotification('Backup erstellen erfolgreich', 'Das Backup wurde erfolgreich erstellt', 'success');
            setDescription("");
            saveTrigger();
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
        }
        saveTrigger();
    }

    const downloadBackup = (backup) => {
        api.send("backups/download/" + backup.filename.substring(0, backup.filename.length - 4), 'GET', {}, "text/plain")
            .then(res => res.blob())
            .then(blob => download(blob, backup.filename));
    }

    const deleteBackup = async () => {
        const res = await api.delete('backups/' + backupToBeDeleted.filename.substring(0, backupToBeDeleted.filename.length - 4));
        if(res.status === 'ok') {
            pushNotification('Löschen erfolgreich', 'Das Backup wurde erfolgreich gelöscht', 'success');
            saveTrigger();
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
        }
        return true;
    }

    return (<>
        <Notificator />
        <ConfirmModal title="Backup löschen" confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={deleteBackup} cancelBtnText="Abbrechen" show={showDeleteModal} setShow={setShowDeleteModal}>
            Möchten Sie wirklich das Backup <i>{backupToBeDeleted.filename}</i> löschen?
        </ConfirmModal>
        <Card body className="mb-3">
            <Card.Title>Gespeicherte Backups</Card.Title>
            {backups.length ? (
                <Accordion>
                    {backups.map((backup, i) => (
                        <Accordion.Item eventKey={i} key={i}>
                            <Accordion.Header>
                                {backup.automaticallyGenerated ? "Automatisches" : "Manuelles"} Backup vom {backupFilenameToGermanDate(backup.filename)}
                            </Accordion.Header>
                            <Accordion.Body>
                                <FormRow label="Beschreibung" plaintext>
                                    {backup.description.length ? backup.description : (
                                        <span className="text-muted">--- keine Beschreibung hinterlegt ---</span>
                                    )}
                                </FormRow>
                                <FormRow label="Erstellt" plaintext>
                                    {backupFilenameToGermanDate(backup.filename)} um {backupFilenameToGermanTime(backup.filename)}
                                </FormRow>
                                <FormRow label="Dateiname" plaintext>
                                    {backup.filename}
                                </FormRow>
                                <div className="text-end">
                                    <Button variant="outline-primary" className="me-2" onClick={() => downloadBackup(backup)}>Herunterladen</Button>
                                    <Button variant="danger" onClick={() => {setBackupToBeDeleted(backup); setShowDeleteModal(true);}}>Löschen</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            ) : (
                <div className="text-muted text-center">--- keine Backups gespeichert ---</div>
            )}
        </Card>
        <Card body>
            <Card.Title>Backup manuell erstellen</Card.Title>
            <Form.Control as="textarea" placeholder="Beschreibung des Backups (Warum, warum jetzt?)" className="mb-2" value={description} onChange={e => setDescription(e.target.value)}/>
            <div className="text-end"><Button variant="success" onClick={createBackup}>Erstellen</Button></div>
        </Card>
    </>)
}