import Login from "../pages/Login";
import Dashboard from "../pages/other/Dashboard";
import StudentLandingPage from "../pages/student/StudentLandingPage";
import ClassOverview from "../pages/teacher/ClassOverview";
import CourseOverview from "../pages/trainer/CourseOverview";
import api from "../service/api";

export default function SpreadRootByUserType() {
    if(!api.isLoggedIn())
        return <Login />

    const userType = api.getUserType();

    if(userType === 'student') {
        return <StudentLandingPage />;
    } else if(userType === 'teacher') {
        return <ClassOverview />;
    } else if(userType === 'trainer') {
        return <CourseOverview />;
    } else {
        return <Dashboard />
    }
}