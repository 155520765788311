import { Form } from "react-bootstrap";
import useFetchData from "../../../hooks/useFetchData"
import FloatingSelect from '../../bt/FloatingSelect'

export default function ClassSelect({ defaultFirst, floating, showTeacherNames, value, setValue, dependencies, useClassAsValue, ...rest }) {
    
    const classes = useFetchData('teachers', {}, dependencies, first => {if(defaultFirst) setValue(useClassAsValue ? first.class : first.id)})
    const SelectInUse = floating ? FloatingSelect : Form.Select;

    return (
        <SelectInUse value={value} onChange={e => setValue(e.target.value)} {...rest} label="Klasse">
            {defaultFirst ? <></> : <option disabled="disabled" value=''>Klasse auswählen</option>}
            {classes.map(c => <option value={useClassAsValue ? c.class : c.id} key={c.id}>{c.class}{showTeacherNames ? ' (' + c.title + ' ' + c.name + ')' : ''}</option>)}
        </SelectInUse>
    )
}

ClassSelect.defaultProps = {
    defaultFirst: false,
    floating: false,
    showTeacherNames: true,
    dependencies: [],
    useClassAsValue: false
}