import { Link } from "react-router-dom";
import utils from "../../../service/utils";

export default function DisplayCoursesInSentence({ courses, links, linkPath, noCourseMsg }) {
    if(courses === undefined || courses.length === 0)
        return  <span className="text-muted">--- {noCourseMsg} ---</span>;

    let parsedCourses = courses.map(c => {
        if(c === undefined || c === [])
            return undefined;
        
        if(links) {
            return <Link to={{pathname: linkPath, state: {id: c.id}}}>{c.title}</Link>
        } else {
            return <>{c.title}</>;
        }
    });

    return utils.jsxGermanImplode(parsedCourses.filter(c => c !== undefined));

}

DisplayCoursesInSentence.defaultProps = {
    courses: [],
    links: false,
    noCourseMsg: 'belegt zurzeit keine Kurse',
    linkPath: '/viewCourse'
}