import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ConfirmModal from "../../components/bt/ConfirmModal";
import EvaluationDisplay from "../../components/evaluation/EvaluationDisplay";
import EvaluationEdit from "../../components/evaluation/EvaluationEdit";
import DisplayEditToggleCard from "../../components/forms/DisplayEditToggleCard";
import EvaluationSelect from "../../components/forms/selects/EvaluationSelect";
import useFetchData from "../../hooks/useFetchData";
import usePageState from "../../hooks/usePageState";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";

export default function ViewEvaluation(props) {
    const [ pageState, setPageState, PageStateReloader ] = usePageState(props, {id: ''});
    const [ saveTrigger, saveListener ] = useTrigger();
    const evaluations = useFetchData('evaluations', {}, [saveListener]);

    const [ pushNotification, Notificator ] = usePushNotificator();

    const [ title, setTitle ] = useState('')
    const [ targetGroup, setTargetGroup ] = useState('')
    const [ periodStart, setPeriodStart ] = useState('')
    const [ periodEnd, setPeriodEnd ]     = useState('')
    const [ data, setData ] = useState([])
    const [ shouldShowPeriodInputInEdit, setShouldShowPeriodInputInEdit ] = useState(false)

    const [ showDeleteModal, setShowDeleteModal ] = useState(false)
    const [ showClearAnswersModal, setShowClearAnswersModal ] = useState(false)

    const currentEval = evaluations.filter(e => e.id === pageState.id)[0];

    useEffect(() => {
        if(currentEval !== undefined) {
            setTitle(currentEval.title)
            setTargetGroup(currentEval.target_group)
            setPeriodStart(currentEval.period_start ? currentEval.period_start : '')
            setPeriodEnd(currentEval.period_end ? currentEval.period_end : '')
            setData(JSON.parse(currentEval.data))

            setShouldShowPeriodInputInEdit(currentEval.period_start ? true : false)
        }
    }, [pageState, evaluations, currentEval])

    const saveEdits = async () => {
        const res = await api.put('evaluations/' + pageState.id, { title: title, targetGroup: targetGroup, periodStart: periodStart, periodEnd: periodEnd, data: JSON.stringify(data) });

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Die Evaluation wurde erfolgreich bearbeitet', 'success');
            saveTrigger();
            return true;
        } else {
            pushNotification('Interner Fehler', res.message, 'danger');
            return false;
        }
        
    }

    const deleteEval = async () => {
        const res = await api.delete(`evaluations/${pageState.id}`);

        if(res.status === 'ok') {
            setPageState({id: ''});
            saveTrigger();
        }
    }

    const clearAnswers = async () => {
        const res = await api.delete(`evaluations/${pageState.id}/answers`);

        if(res.status === 'ok') {
            pushNotification('Bearbeitung erfolgreich', 'Löschung der Antworten erfolgreich', 'success');
            saveTrigger();
        }
    }

    return (
        <>
        <PageStateReloader />
        <ConfirmModal title="Evaluation löschen" confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={deleteEval} cancelBtnText="Abbrechen" show={showDeleteModal} setShow={setShowDeleteModal}>
            Möchten Sie die Evaluation wirklich löschen?
        </ConfirmModal>
        <ConfirmModal title="Gegebene Antworten löschen" confirmBtnVariant="danger" confirmBtnText="Wirklich Löschen" confirmCallback={clearAnswers} cancelBtnText="Abbrechen" show={showClearAnswersModal} setShow={setShowClearAnswersModal}>
            Möchten Sie wirklich alle {currentEval?.count_answers} gegeben Antworten löschen?
        </ConfirmModal>
        <Card body className="mb-3">
            <Card.Title>Evaluationen</Card.Title>
            <EvaluationSelect value={pageState.id} setValue={v => setPageState({id: v})} dependencies={[saveListener]} className="mb-3"/>
            <div className="text-end">
                <Link to="/newEvaluation"><Button variant="success">Neue Evaluation erstellen</Button></Link>
            </div>
        </Card>
        <Notificator />
        {currentEval === undefined ? <></> : (
            <>
            <Card body className="text-end mb-3">
                {currentEval.target_group === 'student' ? (
                        <>
                            <Link to={{pathname: "/evaluationQuestionnaire", state:{id: pageState.id, courses: [{id: -1, title: 'Dummy Kurs 1', trainer_title: 'Herr', trainer_name: 'Mustermann'}, {id: -2, title: 'Dummy Kurs 2', trainer_title: 'Frau', trainer_name: 'Musterfrau'}]}}}><Button variant="outline-primary" className="me-2">Vorschau als Kursteilnehmer</Button></Link>
                            <Link to={{pathname: "/evaluationQuestionnaire", state:{id: pageState.id, courses: []}}}><Button variant="outline-primary" className="me-2">Vorschau als Kursloser</Button></Link>
                        </>
                    ) : (
                        <>
                        <Link to={{pathname: "/evaluationQuestionnaire", state:{id: pageState.id, courses: [{id: -1, title: 'Dummy Kurs 1'}, {id: -2, title: 'Dummy Kurs 2'}]}}}><Button variant="outline-primary" className="me-2">Vorschau als Kursleiter</Button></Link>
                        <Link to={{pathname: "/evaluationQuestionnaire", state:{id: pageState.id, courses: []}}}><Button variant="outline-primary" className="me-2">Vorschau als Leiter ohne Kurs</Button></Link>
                        </>
                    )}
                <Link to={{pathname: "/generatePdf", state:{id: 'analyzeEvaluation', evalId: pageState.id}}}><Button variant="info">Auswerten</Button></Link>
            </Card>
            <DisplayEditToggleCard
                displayComponent={EvaluationDisplay}
                editComponent={EvaluationEdit}
                cardTitle={"Evaluation: " + title}
                saveFun={saveEdits}

                title={title} setTitle={setTitle}
                targetGroup={targetGroup} setTargetGroup={setTargetGroup}
                periodStart={periodStart} setPeriodStart={setPeriodStart}
                periodEnd={periodEnd} setPeriodEnd={setPeriodEnd}
                data={data} setData={setData}

                countAnswers={currentEval.count_answers}
                countParticipants={currentEval.count_participants}

                shouldShowPeriodInput={shouldShowPeriodInputInEdit}
            />
            <Card body className="text-end">
                <Button variant="outline-danger" className="me-2" onClick={() => setShowClearAnswersModal(true)} disabled={Number(currentEval?.count_answers) === 0} >Gegebene Antworten löschen</Button>
                <Button variant="danger" onClick={() => setShowDeleteModal(true)}>Evaluation löschen</Button>
            </Card>
            </>
        )}
        </>
    )
}