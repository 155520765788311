import { useState } from "react";
import { Redirect } from "react-router-dom";
import utils from "../service/utils";

export default function usePageState(props, defaultProps = {}) {
    const initialState = {...defaultProps, ...props.location.state};
    const [ state, setState ] = useState(initialState);
    const PageStateReloader = () => utils.areObjectsEqual(state, initialState) ? <></> : <Redirect to={{...props.location, state: state}} />;

    return [state, setState, PageStateReloader];
}