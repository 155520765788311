import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import EvaluationEdit from "../../components/evaluation/EvaluationEdit";
import usePushNotificator from "../../hooks/usePushNotificator";
import api from "../../service/api";

export default function NewEvaluation() {
    const [ title, setTitle ] = useState("")
    const [ targetGroup, setTargetGroup ] = useState("student")
    const [ periodStart, setPeriodStart ] = useState('')
    const [ periodEnd, setPeriodEnd ]     = useState('')
    const [ data, setData ] = useState([])

    const [ pushNotification, Notificator ] = usePushNotificator();

    const handleSubmit = async e => {
        e.preventDefault();
        const res = await api.post('evaluations', { title: title, targetGroup: targetGroup, periodStart: periodStart, periodEnd: periodEnd, data: JSON.stringify(data) });

        if(res.status === 'ok') {
            setTitle('');
            setTargetGroup('student');
            setData([]);

            pushNotification('Bearbeitung erfolgreich', 'Evaluation erfolgreich erstellt', 'success');
        } else {
            pushNotification("Interner Fehler", res.error, 'danger');
        }
            
    }


    return (
        <>
        <Notificator />
        <Card body>
            <Card.Title>Neue Evaluation erstellen</Card.Title>
            <hr />
            <Form onSubmit={handleSubmit}>
                <EvaluationEdit 
                    title={title} setTitle={setTitle}
                    targetGroup={targetGroup} setTargetGroup={setTargetGroup}
                    data={data} setData={setData}
                    periodStart={periodStart} setPeriodStart={setPeriodStart}
                    periodEnd={periodEnd} setPeriodEnd={setPeriodEnd}

                    shouldShowPeriodInput={false}
                />
                <div className="text-end">
                    <Link to="/viewEvaluation"><Button variant="secondary" className="me-2">Zurück</Button></Link>
                    <Button type="submit" variant="success">Erstellen</Button>
                </div>
            </Form>
        </Card>
        </>
    )
}