import { useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";

export default function DisplayEditToggleCard({ displayComponent: DisplayComponent, editComponent: EditComponent, cardTitle, saveFun, suppressButtonSaveBtn, ...rest}) {
    const [ inEditMode, setInEditMode ] = useState(false);

    const handleSave = async () => {
        const res = await saveFun();
        if(res) setInEditMode(false);
    }

    let toggleButton, form;
    if(inEditMode) {
        toggleButton = <Button size="sm" onClick={handleSave} variant="success">Änderungen Speichern</Button>;
        form = (<>
            <EditComponent {...rest} />
            {suppressButtonSaveBtn ? <></> : (<>
                <hr />
                <div className="text-end">
                    <Button size="sm" onClick={handleSave} variant="success">Änderungen Speichern</Button>
                </div>
            </>)}
        </>)
    } else {
        toggleButton = <Button size="sm" onClick={() => setInEditMode(true)}>Bearbeiten</Button>;
        form = <DisplayComponent {...rest} />
            
    }

    return (
        <Card body className="mb-3">
            <Row>
                <Col>
                    <Card.Title>{cardTitle}</Card.Title>
                </Col>
                <Col className="text-end">
                    {toggleButton}
                </Col>
            </Row>
            <hr />
            {form}
        </Card>
    )
}

DisplayEditToggleCard.defaultProps = {
    suppressButtonSaveBtn: false
}