import { Fragment, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReaderComponent from "../reader/ReaderComponent";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ArrowDownCircle, ArrowUpCircle, List, PinAngle, TrashFill } from 'react-bootstrap-icons'
import useFetchData from "../../hooks/useFetchData";
import useLoginToken from "../../hooks/useLoginToken";
import ConfirmModal from '../../components/bt/ConfirmModal';
import FormRow from "../../components/bt/FormRow";
import utils from "../../service/utils";
import { useHistory } from "react-router-dom";
import api from "../../service/api";

export default function CourseSelection() {
    const [ restrictToClass, setRestrictToClass ] = useState('no')
    const [ allocatedCourses, setAllocatedCourses ] = useState([]) // courses the student is allready allocated to, so he cannot vote for them
    const [ priorityCourses, setPriorityCourses ] = useState([]) // courses the student visited last year, so if he wants to continue visiting them, he has a privilege to do so
    const [ selectedCourse, setSelectedCourse ] = useState() // the course that is selected in the reader component
    const [ likedCourses, setLikedCourses ] = useState([]) // the courses the student voted for
    const [ numLikedPriorityCourses, setNumLikedPriorityCourses ] = useState(0)
    const [ selectedMaxAllocatedCourses, setSelectedMaxAllocatedCourses ] = useState(1)

    const [ showIdentityCreator, setShowIdentityCreator ] = useState(false)
    const [ showThankYou, setShowThankYou ] = useState(false)

    const [ { userData, userType }, ] = useLoginToken();
    const studentInfo = useFetchData(`courseSelection/studentInfo/${userData.id}`);
    
    const history = useHistory();

    const MAX_LIKED_COURSES = 4;
    const MAX_ALLOCATED_COURSES = 3;
    
    const isSelectedCourseAllocatedToStudent = allocatedCourses?.indexOf(Number(selectedCourse?.id)) !== -1
    const isSelectedCourseAllreadyLiked = likedCourses.filter(c => Number(c.id) === Number(selectedCourse?.id)).length > 0
    const isSelectedCoursePriority = priorityCourses?.indexOf(Number(selectedCourse?.id)) !== -1

    useEffect(() => {
        if(userType === 'student') {
            if(!Array.isArray(studentInfo)) {
                if('error' in studentInfo) {
                    history.push("/unauthorized");
                } else {
                    setAllocatedCourses(studentInfo.allocatedCourses.map(c => Number(c)));
                    setPriorityCourses(studentInfo.priorityCourses.map(c => Number(c)));
                    setLikedCourses(studentInfo.selectedCourses);
                    setSelectedMaxAllocatedCourses(studentInfo.maxAllocations);
                    setNumLikedPriorityCourses(studentInfo.selectedCourses.filter(c => Number(c.priority) < 1).length);
                }
            }
        }
    }, [ studentInfo, userType, history ])

    useEffect(() => {
        if(userType === 'other') {
            setShowIdentityCreator(true)
        } else if(userType === 'student') {
            setRestrictToClass(userData.class.split('/')[0])
        }
    }, [ userType, userData.class ])

    const addLikedCourse = () => {
        if(likedCourses.length < MAX_LIKED_COURSES) {
            if(isSelectedCoursePriority) {
                setLikedCourses([ selectedCourse, ...likedCourses ])
                setNumLikedPriorityCourses(numLikedPriorityCourses + 1)
            } else {
                setLikedCourses([ ...likedCourses, selectedCourse ])
            }
        }
    }

    const removeLikedCourse = (index) => {
        setLikedCourses(utils.removeElementFromArray(likedCourses, index))
        if(index < numLikedPriorityCourses)
            setNumLikedPriorityCourses(numLikedPriorityCourses - 1)
    }

    const onDragEnd = (param) => {
        const srcIndex = param.source.index;
        const desIndex = param.destination?.index;

        if(desIndex !== undefined)
            likedCourses.splice(desIndex, 0, likedCourses.splice(srcIndex, 1)[0]);
    }

    const submit = async () => {
        if(userType === 'student') {
            let selectedCourses = []
            for(let i in likedCourses) {
                selectedCourses.push({
                    id: likedCourses[i].id,
                    priority: Number(i)+1-numLikedPriorityCourses
                })
            }
            const body = { selectedCourses: selectedCourses, maxAllocations: selectedMaxAllocatedCourses };

            const res = await api.post('courseSelection/submit', body);
            if(res.status === 'ok') {
                setShowThankYou(true)
            }
        }
    }

    
    return (
        <>
        <h1>Kurswahl</h1>
        <IdentityCreatorModal 
            show={showIdentityCreator} setShow={setShowIdentityCreator} 
            allocatedCourses={allocatedCourses} setAllocatedCourses={setAllocatedCourses}
            priorityCourses={priorityCourses} setPriorityCourses={setPriorityCourses}
            restrictToClass={restrictToClass} setRestrictToClass={setRestrictToClass} 
        />
        <ConfirmModal title="Daten sind eingegangen" closeButton={false} show={showThankYou} setShow={setShowThankYou} hideCancelBtn confirmCallback={() => { history.push('/'); return true; }}>
            Vielen Dank, dass du bei der Kurswahl teilgenommen hast. Deine gewählten Kurse sind eingegangen. Du kann diese innerhalb des Kurswahlzeitraums jederzeit ändern.
        </ConfirmModal>
        <ReaderComponent setSelectedCourse={setSelectedCourse} restrictToClass={restrictToClass} />
        <div className="mt-1">
            <Button disabled={likedCourses.length >= MAX_LIKED_COURSES || isSelectedCourseAllreadyLiked || isSelectedCourseAllocatedToStudent} onClick={addLikedCourse} variant='success' className="me-2">
                {isSelectedCourseAllreadyLiked ? 'Dieser Kurs ist bereits hinzugefügt' :
                    isSelectedCourseAllocatedToStudent ? 'Du belegst den Kurs bereits' : 
                        likedCourses.length >= MAX_LIKED_COURSES ? 'Man kann höchstens 4 Kurse hinzufügen' :
                            'Hinzufügen'
                }
            </Button>
            {isSelectedCoursePriority ? (
                <span>Da du diesen Kurs im letzten Jahr belegt hast, hast du auf Ihn ein Vorrecht!</span>
            ) : <></>}
        </div>
        <br />
        <hr />
        <h3>Prioritätenliste</h3>
        <div className="float-left text-center" width="5%">
            {[...Array(numLikedPriorityCourses).keys()].map((i) => (
                <Card body style={{border: 'none'}} className="mb-1" key={`pins-${i}`}>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>Auf den Kurs hast du ein Vorrecht</Tooltip>
                        }
                    >
                        <PinAngle size={24} />
                    </OverlayTrigger>
                </Card>
            ))}
            {[...Array(MAX_LIKED_COURSES - numLikedPriorityCourses).keys()].map((i) => (
                <Card body style={{border: 'none'}} className="mb-1" key={`nums-${i}`}>
                    <NumberCircle size={24} number={i+1} />
                </Card>
            ))}
        </div>
        <div style={{marginLeft: '5%'}}>
            <DragDropContext onDragEnd={onDragEnd}>
                {likedCourses.slice(0, numLikedPriorityCourses).map((course, i) => (
                    <div className="mb-1" key={course.id}>
                        <LikedCard 
                            course={course} 
                            remove={() => removeLikedCourse(i)}
                            unprioritize={() => {
                                likedCourses.splice(numLikedPriorityCourses - 1, 0, likedCourses.splice(i, 1)[0]);
                                setNumLikedPriorityCourses(numLikedPriorityCourses - 1)
                            }}
                        />
                    </div>
                ))}
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {likedCourses.slice(numLikedPriorityCourses).map((course, i) => (            
                                <Draggable draggableId={"draggable-"+course.id} key={course.id} index={i + numLikedPriorityCourses}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="mb-1"
                                    >
                                        <LikedCard 
                                            provided={provided} 
                                            course={course} 
                                            remove={() => removeLikedCourse(i + numLikedPriorityCourses)} 
                                            draggable 
                                            prioritizable={priorityCourses.filter(c => Number(c) === Number(course.id)).length > 0}
                                            prioritize={() => {
                                                likedCourses.splice(0, 0, likedCourses.splice(i + numLikedPriorityCourses, 1)[0]);
                                                setLikedCourses(likedCourses)
                                                setNumLikedPriorityCourses(numLikedPriorityCourses + 1);
                                            }}
                                        />
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {[...Array(MAX_LIKED_COURSES - likedCourses.length).keys()].map((i) => (
                    <EmptyLikedCard key={i} />
                ))}
            </DragDropContext>
        </div>
        <br />
        <hr />
        <Form.Group className="mb-3">
            <Form.Label>Wie viele Kurse möchtest du von deiner Auswahl maximal besuchen?</Form.Label>
            <Form.Select value={selectedMaxAllocatedCourses} onChange={e => setSelectedMaxAllocatedCourses(e.target.value)}>
                {[...Array(MAX_ALLOCATED_COURSES).keys()].map((i) => (
                    <option value={i+1} key={i}>{i+1}</option>
                ))}
            </Form.Select>
        </Form.Group>
        <div className="text-end mb-5">
            {userType === 'student' ? (
                <Button variant="success" onClick={submit}>Abschicken</Button>
            ) : (
                <Button variant="success" disabled>Abschicken</Button>
            )}
        </div>
        </>
    )
}

function EmptyLikedCard() {
    return (
        <Card className="mb-1" style={{height: '58.5px'}} />
    )
}

function LikedCard({provided, course, draggable, remove, unprioritize, prioritizable, prioritize }) {
    return (
        <Card style={{height: '58.5px', paddingLeft: '10px' }}>
            <div style={{margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%', justifyContent: "space-between", flexDirection: "row", display: 'flex'}}>
                <div>
                    {draggable ? <span {...provided.dragHandleProps} ><List size={20} className="mb-1 me-1"/></span> : <></> }
                    <span>
                        {course.title} <span className="text-muted d-none d-md-inline">von {course.trainer_title} {course.trainer_name}</span>
                    </span>
                </div>
                <div style={{textAlign: 'end', paddingRight: '20px', minWidth: '65px'}}>
                    {draggable ? <></> : (
                        <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>Auf dein Vorrecht verzichten</Tooltip>
                        }
                        >
                            <span className="text-primary me-1">
                                <ArrowDownCircle size="18" className="hoverable" onClick={unprioritize}/>
                            </span>
                        </OverlayTrigger>
                    )}
                    {prioritizable ?  (
                        <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>Auf dein Vorrecht bestehen</Tooltip>
                        }
                        >
                            <span className="text-primary me-1">
                                <ArrowUpCircle size="18" className="hoverable" onClick={prioritize}/>
                            </span>
                        </OverlayTrigger>
                    ) : <></>}
                    <span className="text-danger">
                        <TrashFill size="18" className="hoverable" onClick={remove}/>
                    </span>
                </div>
            </div>
            
        </Card>
    )
}

// Icons that are strangely not included in the library
function NumberCircle({ number, size }) {
    let pathElem = <></>;

    switch(number) {
        case 1: pathElem = <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z" />; break;
        case 2: pathElem = <path d= "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306" />; break;
        case 3: pathElem = <><path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z"/><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z"/></>; break;
        case 4: pathElem = <><path d="M7.519 5.057c.22-.352.439-.703.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z"/><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z"/></>; break;
        default:
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="currentColor" viewBox="0 0 16 16">
            {pathElem}
        </svg>
    )
}

function IdentityCreatorModal({ show, setShow, allocatedCourses, setAllocatedCourses, priorityCourses, setPriorityCourses, restrictToClass, setRestrictToClass }) {
    
    const cancel = () => {
        setAllocatedCourses([])
        setPriorityCourses([])
        setRestrictToClass('no')
    }

    return (
        <ConfirmModal title="Schüleridentität erstellen"  confirmBtnText="Als dieser Schüler fortfahren" cancelBtnText="Abbrechen" cancelCallback={cancel} show={show} setShow={setShow} closeButton={false} size="lg">
            <p>Der Kurswahlbildschirm ist für unterschiedliche Schüler immer leicht verschieden. Es ändert sich welche Kurse angezeigt werden (aufgrund der Jahrgangsstufe des Schülers) und auf welche Kurse der Schüler ein Vorrecht hat (diejenige, welche der Schüler im letzten Jahr belegte). Um die Vorschu so genau wie möglich an der Schülererfahrung zu halten, kann hier bestimmt werden, wie diese Attribute des fiktiven Schülers gesetzt sind.</p>
            <FormRow label="Jahrgang">
                <Form.Select value={restrictToClass} onChange={e => setRestrictToClass(e.target.value)} className="mb-1">
                    <option value="no">--- alle Kurse anzeigen ---</option>
                    <option value="05">5. Klasse</option>
                    <option value="06">6. Klasse</option>
                    <option value="07">7. Klasse</option>
                    <option value="08">8. Klasse</option>
                    <option value="09">9. Klasse</option>
                    <option value="10">10. Klasse</option>
                    <option value="11">11. Klasse</option>
                    <option value="12">12. Klasse</option>
                </Form.Select>
            </FormRow>
            <FormRow label="Ehemalige Kurse">
                <CourseMultiSelect courses={priorityCourses} setCourses={setPriorityCourses} />
            </FormRow>
            <FormRow label="Kurse die der Schüler gerade besucht">
                <CourseMultiSelect courses={allocatedCourses} setCourses={setAllocatedCourses} />
            </FormRow>
        </ConfirmModal>
    )
}

function CourseMultiSelect({ courses, setCourses }) {
    const allCourses = useFetchData('courses')
    const courseSelect = useRef()

    const onChangeSelect = (e) => {
        console.log(courses)
        setCourses([...courses, Number(e.target.value)])
        courseSelect.current.value = ''
    }

    const onRemoveCourse = (cid) => {
        setCourses(courses.filter(c => Number(c) !== Number(cid)))
    }

    return (<>
        <Form.Select defaultValue='' onChange={onChangeSelect} ref={courseSelect}>
            <option disabled="disabled" value=''>Kurs auswählen</option>
            {allCourses.filter(c => courses.indexOf(Number(c.id)) === -1).map(c => (
                <option key={c.id} value={c.id} >{c.title} von {c.trainer_title} {c.trainer_name} (Klassen {utils.classLevelsDisplayString(c.class_levels)})</option>
            ))}
        </Form.Select>
        {courses.map(c => (
            <Fragment key={c}><Badge className="hoverable" onClick={() => onRemoveCourse(c)}>{allCourses?.filter(d => Number(d.id) === Number(c))[0]?.title}</Badge>&nbsp;</Fragment>
        ))}
    </>)
}