import { Link } from "react-router-dom";
import StudentList from "../../components/StudentList";
import useFetchData from "../../hooks/useFetchData";
import useLoginToken from "../../hooks/useLoginToken";

export default function ClassOverview() {
    const [ { userData, }, ] = useLoginToken(); 
    const studentsInClass = useFetchData(`class/${userData.class}/students`, {}, [userData]);


    return (
        <>
        <h1>Hallo {userData.title} {userData.name}</h1>
        <StudentList students={studentsInClass} hideClass attendanceRatioBars courseLinks studentLinks courseLinkPath='/reader' />
        <p>Es fehlt ein Schüler oder eine Schülerin in Ihrer Klasse? Erstellen Sie ihn oder sie <Link to={{pathname: '/newStudent'}}>hier</Link> doch einfach selbst!</p>
        </>
    );
}