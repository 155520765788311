import { Form } from "react-bootstrap";
import FormRow from "../../bt/FormRow";
import RepeatPassword from "../RepeatPassword";

export default function OtherEdit({ name, setName, pwd, setPwd, pwd2, setPwd2, mode }) {
    let pwdPlaceholder = mode === 'edit' ? 'Passwort wird gerade nicht geändert' : 'Passwort';

    return (
        <>
        {mode === 'edit' ? <div className="text-muted mb-2">Wenn das Passwort nicht geändert werden soll, einfach Passwortfeld freilassen!</div> : <></>}
        <FormRow label="Benutzername">
            <Form.Control type="text" placeholder="Benutzername" value={name} onChange={e => setName(e.target.value)} required/>
        </FormRow>
        <FormRow label="Passwort">
            <Form.Control type="password" placeholder={pwdPlaceholder} value={pwd} onChange={e => setPwd(e.target.value)} required/>
        </FormRow>
        {mode === 'new' || pwd !== '' ? (
            <FormRow label="Passwort wiederholen">
                <RepeatPassword pwd={pwd} pwd2={pwd2} setPwd2={setPwd2} />
            </FormRow>
        ) : <></> }
        </>
    );
}

OtherEdit.defaultProps = {
    mode: 'edit'
}