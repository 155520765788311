import { ButtonGroup, Button, Form, Col, Row } from "react-bootstrap";
import utils from "../../service/utils";

export default function ClassLevelRadioButtons(props) {

    let vals = props.value === '' ? [] : props.value.split('|');

    const isClassLevelActive = cLevel => {
        return vals.includes(cLevel);
    }

    const toggleClassLevel = cLevel => {
        utils.toggleArrayItem(vals, cLevel);
        vals = vals.sort();
        
        props.setValue(vals.join('|'))
    }
            

    return (
        <>
        <div className="d-none d-lg-block">
            <ButtonGroup className="w-100">
                <Button onClick={() => toggleClassLevel('05')} variant={isClassLevelActive('05') ? 'primary' : 'outline-primary'}>5. Klasse</Button>
                <Button onClick={() => toggleClassLevel('06')} variant={isClassLevelActive('06') ? 'primary' : 'outline-primary'}>6. Klasse</Button>
                <Button onClick={() => toggleClassLevel('07')} variant={isClassLevelActive('07') ? 'primary' : 'outline-primary'}>7. Klasse</Button>
                <Button onClick={() => toggleClassLevel('08')} variant={isClassLevelActive('08') ? 'primary' : 'outline-primary'}>8. Klasse</Button>
                <Button onClick={() => toggleClassLevel('09')} variant={isClassLevelActive('09') ? 'primary' : 'outline-primary'}>9. Klasse</Button>
                <Button onClick={() => toggleClassLevel('10')} variant={isClassLevelActive('10') ? 'primary' : 'outline-primary'}>10. Klasse</Button>
                <Button onClick={() => toggleClassLevel('11')} variant={isClassLevelActive('11') ? 'primary' : 'outline-primary'}>11. Klasse</Button>
                <Button onClick={() => toggleClassLevel('12')} variant={isClassLevelActive('12') ? 'primary' : 'outline-primary'}>12. Klasse</Button>
            </ButtonGroup>
        </div>
        <div className="d-lg-none">
            <Row>
                <Col sm="3" xs="6"><Form.Check label="5. Klasse" id="c5" checked={isClassLevelActive('05')} onChange={() => toggleClassLevel('05')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="6. Klasse" id="c6" checked={isClassLevelActive('06')} onChange={() => toggleClassLevel('06')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="7. Klasse" id="c7" checked={isClassLevelActive('07')} onChange={() => toggleClassLevel('07')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="8. Klasse" id="c8" checked={isClassLevelActive('08')} onChange={() => toggleClassLevel('08')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="9. Klasse" id="c9" checked={isClassLevelActive('09')} onChange={() => toggleClassLevel('09')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="10. Klasse" id="c10" checked={isClassLevelActive('10')} onChange={() => toggleClassLevel('10')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="11. Klasse" id="c11" checked={isClassLevelActive('11')} onChange={() => toggleClassLevel('11')}/></Col>
                <Col sm="3" xs="6"><Form.Check label="12. Klasse" id="c12" checked={isClassLevelActive('12')} onChange={() => toggleClassLevel('12')}/></Col>
            </Row>
        </div>
        </>
    );
}