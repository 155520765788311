import { Fragment, useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { ArrowCounterclockwise } from "react-bootstrap-icons";
import useFetchData from "../../hooks/useFetchData";
import usePushNotificator from "../../hooks/usePushNotificator";
import useTrigger from "../../hooks/useTrigger";
import api from "../../service/api";
import AttendanceTable from "./AttendanceTable";

export default function AttendanceStudentView({ student, editable }) {
    const courses        = useFetchData('courses');
    let    attendanceData = useFetchData('attendances/students/' + student.id, {}, [ student ]);
    const [ attendances, setAttendances ] = useState([]);
    const [ isSaved, setIsSaved ] = useState(true);
    const [ rollbackTrigger, rollbackListener ] = useTrigger();
    const [ pushNotification, Notificator ] = usePushNotificator();

    useEffect(() => {
        setAttendances(attendanceData.attendances);
    }, [attendanceData, rollbackListener]);

    if(attendanceData instanceof Array)
        return <></>;
    
    const saveAttendance = async () => {
        if(editable) {
            const res = await api.put('attendances', attendances);
            if(res.status === 'ok') {
                setIsSaved(true);
                pushNotification('Bearbeitung erfolgreich', 'Die Änderungen der Anwesenheit wurden erfolgreich gespeichert', 'success');
                attendanceData.attendances = attendances;
            }
        }
    }

    const innerSetAttendance = (att) => {
        setIsSaved(false);

        const newAtts = attendances?.filter(a => Number(a.student_id) !== Number(att.student_id) || a.date !== att.date || Number(a.term_id) !== Number(att.term_id) || Number(a.course_id) !== Number(att.course_id));
        setAttendances([...newAtts, att ]);
    }

    return (
        <>
        <Notificator />
        <Card body className="mb-3">
            <Row>
                <Col><Card.Title>Anwesenheit</Card.Title></Col>
                <Col className="text-end">
                    {isSaved ? (<>
                        <Button size="sm" variant="outline-secondary" disabled className="me-2"><ArrowCounterclockwise size={18} /></Button>
                        <Button variant="success" size="sm" disabled>Speichern</Button>
                    </>) : (<>
                        <Button size="sm" variant="outline-secondary" className="me-2" onClick={() => { setIsSaved(true); rollbackTrigger(); }}><ArrowCounterclockwise size={18} /></Button>
                        <Button variant="success" size="sm" onClick={saveAttendance}>Speichern</Button>
                    </>)}
                </Col>
            </Row>
            <hr />
            {Object.keys(attendanceData.courses).map(cid => (
                <AttendanceStudentViewCell 
                    key={cid} 
                    student={student} 
                    course={courses?.filter(c => cid === c.id)[0]} 
                    visitVoluntary={attendanceData.courses[cid].studentVisitsVoluntary}
                    dates={attendanceData.courses[cid].dates}
                    attendances={attendances}
                    setAttendance={innerSetAttendance}
                    editable={editable}
                />
            ))}
        </Card>
        </>
    );
}

function AttendanceStudentViewCell({ student, course, visitVoluntary, dates, attendances, setAttendance, editable }) {
    if(course === undefined)
        return <></>;

    const schedule = JSON.parse(course.schedule);
    const numTerms = schedule.filter(s => !s.flags.includes('VOLUNTARY')).length + visitVoluntary.length;

    
    return (
        <Card className="mb-2">
            <Card.Header>{course.title}</Card.Header>
            {numTerms === 0 ? (
                <em className="text-end">--- der Schüler nimmt an keinem Termin teil ---</em>
            ) : (
                <AttendanceTable 
                    students={[ {...student, visit_voluntary: visitVoluntary} ]} 
                    dates={dates} 
                    courseId={course.id}
                    attendances={attendances}
                    setAttendance={setAttendance}
                    schedule={schedule}
                    showTitles={numTerms > 1}
                    studentsEditable={editable}
                    hideTrainerAttendance hideStudentNames
                />
            )}
        </Card>
    );
}