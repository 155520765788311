import { useState } from 'react';
import api from '../service/api';

export default function useLoginToken() {
  const [loginToken, setLoginToken] = useState(api.isLoggedIn() ? api.getCrntLoginToken() : { userType: undefined });

  const saveLoginToken = token => {
    api.setCrntLoginToken(token);
    setLoginToken(token);
  };

  return [ loginToken, saveLoginToken ];
}