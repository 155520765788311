import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import ConfirmModal from "../components/bt/ConfirmModal";
import FormRow from "../components/bt/FormRow";
import usePushNotificator from "../hooks/usePushNotificator";
import api from "../service/api";
import utils from "../service/utils";

export default function FeedbackPage() {
    const [ subject, setSubject ] = useState("")
    const [ message, setMessage ] = useState("")
    const [ anonymous, setAnonymous] = useState(false)
    const [ answerMail, setAnswerMail ] = useState("")
    const [ pushNotification, Notificator ] = usePushNotificator();

    const [ showMailWarning, setShowMailWarning ] = useState(false)

    const send = async (e) => {
        e.preventDefault();
        if(subject.length < 5 || message.length < 10) {
            pushNotification('Inkorrekte Eingabe', 'Bitte gebe ein Betreff und eine Nachricht an, die lang genug ist!', 'warning');

        } else if(!anonymous && answerMail.length === 0) {
            setShowMailWarning(true)
        } else if(!anonymous && !utils.isEmail(answerMail)) {
            pushNotification('Inkorrekte Eingabe', 'Die eingegebene E-Mail-Adresse hat nicht das richtige Format.', 'warning');
        } else {
            executeSend();
        }
    }

    const executeSend = async () => {
        const res = await api.post('feedback', {subject, message, answerMail: anonymous ? '' : answerMail, anonymous: anonymous ? 1 : 0});

        if(res.status === 'ok') {
            setSubject('');
            setMessage('');
            setAnswerMail('');
            setAnonymous(false);

            pushNotification('Feedback gesendet', 'Vielen Dank für dein Feedback. Wir werden uns darum', 'success');
        } else {
            pushNotification('Interner Fehler', res.error, 'danger');
        }
    }


    return (<>
        <Notificator />
        <ConfirmModal title="Feedback ohne Antwort E-Mail" confirmBtnText="Trotzdem Senden" confirmCallback={() => { executeSend(); return true }} cancelBtnText="Abbrechen" show={showMailWarning} setShow={setShowMailWarning}>
            Wenn Sie das Feedback ohne eine Antwort E-Mail absenden, ist es nicht möglich mit Ihnen in Kontakt zu treten oder zu antworten. Sind Sie sich sicher, dass Sie keine angeben wollen?
        </ConfirmModal>
        <h1>Feedback</h1>
        <p>Wir suchen immer nach Fehlern in der Webseite, gerade weil sie auch so neu ist. Daher freuen wir uns sehr über Feedback um die Seite ein Stückchen besser zu machen :) </p>
        <Card body>
            <FormRow label="Betreff">
                <Form.Control value={subject} onChange={e => setSubject(e.target.value)} />
            </FormRow>
            <FormRow label="Nachricht">
                <Form.Control as="textarea" rows={7} value={message} onChange={e => setMessage(e.target.value)} />
            </FormRow>
            <FormRow label="">
                <Form.Check type="checkbox" id="anonymous" label="Nachricht anonym versenden" checked={anonymous} onChange={() => setAnonymous(!anonymous)} />
            </FormRow>
            {anonymous ? <></> : (
                <FormRow label="Antwort E-Mail">
                    <Form.Control type="email" value={answerMail} onChange={e => setAnswerMail(e.target.value)} />
                </FormRow>
            )}
            <div className="text-end">
                <Button variant="success" onClick={send}>Abschicken</Button>
            </div>
        </Card>
    </>);
}