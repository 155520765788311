export default function FloatingInput(props) {
    const label = props.label || 'Label';
    const placeholder = props.placeholder || label;
    const classNames = props.className || '';
    
    let propsCpy = {...props};
    delete propsCpy['label'];
    delete propsCpy['placeholder'];
    delete propsCpy['className'];


    return (
        <div className="form-floating">
            <input className={"form-control " + classNames} id={label} placeholder={placeholder} {...propsCpy} />
            <label htmlFor={label}>{label}</label>
        </div>
    );
}