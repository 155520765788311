import useLoginToken from "../../hooks/useLoginToken";
import usePageState from "../../hooks/usePageState";
import ReaderComponent from "./ReaderComponent";

export default function ReaderPage(props) {
    const [ pageState, ] = usePageState(props, {id: ''});
    const [{ userType }, ] = useLoginToken();

    return (
        <>
        <h1>Kursübersicht</h1>
        <ReaderComponent initialCourseId={pageState.id} showTrainerLinks={userType === 'other'}/>
        </>
    );
}


