import FormRow from '../../bt/FormRow'
import PasswordDisplayRow from './PasswordDisplayRow'

export default function TeacherDisplay({ title, name, _class }) {
    return (
        <>
            <FormRow label="Klasse" plaintext>
                {_class}
            </FormRow>
            <FormRow label="Name" plaintext>
                {title + " " + name}
            </FormRow>
            <PasswordDisplayRow />
        </>
    )
}